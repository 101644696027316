import { FormControl, FormHelperText, InputLabel, Slider } from '@mui/material';
import { textToNumber } from 'forms/format';
import React, { useState } from 'react';
const defaultValueByComponent = (component, value) => {
    if (component === 'range') {
        if (!value)
            return [0, 0];
        return Array.isArray(value) ? value?.map(Number) : [0, 0];
    }
    return textToNumber(value);
};
export const AltSlider = ({ label, altField, controlledField, getValues, setValue, error, condition }) => {
    const [values, setValues] = useState(defaultValueByComponent(altField.component, controlledField.value));
    const handleChange = (_, newValue) => {
        setValues(newValue);
        controlledField.onChange(newValue);
    };
    const getRange = () => {
        try {
            const result = [
                ...(altField?.choices?.sort((a, b) => {
                    return Number(a?.value ?? 0) - Number(b?.value ?? 0);
                }) || [])
            ];
            const min = result?.[0]?.value ?? result?.[0] ?? 0;
            const max = result?.pop();
            return {
                min: textToNumber(min),
                max: textToNumber(max?.value ?? max ?? 0)
            };
        }
        catch (error) {
            console.error(`component::slider - failed to get range min, max, returning default 0, 100`, error);
            return {
                min: 0,
                max: 100
            };
        }
    };
    const { min, max } = getRange();
    return (<>
      <InputLabel>{label}</InputLabel>
      <FormControl component='div' sx={{
            width: '95%',
            display: 'flex',
            justifySelf: 'center',
            mx: 'auto'
        }} {...controlledField} // this is not used as we are saving down the string[] of what the user selected.. controlledField value for checkbox is only boolean
     {...condition?.props}>
        <Slider getAriaLabel={() => `${altField.component}`} 
    // getAriaValueText={controlledField.value || 0}
    // step={10}
    {...controlledField} value={values} onChange={handleChange} step={null} min={min} max={max} marks={altField.choices?.map((choice) => {
            return {
                value: textToNumber(choice?.value),
                label: choice?.display
            };
        })} valueLabelDisplay='off'/>
        <FormHelperText>{altField.sub || ''}</FormHelperText>
        {error && <FormHelperText>{error || ''}</FormHelperText>}
      </FormControl>
    </>);
};
