import { RedirectToSignIn, useAuth, useUser } from '@clerk/clerk-react';
import { Box, createTheme, ThemeProvider, Typography } from '@mui/material';
import { PoweredBy } from 'components/altfield/PoweredBy';
import { AltForm } from 'components/AltForm';
import { Heading } from 'components/Heading';
import { Placeholder } from 'components/Placeholder';
import { CREATE_NEW_FLOW_SLUG } from 'forms/alt-field';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { Outlet, Route, Routes, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getUserAnswers } from 'services/answers-service';
import { createFlow, getFlowAdmin } from 'services/flow-admin-service';
import { getFlow } from 'services/flow-service';
import { postUserAnswers, postUserFlowStatus, updateUser } from 'services/user-service';
import { theme } from 'style';
import { RequireAuth } from './flow/RequireAuth';
import { FlowOverviewRouterV2 } from './FlowOverviewRouterV2';
import { FlowSidebar } from './FlowSidebar';
export const FlowRoutes = ({ fieldOverrides }) => {
    const params = useParams();
    const { slug } = params;
    const location = useLocation();
    const navigate = useNavigate();
    const [flow, setFlow] = useState(null);
    const [answers, setAnswers] = useState(null);
    const [statuses, setStatuses] = useState(null);
    const [searchParams] = useSearchParams();
    const templateSlug = searchParams.get('template');
    const isEditMode = searchParams.get('mode') && searchParams.get('mode') === 'edit';
    const [isLoading, setIsLoading] = useState(true);
    const [flowNotFound, setFlowNotFound] = useState(false);
    const auth = useAuth();
    const { user } = useUser();
    const [isAnon, setIsAnon] = useState(false);
    useEffect(() => {
        const fetchFlow = async () => {
            if (slug && !isEditMode) {
                const data = await getFlow(slug);
                setFlow(data);
                if (!data) {
                    setFlow(null);
                    setFlowNotFound(true);
                }
                if (user?.id) {
                    if (data.settings?.toRequiredAuth) {
                        const userResponse = await getUserAnswers(slug, user?.id);
                        setAnswers(userResponse?.answers);
                        if (userResponse.status)
                            setStatuses(userResponse.status);
                    }
                }
                if (data.settings.toRequireAuth === false) {
                    if (!user?.id)
                        setIsAnon(true);
                    const anonUserId = localStorage.getItem('anon');
                    if (!anonUserId) {
                        const newAnonUserId = `anon_${nanoid()}`;
                        localStorage.setItem('anon', newAnonUserId);
                        await updateUser(newAnonUserId, {
                            lastName: '',
                            firstName: '',
                            email: ''
                        });
                        setAnswers({});
                        setStatuses({});
                    }
                    else {
                        const userResponse = await getUserAnswers(slug, anonUserId);
                        console.log('userResponse', userResponse);
                        setAnswers(userResponse?.answers);
                        if (userResponse.status)
                            setStatuses(userResponse.status);
                    }
                }
            }
            if (slug && isEditMode) {
                const token = await auth.getToken();
                const data = await getFlowAdmin(slug, token);
                if (!data) {
                    setFlowNotFound(true);
                    setFlow(null);
                    return;
                }
                setFlow(data);
            }
            setIsLoading(false);
        };
        fetchFlow();
    }, []);
    useEffect(() => {
        const fetchUserAnswers = async () => {
            setIsLoading(true);
            if (flow?.settings?.toRequireAuth) {
                setIsAnon(false);
                const userResponse = await getUserAnswers(slug, user?.id);
                setAnswers((prev) => {
                    return {
                        ...prev,
                        ...userResponse?.answers
                    };
                });
                if (userResponse.status)
                    setStatuses(userResponse.status);
            }
            setIsLoading(false);
        };
        if (user?.id && !isEditMode)
            fetchUserAnswers();
    }, [user?.id, flow?.settings]);
    const handleSubmit = async (sectionId, data) => {
        if (slug === CREATE_NEW_FLOW_SLUG)
            return;
        try {
            const userId = isAnon ? localStorage.getItem('anon') : user?.id;
            await postUserAnswers(slug, sectionId, userId, data);
            setAnswers((prev) => {
                return {
                    ...prev,
                    [sectionId]: {
                        ...prev?.[sectionId],
                        ...data
                    }
                };
            });
        }
        catch (error) {
            console.error(error);
        }
    };
    const handleCreateFlowFromTemplate = async (flowToCreate) => {
        try {
            const newFlow = await createFlow({
                ...flowToCreate,
                userId: user?.id
            }, templateSlug);
            return newFlow;
        }
        catch (error) {
            console.error(error);
        }
    };
    const handleUpdateUserSectionStatus = async (sectionId, newStatus) => {
        if (slug === CREATE_NEW_FLOW_SLUG)
            return;
        if (flow?.sections.find(({ id, status }) => {
            return id === sectionId && status === newStatus;
        })) {
            return;
        }
        try {
            const userId = isAnon ? localStorage.getItem('anon') : user?.id;
            await postUserFlowStatus(slug, sectionId, userId, newStatus);
            setStatuses((prev) => {
                return {
                    ...prev,
                    [sectionId]: newStatus
                };
            });
        }
        catch (error) {
            console.error(error);
        }
    };
    const flowTheme = flow?.theme?.['color-primary'] && !isEditMode
        ? createTheme({
            ...theme,
            palette: {
                primary: {
                    // read: https://mui.com/material-ui/customization/palette/#providing-the-colors-directly
                    // light: will be calculated from palette.primary.main,
                    main: flow?.theme?.['color-primary']
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                }
            }
        })
        : theme;
    return (<ThemeProvider theme={flowTheme}>
      {isEditMode && !user?.id && (<RedirectToSignIn afterSignInUrl={`${location.pathname}${location.search}`} afterSignUpUrl={`${location.pathname}${location.search}`} redirectUrl={`${location.pathname}${location.search}`}/>)}
      {slug && flow?.settings?.toRequireAuth === true && !isEditMode && (
        // require user to sign-in if toRequireAuth is true
        <RequireAuth isRequired={flow?.settings?.toRequireAuth === true} flowName={flow.name} slug={slug}/>)}
      {slug && flow ? (<Box sx={{ display: 'flex' }} key={flow.name} className='flow-main' id={`flow-${flow.slug}`}>
          {!isEditMode && <FlowSidebar flow={flow} isEmbed={false}/>}
          <Routes>
            <Route index element={<FlowOverviewRouterV2 flow={flow} answers={answers} statuses={statuses}/>}/>

            {flow?.sections?.map((section) => {
                const { header, fields: allFields, next, settings, id } = section;
                return (<Route path={section.slug} key={section.slug} element={<Box id='flow-section-main' component='main' sx={{
                            width: '100%',
                            p: 3
                        }}>
                      <Typography variant='h2' component='header' sx={{
                            paddingBottom: 3
                        }}>
                        {flow.name}
                      </Typography>
                      {allFields?.length && answers ? (<AltForm flowSlug={slug} id={id} key={id} header={header} allFields={allFields} next={next ? next : `/flow/${slug}`} settings={settings} answers={answers?.[id] || {}} fieldOverrides={fieldOverrides} onSubmit={handleSubmit} onStatusChange={handleUpdateUserSectionStatus} onCreateFlowFromTemplateSelection={handleCreateFlowFromTemplate}/>) : (<Placeholder size={1}/>)}
                      <PoweredBy />
                    </Box>}/>);
            })}
          </Routes>
        </Box>) : (<Box sx={{ padding: 2 }}>
          <Heading>
            <Typography variant='h2' component='header'>
              Multiply Your Impact, Change the World!
            </Typography>
            {flowNotFound && (<Typography variant='h4' component='header'>
                Flow not found!
              </Typography>)}
          </Heading>
          {!flowNotFound && <Placeholder size={3}/>}
        </Box>)}
      <Outlet />
    </ThemeProvider>);
};
