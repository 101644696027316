import { PasswordOutlined, Tag, ToggleOffOutlined, ViewSidebarOutlined } from '@mui/icons-material';
import React from 'react';
import { FlowSettings } from 'shared/flow-settings';
export const FLOW_SETTINGS = [
    {
        id: '63536643f662ae82d97a167b',
        component: 'toggle',
        key: FlowSettings.showSidebar.key,
        label: FlowSettings.showSidebar.label,
        sub: FlowSettings.showSidebar?.sub,
        tip: FlowSettings.showSidebar?.tip,
        defaultValue: FlowSettings.showSidebar.default,
        icon: <ViewSidebarOutlined />,
        type: 'text',
        choices: null,
        info: null,
        watch: null,
        autocomplete: false,
        multiple: false,
        ordinal: 1,
        isPrivate: false,
        isHidden: false,
        hasChildren: false,
        createdAt: '2022-10-22T03:40:51.592Z',
        updatedAt: '2022-10-22T03:40:51.595Z',
        parent: null
    },
    {
        id: '63536643f662ae82d97a167b',
        component: 'toggle',
        key: FlowSettings.navigateFreely.key,
        label: FlowSettings.navigateFreely.label,
        sub: FlowSettings.navigateFreely?.sub,
        tip: FlowSettings.navigateFreely?.tip,
        defaultValue: FlowSettings.navigateFreely.default,
        icon: <ToggleOffOutlined />,
        type: 'text',
        choices: null,
        info: null,
        watch: null,
        autocomplete: false,
        multiple: false,
        ordinal: 1,
        isPrivate: false,
        isHidden: false,
        hasChildren: false,
        createdAt: '2022-10-22T03:40:51.592Z',
        updatedAt: '2022-10-22T03:40:51.595Z',
        parent: FlowSettings.showSidebar.key,
        condition: {
            type: 'show',
            value: true
        }
    },
    {
        id: '63536643f662ae82d97a167b',
        component: 'toggle',
        key: FlowSettings.showControls.key,
        label: FlowSettings.showControls.label,
        sub: FlowSettings.showControls?.sub,
        tip: FlowSettings.showControls?.tip,
        defaultValue: FlowSettings.showControls.default,
        icon: <ToggleOffOutlined />,
        type: 'text',
        choices: null,
        info: null,
        watch: null,
        autocomplete: false,
        multiple: false,
        ordinal: 1,
        isPrivate: false,
        isHidden: false,
        hasChildren: false,
        createdAt: '2022-10-22T03:40:51.592Z',
        updatedAt: '2022-10-22T03:40:51.595Z',
        parent: null,
        props: {
            disabled: true
        }
    },
    // {
    //   id: '63536643f662ae82d97a167b',
    //   component: 'toggle',
    //   key: FlowSettings.toEditAnswersAfterCompletion.key,
    //   label: FlowSettings.toEditAnswersAfterCompletion.label,
    //   sub: FlowSettings.toEditAnswersAfterCompletion?.sub,
    //   defaultValue: FlowSettings.toEditAnswersAfterCompletion.default,
    //   icon: <ToggleOffOutlined />,
    //   type: 'text',
    //   choices: null,
    //   info: null,
    //   watch: null,
    //   autocomplete: false,
    //   multiple: false,
    //   ordinal: 1,
    //   isPrivate: false,
    //   isHidden: false,
    //   hasChildren: false,
    //   createdAt: '2022-10-22T03:40:51.592Z',
    //   updatedAt: '2022-10-22T03:40:51.595Z',
    //   parent: null
    // },
    {
        id: '63536643f662ae82d97a167b',
        component: 'toggle',
        key: FlowSettings.toRequireAuth.key,
        label: FlowSettings.toRequireAuth.label,
        sub: FlowSettings.toRequireAuth?.sub,
        tip: FlowSettings.toRequireAuth?.tip,
        defaultValue: FlowSettings.toRequireAuth.default,
        icon: <ToggleOffOutlined />,
        type: 'text',
        choices: null,
        info: null,
        watch: null,
        autocomplete: false,
        multiple: false,
        ordinal: 1,
        isPrivate: false,
        isHidden: false,
        hasChildren: false,
        createdAt: '2022-10-22T03:40:51.592Z',
        updatedAt: '2022-10-22T03:40:51.595Z',
        parent: null,
        props: {
        // disabled: true
        }
    },
    {
        id: '63536643f662ae82d97a167b',
        component: 'toggle',
        key: FlowSettings.toRequirePassword.key,
        label: FlowSettings.toRequirePassword.label,
        sub: FlowSettings.toRequirePassword?.sub,
        tip: FlowSettings.toRequirePassword?.tip,
        defaultValue: FlowSettings.toRequirePassword.default,
        icon: <PasswordOutlined />,
        type: 'text',
        choices: null,
        info: null,
        watch: null,
        autocomplete: false,
        multiple: false,
        ordinal: 1,
        isPrivate: false,
        isHidden: false,
        hasChildren: false,
        createdAt: '2022-10-22T03:40:51.592Z',
        updatedAt: '2022-10-22T03:40:51.595Z',
        parent: null
    },
    {
        id: '63536644f662ae82d97a160f',
        component: 'input',
        key: FlowSettings.passwordRequirement.key,
        label: FlowSettings.passwordRequirement.label,
        sub: FlowSettings.passwordRequirement?.sub,
        tip: FlowSettings.passwordRequirement?.tip,
        icon: <Tag />,
        type: 'text',
        choices: null,
        required: true,
        autocomplete: false,
        multiple: false,
        ordinal: 0,
        isPrivate: false,
        isHidden: false,
        hasChildren: false,
        props: {},
        parent: FlowSettings.toRequirePassword.key,
        condition: {
            type: 'show',
            value: true
        }
    }
];
