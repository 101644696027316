import { AddPhotoAlternateOutlined, CheckCircle, DateRangeOutlined, GestureOutlined, LinearScaleOutlined, List, ListAltOutlined, Notes, OndemandVideo, PhotoOutlined, RadioButtonChecked, ShortText, TuneOutlined, UploadOutlined } from '@mui/icons-material';
import React from 'react';
export const renderAltFieldIcon = (component) => {
    switch (component) {
        case 'date':
            return <DateRangeOutlined color='primary'/>;
        case 'textarea':
            return <Notes color='primary'/>;
        case 'radio':
            return <RadioButtonChecked color='primary'/>;
        case 'checkbox':
            return <CheckCircle color='primary'/>;
        case 'picture-selection':
            return <PhotoOutlined color='primary'/>;
        case 'select':
            return <List color='primary'/>;
        case 'video':
            return <OndemandVideo color='primary'/>;
        case 'signature':
            return <GestureOutlined color='primary'/>;
        case 'chip':
            return <ListAltOutlined color='primary'/>;
        case 'upload':
            return <UploadOutlined color='primary'/>;
        case 'photo-upload':
            return <AddPhotoAlternateOutlined color='primary'/>;
        case 'linear':
            return <LinearScaleOutlined color='primary'/>;
        case 'range':
            return <TuneOutlined color='primary'/>;
        default:
            return <ShortText color='primary'/>;
    }
};
