import { Typography } from '@mui/material';
import { formatTextarea } from 'forms/format';
import React from 'react';
import { FileUploadPreview, ImagePreview } from './FileUploadPreview';
export const renderAltFieldReadOnly = (altField, answer) => {
    switch (altField.component) {
        case 'photo-upload':
        case 'signature':
            return <FileUploadPreview answer={answer}/>;
        case 'picture-selection': {
            const pictureAnswers = altField.multiple ? answer : [answer];
            return pictureAnswers.map((a) => {
                const foundChoice = altField?.choices?.find((c) => c.value === a);
                return (<ImagePreview src={foundChoice?.image || ''} alt={foundChoice?.display}>
            <Typography sx={{ fontWeight: 'bold', paddingTop: 0, paddingLeft: 1 }} dangerouslySetInnerHTML={{
                        __html: formatTextarea(a)
                    }}/>
          </ImagePreview>);
            });
        }
        default:
            return (<Typography sx={{ fontWeight: 'bold', paddingTop: 0, paddingLeft: 1 }} dangerouslySetInnerHTML={{
                    __html: formatTextarea(answer)
                }}/>);
    }
};
