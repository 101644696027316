import { TableRowsOutlined, ViewKanbanOutlined } from '@mui/icons-material';
import { Box, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { getFlowSubmissions } from 'services/flow-admin-service';
import { getWorkflow } from 'services/workflow-admin-service';
import { filterFieldProperties } from './filter-field-properties';
import { FlowBoardView } from './views/FlowBoardView';
import { FlowTableView } from './views/FlowTableView';
export const FlowDash = () => {
    const params = useParams();
    const slug = params.slug;
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(true);
    const [submissions, setSubmissions] = useState([]);
    const [metadata, setMetadata] = useState(null);
    const [groupColumns, setGroupColumns] = useState([]);
    const [columns, setColumns] = useState([]);
    const [view, setView] = useState(searchParams?.get('view') ?? 'table');
    const [workflow, setWorkflow] = useState(null);
    useEffect(() => {
        const fetchSubmissions = async () => {
            if (slug) {
                const flowSubmissions = (await getFlowSubmissions(slug)) || {
                    items: [],
                    meta: {}
                };
                if (flowSubmissions) {
                    const groupingColumns = Object.values(Object.keys(flowSubmissions?.items[0] || [])
                        ?.filter(filterFieldProperties)
                        ?.reduce((acc, key) => {
                        const [sectionKey, fieldKey] = key.includes('.')
                            ? key.split('.')
                            : ['info', key];
                        const meta = flowSubmissions.meta;
                        const sectionHeader = meta.sections?.[sectionKey]?.label ?? sectionKey;
                        acc = {
                            ...acc,
                            [sectionKey]: {
                                ...acc?.[sectionKey],
                                groupId: sectionHeader,
                                children: [
                                    ...(acc?.[sectionKey]?.children || []),
                                    {
                                        field: key,
                                        headerName: flowSubmissions.meta?.fields?.[key]?.label ?? fieldKey
                                    }
                                ]
                            }
                        };
                        return acc;
                    }, {}));
                    const fieldColumns = Object.keys(flowSubmissions?.items[0] || [])
                        ?.filter(filterFieldProperties)
                        ?.map((key) => {
                        const meta = flowSubmissions.meta;
                        return {
                            field: key,
                            headerName: meta.fields?.[key]?.label ?? key,
                            width: 150
                            // editable: true
                        };
                    });
                    // fieldColumns.push({
                    //   field: 'info.user-info',
                    //   headerName: 'User Info',
                    //   valueGetter: (colParam: GridValueGetterParams) => {
                    //     return `${colParam.row['_firstname'] || ''} ${
                    //       colParam.row['_lastname'] || ''
                    //     }`
                    //   }
                    // })
                    setMetadata({
                        ...flowSubmissions.meta,
                        user: {
                            username: {
                                key: 'username',
                                label: 'User',
                                component: 'input'
                            }
                        }
                    });
                    setGroupColumns(groupingColumns);
                    setColumns(fieldColumns);
                    setSubmissions(flowSubmissions.items);
                    setIsLoading(false);
                }
            }
        };
        const fetchWorkflow = async () => {
            const workflow = await getWorkflow(slug);
            setWorkflow(workflow);
        };
        fetchSubmissions();
        fetchWorkflow();
    }, [view]);
    useEffect(() => {
        const newView = (searchParams.get('view') ?? 'table');
        if (newView !== view)
            setView(newView);
    }, [searchParams]);
    const handleChangeView = (_event, newView) => {
        if (newView) {
            searchParams.set('view', newView);
            setSearchParams(searchParams);
            setView(newView);
        }
    };
    return (<Box sx={{
            padding: 2
        }}>
      <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline'
        }}>
        <Box>
          <Typography variant='h2' component='header' color='text.primary' sx={{
            paddingBottom: 2
        }}>
            Dashboard {slug}
          </Typography>
        </Box>
        <Box>
          {submissions.length > 0 && (<Stack direction='row' spacing={4}>
              <ToggleButtonGroup exclusive value={view} size='small' onChange={handleChangeView} aria-label='view'>
                <ToggleButton value={'table'} aria-label='table'>
                  <TableRowsOutlined />
                </ToggleButton>
                <ToggleButton value={'board'} aria-label='board'>
                  <ViewKanbanOutlined />
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>)}
        </Box>
      </Box>
      {/* <pre>{JSON.stringify(submissions, null, 3)}</pre> */}
      {isLoading && (<Typography variant='body1' color='text.secondary'>
          Fetching submissions...
        </Typography>)}

      {/* <pre>{JSON.stringify(metadata, null, 1)}</pre> */}
      {view === 'board' && workflow && submissions.length > 0 && (<FlowBoardView flowSlug={slug} workflow={{
                ...workflow,
                stages: workflow?.stages.map((stage) => {
                    if (stage.isBacklog === true) {
                        const stageTasks = submissions.filter((submission) => submission._stageId === null ||
                            submission._stageId === stage.id);
                        return {
                            ...stage,
                            tasks: stageTasks
                        };
                    }
                    else {
                        const stageTasks = submissions.filter((submission) => submission._stageId === stage.id);
                        return {
                            ...stage,
                            tasks: stageTasks
                        };
                    }
                })
            }} meta={metadata}/>)}
      {view === 'table' && submissions.length > 0 && !isLoading && (<FlowTableView submissions={submissions} groupColumns={groupColumns} columns={columns}/>)}
      {submissions.length < 1 && !isLoading && (<Typography variant='body1' color='text.secondary'>
          No submissions yet. Check back later!
        </Typography>)}
    </Box>);
};
