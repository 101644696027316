import { AddOutlined, InfoOutlined, RemoveOutlined } from '@mui/icons-material';
import { Box, FormLabel, IconButton, InputLabel, Switch, Tooltip, Typography } from '@mui/material';
import { FileUploadContext } from 'contexts/FileUploadContext';
import { generateAltFieldEdit } from 'pages/admin/flow/AltFieldEdit';
import React, { useContext, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
/**
 *
 * @param param0
 * @returns [{...}]
 */
export const MatrixGroup = ({ label, altField, controlledField, getValues, setValue, condition }) => {
    const name = `${altField.key}`;
    const valueType = condition?.props?.valueType ?? 'text';
    const useImage = condition?.props?.useImage ?? false;
    const [isAdvanced, setIsAdvanced] = useState(condition?.props?.isAdvanced ?? false);
    const { control, watch, formState } = useForm({
        mode: 'onChange',
        defaultValues: {
            [name]: controlledField?.value
        }
    });
    const { fields, append, remove, insert } = useFieldArray({
        control: control,
        name: name
    });
    /**
     * this is used for PictureSelection and FileUpload
     * because rhf won't trigger setValue and savei t to field-array, this is a workaround
     * ref: altflow-ui/src/components/FileUpload.tsx
     */
    const fileUploadContext = useContext(FileUploadContext);
    useEffect(() => {
        const { isDirty } = formState;
        if (isDirty) {
            const watchFieldArray = watch(controlledField?.name);
            const data = fields.map((field, index) => {
                const { display, value, image } = watchFieldArray[index];
                const fileUploadImage = fileUploadContext?.uploads?.[`${altField.key}[${index}].image`];
                return {
                    ...{
                        display: field?.display,
                        value: field?.value?.length > 0 ? field?.value : field?.display,
                        ...(useImage === true ? { image: field?.image } : undefined)
                    },
                    ...{
                        display,
                        value: value?.length > 0 ? value : display,
                        ...(useImage === true
                            ? { image: fileUploadImage || image }
                            : undefined)
                    }
                };
            });
            if (JSON.stringify(data) !== JSON.stringify(controlledField.value)) {
                controlledField.onChange(data);
            }
        }
    }, [formState]);
    const handleChange = (event) => {
        if (event.target.checked === true) {
            const watchFieldArray = watch(controlledField?.name);
            const data = fields.map((field, index) => {
                const { display, value, image } = watchFieldArray[index];
                return {
                    ...{
                        display: field?.display,
                        value: field?.value.length > 0 ? field?.value : field?.display,
                        ...(useImage === true ? { image: field?.image } : undefined)
                    },
                    ...{
                        display,
                        value: field?.value.length > 0 || value?.length > 0 ? value : display,
                        ...(useImage === true ? { image: image } : undefined)
                    }
                };
            });
            controlledField.onChange(data);
        }
        setIsAdvanced(event.target.checked);
    };
    return (<>
      <Box key={altField.key} sx={{ width: '100%' }}>
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        }}>
          <Typography variant='body1' component='span'>
            {label}
          </Typography>
          <IconButton size='small' onClick={() => append({
            display: '',
            image: '',
            value: valueType === 'number' ? 0 : ''
        })}>
            <AddOutlined />
          </IconButton>
        </Box>
        <Box>
          <FormLabel id={`advanced-label`}>
            Advanced?
            <Tooltip title={'Turn this option on if you want to control the value of the choices'} placement='right' sx={{
            ml: '4px'
        }}>
              <InfoOutlined fontSize='inherit'/>
            </Tooltip>
          </FormLabel>
          <Switch required={false} defaultChecked={isAdvanced} edge='end' inputProps={{
            'aria-labelledby': 'Advanced'
        }} onChange={handleChange}/>
        </Box>
        {fields.length > 0 && !useImage && (<Box sx={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
            <InputLabel>{isAdvanced ? 'Display' : 'Choice'}</InputLabel>
            <InputLabel sx={{ display: !isAdvanced ? 'none' : 'unset' }}>
              Value
              <Tooltip title={'The content of this attribute represents the value to be submitted with the form, should this option be selected. If this attribute is omitted, the value is taken from the text content of the option element.'} placement='right' sx={{
                ml: '4px'
            }}>
                <InfoOutlined fontSize='inherit'/>
              </Tooltip>
            </InputLabel>
            <InputLabel sx={{ minWidth: '75px' }}>Add/Remove</InputLabel>
          </Box>)}

        <Box sx={useImage
            ? {
                display: 'flex',
                justifyContent: 'flex-start',
                flexWrap: 'wrap'
            }
            : {}}>
          {fields?.map((field, index) => {
            return (<Edit key={field.id} parentField={altField} index={index} value={field} getValues={getValues} setValue={setValue} control={control} remove={remove} insert={insert} isAdvanced={isAdvanced} valueType={valueType} useImage={useImage}/>);
        })}
        </Box>
      </Box>
    </>);
};
const Edit = ({ parentField, getValues, setValue, control, value, remove, insert, index, isAdvanced, valueType, useImage }) => {
    const parentKey = `${parentField.key}[${index}]`;
    return (<Box key={`${parentKey}.group`} sx={useImage
            ? {
                display: 'flex',
                minWidth: '150px',
                justifyContent: 'space-between',
                flexDirection: 'column',
                border: '1px solid black',
                borderRadius: '18px',
                p: 2,
                my: 2,
                mr: 1,
                alignItems: 'center',
                textAlign: 'center'
            }
            : {
                display: 'flex',
                justifyContent: 'space-between',
                p: 0,
                my: 0,
                textAlign: 'center'
            }}>
      <Box key={`${value.id}-photo`} sx={{
            flexGrow: 1,
            pr: 1,
            display: useImage ? 'unset' : 'none'
        }}>
        {generateAltFieldEdit({
            key: `${parentKey}.image`,
            component: 'upload',
            type: 'text',
            required: true,
            label: useImage ? `Upload Photo for Choice ${index + 1}` : '',
            defaultValue: value?.image ?? ''
        }, getValues, setValue, control, {}, {
            isSimple: true
        })}
      </Box>
      <Box key={`${value.id}-display`} sx={{
            flexGrow: 1,
            pr: 1
        }}>
        {generateAltFieldEdit({
            key: `${parentKey}.display`,
            component: 'input',
            type: 'text',
            label: useImage ? 'Display' : '',
            required: true,
            defaultValue: value.display ?? ''
        }, getValues, setValue, control, {}, {
            isSimple: true
        })}
      </Box>
      <Box key={`${value.id}-value`} sx={{ display: !isAdvanced ? 'none' : '', pr: 1 }}>
        {generateAltFieldEdit({
            key: `${parentKey}.value`,
            component: 'input',
            type: valueType,
            label: useImage ? 'Value' : '',
            required: true,
            defaultValue: value.value ?? (valueType === 'number' ? 0 : '')
        }, getValues, setValue, control, {}, {
            isSimple: true
        })}
      </Box>
      <Box sx={{ minWidth: '75px', textAlign: useImage ? 'center' : 'unset' }}>
        <IconButton size='small' onClick={() => remove(index)}>
          <RemoveOutlined />
        </IconButton>
        <IconButton size='small' onClick={() => insert(index + 1, {
            display: '',
            image: '',
            value: valueType === 'number' ? 0 : ''
        })}>
          <AddOutlined />
        </IconButton>
      </Box>
    </Box>);
};
