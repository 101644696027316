import { ClerkProvider } from '@clerk/clerk-react';
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { theme } from 'style';
import App from './App';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
const container = document.getElementById('altflow');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<ClerkProvider frontendApi={process.env.REACT_APP_ALTFLOW_AUTH_KEY}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <FlagsmithProvider options={{
        environmentID: process.env.REACT_APP_FLAGSMITH_ID
    }} flagsmith={flagsmith}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </FlagsmithProvider>
    </ThemeProvider>
  </ClerkProvider>
// <ClerkProvider frontendApi={process.env.REACT_APP_ALTFLOW_AUTH_KEY}>
//   <ThemeProvider theme={theme}>
//     <CssBaseline />
//     <BrowserRouter>
//       <SignedIn>
//         <App />
//       </SignedIn>
//       <SignedOut>
//         <RedirectToSignIn />
//       </SignedOut>
//     </BrowserRouter>
//   </ThemeProvider>
// </ClerkProvider>
);
document.documentElement.setAttribute('lang', 'en-US');
