import altflowAwsApi from './altflow-aws-api';
export const updateOrg = async (id, org) => {
    try {
        const { data } = await altflowAwsApi.put(`/orgs/${id}`, {
            ...org
        });
        return data;
    }
    catch (error) {
        throw error;
    }
};
export const getOrgFlows = async (id) => {
    try {
        const { data } = await altflowAwsApi.get(`/orgs/${id}/flows`);
        return data;
    }
    catch (error) {
        throw error;
    }
};
