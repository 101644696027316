import { createContext } from 'react';
/**
 * This is for FlowEditContext state management
 */
export const FlowEditContext = createContext({
    isSubmitting: false,
    setIsSubmitting: (isSubmitting) => isSubmitting,
    slug: ''
});
FlowEditContext.displayName = 'FlowEditContext';
