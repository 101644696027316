import axios from 'axios';
import { CREATE_NEW_FLOW_SLUG } from 'forms/alt-field';
import { adminFieldsOverride } from './overrides/admin-fields-override';
const altflowAwsApi = axios.create({
    baseURL: `${process.env.REACT_APP_ALTFLOW_AWS_API}`,
    timeout: 60000
});
export const altflowAwsApiConfig = async (auth) => {
    altflowAwsApi.interceptors.request.use(async (request) => {
        const apiToken = await auth.getToken();
        request.headers = {
            ...request.headers,
            authorization: `Bearer ${apiToken}`
        };
        return request;
    });
};
altflowAwsApi.interceptors.response.use((response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.config.url?.includes(`/flows/${CREATE_NEW_FLOW_SLUG}`)) {
        console.log('inside create-flow intercept');
        response.data = {
            ...response.data,
            sections: response.data?.sections?.map((section, index) => {
                if (adminFieldsOverride.data.sections?.[index]) {
                    return {
                        ...section,
                        fields: section.fields.map((field) => {
                            const currentOverrideIndexFields = adminFieldsOverride.data.sections?.[index]?.fields;
                            if (currentOverrideIndexFields?.[field.key]) {
                                return {
                                    ...field,
                                    ...currentOverrideIndexFields?.[field.key]
                                };
                            }
                            return field;
                        })
                    };
                }
                return section;
            })
        };
        return response;
    }
    return response;
}, (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});
export default altflowAwsApi;
