import { Card, CardContent, CardHeader, Dialog, DialogContent, DialogTitle, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
export const ArdentMentoringPrint = ({ showPrint, setShowPrint, flow, answers }) => {
    const componentRef = useRef();
    const mainSectionId = flow.sections[1].id;
    const labels = [
        'Mission Statement',
        'Vision Statement',
        'Personal/Family Vision'
    ];
    const main = labels.map((label) => {
        const key = flow.sections[1].fields.find((field) => field.label.trim() === label)
            ?.key || '';
        const foundAnswer = answers?.[mainSectionId]?.[key] || '';
        return {
            title: label,
            body: foundAnswer
        };
    });
    const table = [
        {
            keyword: 'your action area',
            title: 'Action Areas',
            areas: {}
        },
        {
            keyword: 'desire',
            title: 'Desired Results & Measurements',
            areas: {}
        },
        {
            keyword: 'capacity',
            title: 'Capacity Requirements',
            areas: {}
        },
        {
            keyword: 'opportunities',
            title: 'Primary Opportunities',
            areas: {}
        },
        {
            keyword: 'obstacles',
            title: 'Primary Obstacles',
            areas: {}
        },
        {
            keyword: 'milestones',
            title: 'Milestones',
            areas: {}
        },
        {
            keyword: 'help',
            title: 'Who Can Help Me?',
            areas: {}
        }
    ];
    flow.sections
        ?.filter((section) => section.slug.includes('action-area'))
        ?.forEach((section) => {
        section.fields?.forEach((field) => {
            const index = table.findIndex((td) => field.label.toLowerCase().includes(td.keyword));
            const parentFieldKey = field.parent || '';
            if (index > -1) {
                table[index] = {
                    ...table[index],
                    areas: {
                        ...table?.[index]?.areas,
                        [section.slug]: answers?.[section.id]?.[field.key]?.trim() ||
                            answers?.[section.id]?.[parentFieldKey]?.trim() ||
                            ''
                    }
                };
            }
        });
    });
    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });
    useEffect(() => {
        if (showPrint === true) {
            setTimeout(() => {
                handlePrint();
            }, 400);
        }
    }, [showPrint]);
    return (<Dialog open={showPrint} keepMounted={false} ref={componentRef} maxWidth='xl' fullWidth onClose={() => setShowPrint(false)}>
      <DialogTitle>Ardent Mentoring Roadmap</DialogTitle>
      <DialogContent sx={{ p: 2 }}>
        {/* <pre>{JSON.stringify(table, null, 2)}</pre> */}
        <Grid container spacing={2} paddingBottom={1} key='ardent-mentoring-print'>
          {main?.map((area, index) => {
            return (<Grid item sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: `${100 / main.length}%`
                }} key={index}>
                <Card variant='outlined' sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    textAlign: 'center'
                }}>
                  <CardHeader title={area.title} titleTypographyProps={{ variant: 'h6' }}/>
                  <CardContent>{area.body}</CardContent>
                </Card>
              </Grid>);
        })}
        </Grid>
        <TableContainer component={Paper} sx={{ pt: 2 }}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableBody>
              {table?.filter(row => row.title?.length > 0)?.map((row) => (<TableRow key={row.title}>
                  <TableCell component='th' scope='row' sx={{ border: '1px solid black' }}>
                    {row.title}
                  </TableCell>
                  {Object.values(row.areas || {})?.map((area, index) => {
                return (<TableCell align='center' key={index} sx={{ border: '1px solid black' }}>
                        {area}
                      </TableCell>);
            })}
                </TableRow>))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>);
};
