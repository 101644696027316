import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { generateAltField } from 'components/AltField';
import { CREATE_NEW_FLOW_SLUG, generateFieldModel } from 'forms/alt-field';
import { formatTextarea } from 'forms/format';
import React, { Fragment, useEffect, useState } from 'react';
import { Code } from 'react-content-loader';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { paramCase } from 'shared';
import { LAST_FIELD_KEY } from 'shared/alt-field';
const getFields = (fields, answers) => {
    return [
        ...fields.map((field) => {
            return {
                ...field,
                defaultValue: answers?.[field.key]
            };
        }),
        {
            key: LAST_FIELD_KEY,
            label: '',
            component: 'checkbox',
            defaultValue: true
        }
    ];
};
/**
 * This is the altform component that flow and section will use
 * @returns
 */
export const AltForm = ({ flowSlug, id, header, allFields, next, settings, answers, fieldOverrides, onSubmit, onStatusChange, onCreateFlowFromTemplateSelection }) => {
    const navigate = useNavigate();
    /**
     * Temp solution for now -- move this out abstraction
     * as Section.tsx should be single responsibility
     */
    const [searchParams] = useSearchParams();
    const [showOneFieldAtATime, setShowOneFieldAtATime] = useState(settings?.showOneFieldAtATime);
    const [altFields] = useState(getFields(allFields, answers || {}));
    // 1 question at a time
    const [question, setQuestion] = useState({
        current: altFields[0],
        next: null,
        previous: null,
        isCompleted: false
    });
    const [completedQuestions, setCompletedQuestions] = useState([]);
    const [error, setError] = useState(null);
    const { control, formState, setValue, getValues, handleSubmit, watch } = useForm({
        mode: 'onChange',
        // resolver: joiResolver(validationSchema),
        shouldUnregister: !showOneFieldAtATime,
        defaultValues: { ...answers }
    });
    const formValues = watch();
    const overrideFormWatch = fieldOverrides ? watch(fieldOverrides?.field) : null;
    const { errors, isSubmitting } = formState;
    const [isFieldSubmitting, setIsFieldSubmitting] = useState(false);
    // This is only for all questions at a time flow
    const [allowSubmit, setAllowSubmit] = useState(false);
    const allRequiredFields = allFields.filter((field) => field.required === true);
    // if (Object.keys(formValues).length < 1) trigger()
    // console.log('formvalues', formValues)
    // console.log('errors', errors)
    useEffect(() => {
        if (fieldOverrides?.action) {
            return fieldOverrides?.action({
                formValues,
                setValue
            });
        }
    }, [overrideFormWatch]);
    // if (Object.keys(errors).length) console.log(errors)
    // if (error) console.error(error)
    /**
     * This is only for 1 question at a time flow
     * @returns
     */
    const determineNext = async () => {
        const isRequired = question.current?.required ?? false;
        const defaultValue = question.current.defaultValue || null;
        const currentValue = formValues?.[question.current.key] || null;
        // todo: fix this as number values are getting lost in translation
        const isCurrentValueValid = currentValue !== undefined && currentValue !== null;
        if (isRequired === true && !defaultValue && !isCurrentValueValid) {
            setError(errors?.[question?.current?.key] || 'no answer yet');
            return;
        }
        if (isRequired === true && !isCurrentValueValid) {
            setError(errors?.[question?.current?.key] ||
                'had answer before, but cleared out answer on current step');
            return;
        }
        setError(null);
        const filteredFields = altFields
            .map((field) => generateFieldModel(field, getValues))
            .filter((field) => field !== null);
        const lastIndexof = filteredFields
            .map((field) => `${field?.key || ''} - ${field?.label || ''}`)
            .lastIndexOf(`${question.current?.key || ''} - ${question.current?.label || ''}`);
        const isLastFieldKey = filteredFields[lastIndexof + 1]?.key === LAST_FIELD_KEY;
        setQuestion((questionPrevState) => {
            return {
                ...questionPrevState,
                next: !isLastFieldKey ? filteredFields[lastIndexof + 1] : null,
                isCompleted: isLastFieldKey
            };
        });
    };
    // This is only for 1 question at a time flow
    useEffect(() => {
        if (showOneFieldAtATime)
            determineNext();
    }, [showOneFieldAtATime, question.current, formValues[question.current.key]]);
    // This is only for 1 question at a time flow
    const goTo = async (step) => {
        setIsFieldSubmitting(true);
        switch (step) {
            case 'previous':
                setCompletedQuestions((prev) => {
                    prev.pop();
                    return [...prev];
                });
                setQuestion((_question) => {
                    return {
                        ..._question,
                        current: _question.previous.key
                            ? altFields.find((field) => field.key === _question.previous?.key)
                            : null,
                        previous: altFields.find((field) => field.key === completedQuestions[completedQuestions.length - 1]),
                        isCompleted: false
                    };
                });
                break;
            case 'next':
                const prevAnswer = question.current.defaultValue || '';
                const newAnswer = formValues[question.current.key] || '';
                if (prevAnswer !== newAnswer) {
                    try {
                        const fieldToSave = {
                            [question.current.key]: formValues[question.current.key]
                        };
                        await onSubmit(id, fieldToSave);
                    }
                    catch (gotoNextSubmitError) {
                        console.log(paramCase('gotoNextSubmitError'), gotoNextSubmitError);
                    }
                }
                try {
                    if (completedQuestions.length === 0) {
                        await onStatusChange(id, 'IN_PROGRESS');
                    }
                }
                catch (gotoNextStatusChangeError) {
                    console.log(paramCase('gotoNextStatusChangeError'), gotoNextStatusChangeError);
                }
                setCompletedQuestions((prev) => {
                    return [...prev, question.current.key];
                });
                setQuestion((_question) => {
                    return {
                        ..._question,
                        current: { ..._question.next },
                        previous: altFields.find((field) => field.key === _question.current.key)
                    };
                });
                break;
            default:
                break;
        }
        setIsFieldSubmitting(false);
    };
    // This is only for all questions at a time flow
    useEffect(() => {
        if (!showOneFieldAtATime) {
            const { isValid } = formState;
            const isAllFieldsCompleted = !allRequiredFields.some((field) => {
                return !formValues?.[field.key];
            });
            // console.log('isValid', isValid)
            // console.log('isAllFieldsCompleted', isAllFieldsCompleted)
            // console.log('formValues', formValues)
            setAllowSubmit(isValid ?? isAllFieldsCompleted);
        }
    }, [formValues]);
    // This is for both 1x and all
    const onSubmitHandler = async (data) => {
        delete data[LAST_FIELD_KEY];
        console.log('submitting', data);
        if (flowSlug === CREATE_NEW_FLOW_SLUG) {
            const createTemplate = async () => {
                try {
                    if (onCreateFlowFromTemplateSelection) {
                        const newFlow = await onCreateFlowFromTemplateSelection(data);
                        if (newFlow.slug) {
                            // route to newly created flow in edit mode
                            navigate(`/flow/${newFlow.slug}?mode=edit`);
                            return;
                        }
                    }
                }
                catch (createFlowFromTemplateError) {
                    console.error(createFlowFromTemplateError);
                }
                return;
            };
            await createTemplate();
        }
        try {
            await onSubmit(id, data);
            await onStatusChange(id, 'COMPLETED');
            if (flowSlug === CREATE_NEW_FLOW_SLUG)
                return;
            // if (!next) {
            //   navigate(`/flow/${flowSlug}`)
            //   return
            // }
            navigate(`../${next}`);
        }
        catch (onSubmitHandlerError) {
            console.log(paramCase('onSubmitHandler'), onSubmitHandlerError);
        }
    };
    return (<Card variant='outlined' id={`section-${id}`}>
      <CardContent>
        {/* <Box sx={{ padding: 0 }} id={`section-${id}`} className='section'> */}
        {/* {(flowSettings?.showControls || false) && (
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={showOneFieldAtATime}
                onChange={() => {
                  setShowOneFieldAtATime(!showOneFieldAtATime)
                }}
              />
            }
            label={`Show all questions`}
          />
        </FormGroup>
      )} */}
        {header?.label ? (<Typography variant='h3' component='header' sx={{
                paddingBottom: 2
            }}>
            {header?.label}
          </Typography>) : null}
        {header?.sub ? (<Typography variant='h5' color='text.secondary' component='p' sx={{
                paddingBottom: 2
            }} dangerouslySetInnerHTML={{
                __html: formatTextarea(header?.sub)
            }}/>) : null}
        {flowSlug === CREATE_NEW_FLOW_SLUG && searchParams.get('template') && (<Typography variant='h5' component='p' sx={{
                pb: 2,
                textDecoration: 'capitalize'
            }}>
            With {searchParams.get('template')?.split('-').join(' ')}
          </Typography>)}
        {!showOneFieldAtATime ? (<Box py={2} className='altform-fields'>
            {!isSubmitting ? (<Fragment key={`${id}-full`}>
                <Grid key={`${id}-full`} container spacing={1} alignItems='center' justifyItems='center' justifyContent='center'>
                  {altFields
                    ?.map((field) => generateFieldModel(field, getValues))
                    ?.filter((field) => field !== null)
                    ?.map((field) => {
                    return (<Grid item xs={12} sm={12} md={12} key={field.key}>
                          {generateAltField({
                            ...field,
                            defaultValue: formValues?.[field.key] ?? field?.defaultValue,
                            setValue
                        }, getValues, setValue, control, errors)}
                        </Grid>);
                })}
                </Grid>
                <Button variant='contained' type='submit' disabled={!allowSubmit} onClick={handleSubmit(onSubmitHandler)}>
                  {next ? 'Continue' : 'Submit'}
                </Button>
              </Fragment>) : (<Code />)}
          </Box>) : (<Box paddingTop={2} paddingBottom={2} key={`${id}-1-at-a-time`}>
            {!(isFieldSubmitting || isSubmitting) ? (<>
                <Box paddingBottom={2} className='altform-field'>
                  {generateAltField({
                    ...question.current,
                    defaultValue: getValues(question.current.key) ??
                        question.current.defaultValue,
                    setValue
                }, getValues, setValue, control, errors)}
                </Box>
                <Box sx={{ pt: 2 }} id='altform-action'>
                  {question.previous?.key ? (<Button type='button' onClick={() => goTo('previous')}>
                      Back
                    </Button>) : null}
                  {question.next?.key ? (<Button variant='contained' type='button' disabled={(Object.keys(errors)?.length > 0 || error !== null) ??
                        false} onClick={() => goTo('next')}>
                      Continue
                    </Button>) : null}
                  {question.isCompleted ? (<Button variant='contained' type='submit' disabled={(Object.keys(errors)?.length > 0 || error !== null) ??
                        false} onClick={handleSubmit(onSubmitHandler)}>
                      {next ? 'Continue' : 'Submit'}
                    </Button>) : null}
                </Box>
              </>) : (<Code />)}
          </Box>)}
        {/* </Box> */}
      </CardContent>
    </Card>);
};
