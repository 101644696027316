import { parseISO, formatDistance } from "date-fns";
export const formatDate = (date) => {
    try {
        const isoDate = parseISO(date);
        const dateDiff = formatDistance(isoDate, new Date(), { addSuffix: true });
        return dateDiff;
    }
    catch (error) {
        return parseISO(date).toLocaleString();
    }
};
