import { MenuBookOutlined } from '@mui/icons-material';
import { Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar } from '@mui/material';
import { PoweredBy } from 'components/altfield/PoweredBy';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DRAWER_WIDTH } from 'style';
export const FlowSidebar = ({ flow, isEmbed }) => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const flowEntryPath = isEmbed ? `/` : `/flow/${flow.slug}`;
    return flow.settings?.showSidebar ? (<Drawer variant='permanent' id='flow-sidebar' sx={{
            display: { xs: 'none', md: 'block' },
            width: DRAWER_WIDTH,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
                width: DRAWER_WIDTH,
                boxSizing: 'border-box'
            }
        }}>
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>
        <List>
          <ListItem key={flow.name} disablePadding onClick={() => {
            navigate(flowEntryPath);
        }}>
            <ListItemButton>
              <ListItemIcon>
                <MenuBookOutlined />
              </ListItemIcon>
              <ListItemText primary={flow.name} primaryTypographyProps={{
            sx: { fontWeight: 'bold' }
        }}/>
            </ListItemButton>
          </ListItem>
          {flow.sections.map(({ header: { label }, slug: sectionSlug }) => (<ListItem key={label} disablePadding {...(flow.settings?.navigateFreely
            ? {
                onClick: () => {
                    navigate(isEmbed
                        ? `${sectionSlug}`
                        : `/flow/${flow.slug}/${sectionSlug}`);
                }
            }
            : {})}>
              <ListItemButton selected={isEmbed
                ? `/${sectionSlug}` === location.pathname
                : sectionSlug === params?.['*']} disabled={!((isEmbed
                ? `/${sectionSlug}` === location.pathname
                : sectionSlug === params?.['*']) ||
                flow.settings?.navigateFreely)}>
                <ListItemIcon></ListItemIcon>
                <ListItemText primary={label}/>
              </ListItemButton>
            </ListItem>))}
        </List>
        <Divider />
        <PoweredBy align='center'/>
      </Box>
    </Drawer>) : (<></>);
};
