import { Grid } from '@mui/material';
import React from 'react';
import ContentLoader from 'react-content-loader';
export const Placeholder = ({ size }) => {
    return (<Grid container spacing={1} paddingBottom={1}>
      {[...Array(size).keys()].map((_, index) => {
            return (<Grid item sm={6} md={4} key={index} sx={{
                    display: 'grid',
                    width: '100%'
                }}>
            <ContentLoader speed={2} width={476} height={124} viewBox='0 0 476 124' backgroundColor='#f3f3f3' foregroundColor='#ecebeb'>
              <circle cx='20' cy='20' r='20'/>
              <rect x='48' y='8' rx='3' ry='3' width='88' height='6'/>
              <rect x='48' y='26' rx='3' ry='3' width='52' height='6'/>
              <rect x='0' y='56' rx='3' ry='3' width='240' height='6'/>
              <rect x='0' y='72' rx='3' ry='3' width='200' height='6'/>
              <rect x='0' y='88' rx='3' ry='3' width='178' height='6'/>
            </ContentLoader>
          </Grid>);
        })}
    </Grid>);
};
