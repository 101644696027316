import { BoltOutlined } from '@mui/icons-material';
import { Box, Button, Link } from '@mui/material';
import React from 'react';
export const PoweredBy = ({ align = 'right' }) => {
    return (<Box display={'flex'} pt={4} justifyContent={align}>
      <Button component={Link} variant='outlined' sx={{ textAlign: 'center' }} type='button' target={'_blank'} href={process.env.REACT_APP_ALTFLOW_URL} startIcon={<BoltOutlined />}>
        Powered by Altflow
      </Button>
    </Box>);
};
