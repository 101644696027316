import altflowAwsApi from './altflow-aws-api';
export const getFlow = async (slug) => {
    try {
        const { data } = await altflowAwsApi.get(`/flows/${slug}`);
        return data;
    }
    catch (error) {
        console.error('error', error);
        return null;
    }
};
export const validateFlowPassword = async (slug, password) => {
    try {
        await altflowAwsApi.post(`/flows/${slug}/validate`, {
            password
        });
    }
    catch (error) {
        throw error;
    }
};
