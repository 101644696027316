import { InfoOutlined } from '@mui/icons-material';
import { Box, FormHelperText, InputLabel, Tooltip } from '@mui/material';
import React from 'react';
import ReactPlayer from 'react-player';
export const AltVideo = ({ label, altField, controlledField, getValues, setValue, error, condition }) => {
    return (<>
      <InputLabel sx={{ display: 'flex', alignItems: 'center' }}>
        {label}
        <Tooltip title={altField?.required
            ? 'You must watch the video before moving on'
            : 'This video is optional to watch'} placement='right' sx={{
            ml: '4px'
        }}>
          <InfoOutlined fontSize='inherit'/>
        </Tooltip>
      </InputLabel>
      <FormHelperText>{altField.sub || ''}</FormHelperText>
      <Box component={'div'} className='video-player-wrapper' sx={{
            minWidth: {
                sm: '100%',
                md: 'unset'
            },
            height: {
                sm: '300px',
                md: '420px'
            }
        }}>
        <ReactPlayer url={altField.watch} controls={true} loop={false} width='100%' height='100%' onEnded={() => {
            // console.log('video ended')
            try {
                altField?.props?.onEnded && altField?.props?.onEnded();
                controlledField.onChange('completed');
            }
            catch (videoOnEndedError) {
                console.log('error in video onEnded', videoOnEndedError);
            }
        }}/>
      </Box>
    </>);
};
