export const FIELD_CONDITIONS_EDIT_SETTINGS = [
    {
        id: '63536644f662ae82d97a160f',
        key: 'parentFieldId',
        component: 'select',
        label: 'When Field',
        sub: '',
        defaultValue: null,
        tip: null,
        type: null,
        choices: null,
        info: null,
        watch: null,
        required: false,
        autocomplete: false,
        multiple: false,
        ordinal: 0,
        isPrivate: false,
        isHidden: false,
        hasChildren: false,
        sectionId: '63536643f662ae82d97a1678',
        createdAt: '2022-10-22T03:40:52.898Z',
        updatedAt: '2022-10-22T03:40:52.898Z',
        props: {}
    },
    {
        id: '63536644f662ae82d97a160f',
        key: 'condition.value',
        component: 'input',
        label: 'Has Value',
        sub: '',
        tip: null,
        type: null,
        choices: null,
        info: null,
        watch: null,
        required: true,
        autocomplete: false,
        multiple: false,
        ordinal: 0,
        isPrivate: false,
        isHidden: false,
        hasChildren: false,
        sectionId: '63536643f662ae82d97a1678',
        createdAt: '2022-10-22T03:40:52.898Z',
        updatedAt: '2022-10-22T03:40:52.898Z',
        parent: 'parentFieldId',
        props: {}
    },
    {
        id: '63536644f662ae82d97a160f',
        key: 'condition.type',
        component: 'select',
        label: 'Then this Field will',
        sub: '',
        tip: null,
        type: null,
        choices: [
            { display: 'Show', value: 'show' },
            { display: 'Hide', value: 'hide' }
        ],
        info: null,
        watch: null,
        required: true,
        autocomplete: false,
        multiple: false,
        ordinal: 0,
        isPrivate: false,
        isHidden: false,
        hasChildren: false,
        sectionId: '63536643f662ae82d97a1678',
        createdAt: '2022-10-22T03:40:52.898Z',
        updatedAt: '2022-10-22T03:40:52.898Z',
        parent: 'parentFieldId',
        props: {}
    }
    // {
    //   id: '63536644f662ae82d97a160f',
    //   key: 'condition',
    //   component: 'input',
    //   label: 'Condition',
    //   sub: '',
    //   format: 'json',
    //   tip: null,
    //   type: null,
    //   choices: null,
    //   info: null,
    //   watch: null,
    //   required: false,
    //   autocomplete: false,
    //   multiple: false,
    //   ordinal: 0,
    //   isPrivate: false,
    //   isHidden: false,
    //   hasChildren: false,
    //   sectionId: '63536643f662ae82d97a1678',
    //   createdAt: '2022-10-22T03:40:52.898Z',
    //   updatedAt: '2022-10-22T03:40:52.898Z',
    //   parent: 'parentFieldId',
    //   props: {
    //     sx: {
    //       // display: 'none'
    //     }
    //   }
    // }
];
