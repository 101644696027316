import { Add, DashboardCustomizeOutlined, InsightsOutlined, ListAltOutlined, PaletteOutlined, PreviewOutlined, SaveOutlined, Share, SportsScoreOutlined, TitleOutlined } from '@mui/icons-material';
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Drawer, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Snackbar, Toolbar, Tooltip, Typography } from '@mui/material';
import { FlowEditContext } from 'contexts/FlowContext';
import { generateNanoId } from 'forms/alt-field';
import { formatTextarea } from 'forms/format';
import { FlowOverview } from 'pages/public/FlowOverview';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { deleteSection, updateSection } from 'services/sections-service';
import { DRAWER_WIDTH, rawTheme } from 'style';
import { handleScrollTo } from 'style/utils';
import { FlowEdit } from './FlowEdit';
import { SectionEditV2 } from './SectionEditV2';
export const FlowOverviewEdit = ({ flow, sections }) => {
    const params = useParams();
    const [shareLinkSuccessful, setShareLinkSuccessful] = useState(false);
    const [sortedSections, setSortedSections] = useState(sections.map((section, index) => {
        return {
            ...section,
            ordinal: index + 1
        };
    }));
    const [flowEdited, setFlowEdited] = useState(flow);
    console.log('slug', flowEdited.slug); // re-rendering constantly (found out the issue: setPreview)
    // for preview only
    const [showPreview, setShowPreview] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    // this is so that the flow will know what is currently being saved
    const [inProgressSavings, setInProgressSavings] = useState({});
    const handleDeleteSection = async (section) => {
        // new section created have starting id as 'new'
        if (section.id && !section.id.startsWith('new-')) {
            await deleteSection(flowEdited.slug, section.id);
        }
        setSortedSections((_sections) => 
        // compare against slug because id are not there for new section yet
        _sections
            .filter((s) => s.slug !== section.slug)
            .map((section, index) => {
            return {
                ...section,
                ordinal: index + 1
            };
        }));
    };
    const handleCreateSection = (startIndex = 0) => {
        const newId = generateNanoId();
        const newSection = {
            id: `new-${newId}`,
            slug: newId,
            header: {
                label: `Section ${sortedSections?.length + 1}`,
                sub: ''
            },
            fields: [
                {
                    key: generateNanoId(),
                    label: 'Your question here'
                }
            ]
        };
        if (startIndex > 0) {
            const tmpSections = Array.from(sortedSections);
            tmpSections.splice(startIndex, 0, newSection);
            setSortedSections(tmpSections.map((section, index) => {
                return {
                    ...section,
                    ordinal: index + 1
                };
            }));
            return;
        }
        setSortedSections((prev) => {
            return [
                ...prev.map((section, index) => {
                    return {
                        ...section,
                        ordinal: index + 1
                    };
                }),
                {
                    ...newSection,
                    ordinal: prev?.length ?? 1
                }
            ];
        });
        handleScrollTo(`section-${newSection.id}`, 2);
    };
    const handleCloneSection = async (section) => {
        const deepCloneSection = () => {
            try {
                return JSON.parse(JSON.stringify(section));
            }
            catch (error) {
                return section;
            }
        };
        const currentIndex = section.ordinal;
        const newId = generateNanoId();
        const newSection = {
            ...deepCloneSection(),
            id: `new-${newId}`,
            slug: newId,
            ordinal: currentIndex + 1
        };
        const tmpSections = Array.from(sortedSections);
        tmpSections.splice(currentIndex, 0, newSection);
        setSortedSections(tmpSections.map((section, index) => {
            return {
                ...section,
                ordinal: index + 1
            };
        }));
        handleScrollTo(`section-${newSection.id}`, 1);
    };
    useEffect(() => {
        console.log('savings', inProgressSavings);
        if (isSaving === true &&
            Object?.values(inProgressSavings || {}).every((state) => state === false)) {
            setIsSaving(false);
        }
    }, [inProgressSavings]);
    const handleSaveSection = async (data, fieldKeysInOrder) => {
        console.log('submitting', data);
        const { id: sectionId, header, fields, settings, slug: sectionSlug } = data;
        setInProgressSavings((prev) => {
            return { ...prev, [sectionId]: header?.label };
        });
        try {
            const persistedSection = await updateSection(flowEdited.slug, sectionId, {
                ...header,
                ordinal: sortedSections.findIndex((section) => section.id === sectionId) + 1 ?? sortedSections.length,
                slug: sectionSlug,
                settings,
                fields: fieldKeysInOrder.reduce((acc, curr, index) => {
                    if (acc[curr]) {
                        acc[curr] = {
                            ...acc[curr],
                            ordinal: index + 1,
                            multiple: acc?.[curr]?.multiple ?? false,
                            condition: acc?.[curr]?.condition ?? null,
                            ...{
                                choices: acc?.[curr]?.choices
                                    ? acc?.[curr]?.choices
                                        ?.filter((choice) => choice?.display?.length > 0)
                                        .map((choice) => {
                                        return {
                                            display: choice?.display,
                                            value: choice?.value ?? choice?.display,
                                            ...(choice?.image
                                                ? { image: choice?.image }
                                                : undefined)
                                        };
                                    })
                                    : undefined
                            }
                        };
                        // removed since we want to have parentFieldId = ''; so API can remove it
                        // if (!acc[curr]?.parentFieldId) delete acc[curr]?.parentFieldId
                        // if (!acc[curr]?.condition) delete acc[curr]?.condition
                    }
                    return acc;
                }, fields)
            });
            setSortedSections((prev) => {
                return prev?.map((section) => {
                    if (section.id === sectionId) {
                        return {
                            ...persistedSection,
                            fields: persistedSection?.fields.map((field) => {
                                return { ...field };
                            })
                        };
                    }
                    return section;
                });
            });
        }
        catch (error) {
            console.log('error', error);
        }
        finally {
            setInProgressSavings((prev) => {
                return { ...prev, [sectionId]: false };
            });
        }
    };
    const handleUpdateFlow = async () => {
        setIsSaving(true);
    };
    const savingInProgressMessage = useMemo(() => {
        const values = Object.values(inProgressSavings).filter((s) => s);
        if (values.length > 0) {
            const message = values.map((headline, index) => {
                return (<>
            <Box key={`saving-${index}`} sx={{ pd: 1 }}>
              {headline}
            </Box>
            <br />
          </>);
            });
            return message;
        }
        return '';
    }, [inProgressSavings]);
    return (<FlowEditContext.Provider value={{
            isSubmitting: isSaving,
            setIsSubmitting: setIsSaving,
            slug: flowEdited.slug
        }}>
      {/* flow: start */}
      <Drawer variant='permanent' className='flow-edit-sidebar' sx={{
            display: { xs: 'none', md: 'flex' },
            width: DRAWER_WIDTH,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
                width: DRAWER_WIDTH,
                boxSizing: 'border-box'
            }
        }}>
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            <ListItem key={flowEdited.name}>
              <ListItemIcon>
                <DashboardCustomizeOutlined />
              </ListItemIcon>
              <ListItemText primary={`Flow: ${flowEdited.name}`} primaryTypographyProps={{
            sx: { fontWeight: 'bold' }
        }}/>
            </ListItem>
            <ListItem key={'flow-edit-header'}>
              <ListItemButton onClick={() => handleScrollTo(`flow-${flowEdited.slug}`)}>
                <ListItemIcon>
                  <TitleOutlined />
                </ListItemIcon>
                <ListItemText primary={'Starting Page'}/>
              </ListItemButton>
            </ListItem>
            <ListItem key={'flow-theming'}>
              <ListItemButton onClick={() => handleScrollTo(`flow-theme`)}>
                <ListItemIcon>
                  <PaletteOutlined />
                </ListItemIcon>
                <ListItemText primary={'Theme'}/>
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem key={'flow-sections'}>
              <ListItemIcon>
                <ListAltOutlined />
              </ListItemIcon>
              <ListItemText primary={'Sections + Fields'} primaryTypographyProps={{
            sx: { fontWeight: 'bold' }
        }}/>
            </ListItem>
            {sortedSections?.map(({ header: { label }, slug: sectionSlug, id }) => (<ListItem key={`list-item-${id}`}>
                  <ListItemButton selected={sectionSlug === params?.['*']} onClick={() => handleScrollTo(`section-${id}`)}>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary={label}/>
                  </ListItemButton>
                </ListItem>))}
            <ListItem key={'add-section'}>
              <ListItemButton onClick={() => handleCreateSection()}>
                <ListItemIcon>
                  <IconButton color='primary' size='small' aria-label={`create section`}>
                    <Add />
                  </IconButton>
                </ListItemIcon>
                <ListItemText primary={'Add Section'}/>
              </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem key={'flow-ending-edit'}>
              <ListItemButton onClick={() => handleScrollTo(`flow-ending-edit`)}>
                <ListItemIcon>
                  <SportsScoreOutlined />
                </ListItemIcon>
                <ListItemText primary={'Flow Ending'}/>
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Box component='main' sx={{
            width: '100%',
            pb: {
                xs: '20vh',
                md: '70vh'
            }
        }}>
        <Box id='edit-flow-bar' sx={{
            px: 2,
            position: 'fixed',
            width: {
                xs: '100%',
                md: `calc(100% - ${DRAWER_WIDTH + 0}px)`
            },
            zIndex: 999,
            backgroundColor: rawTheme.palette.common.white
            // backgroundColor: rawTheme.palette.primary.main
        }}>
          <Box sx={{
            display: 'flex',
            position: 'relative',
            justifyContent: 'space-between',
            alignItems: 'center'
        }}>
            <Box>
              <Typography variant='h4' component='header'>
                Edit
              </Typography>
            </Box>
            <Box sx={{
            display: 'flex',
            position: 'relative',
            justifyContent: 'space-between',
            // alignItems: 'center',
            py: 1
        }}>
              <Button size='small' variant='outlined' aria-label='save flow' onClick={handleUpdateFlow} sx={{
            backgroundColor: '#1976d21f',
            border: 'none',
            fontSize: '16px',
            mr: 1
        }} startIcon={<SaveOutlined />}>
                Save
              </Button>
              <Tooltip title={`this feature is still in beta`} placement='bottom'>
                <Button size='small' variant='outlined' aria-label='preview flow' onClick={() => {
            setShowPreview(true);
        }} disabled={sortedSections.length < 1} sx={{
            backgroundColor: '#1976d21f',
            border: 'none',
            fontSize: '16px',
            mr: 1
        }} startIcon={<PreviewOutlined />}>
                  Preview
                </Button>
              </Tooltip>
              <Button size='small' variant='outlined' aria-label='edit' sx={{
            backgroundColor: '#1976d21f',
            border: 'none',
            fontSize: '16px',
            mr: 1
        }} startIcon={<InsightsOutlined />}>
                <Link to={`/dashboard/flow/${flow.slug}`}>Results</Link>
              </Button>
              <Button size='small' variant='outlined' aria-label='edit' onClick={() => {
            navigator.clipboard.writeText(`${process.env.REACT_APP_ALTFLOW_URL}flow/${flowEdited.slug}`);
            setShareLinkSuccessful(true);
        }} disabled={sortedSections.length < 1} sx={{
            backgroundColor: '#1976d21f',
            border: 'none',
            fontSize: '16px'
        }} startIcon={<Share />}>
                Share
              </Button>
            </Box>
          </Box>
          <Divider />
        </Box>
        <Box id='empty-div' sx={{ height: '50px' }}/>
        <FlowEdit flow={flow} isSubmitting={isSaving} setInProgressSavings={setInProgressSavings}>
          <Grid container sx={{ pt: 1, justifyContent: 'center' }}>
            {sortedSections?.map((section, index) => {
            return (<Fragment key={`section-container-${index}`}>
                  <Box id={`section-${section.id}`} sx={{
                    minWidth: '100%'
                }} key={section.id}>
                    <SectionEditV2 section={section} handleDeleteSection={handleDeleteSection} handleSaveSection={handleSaveSection} handleCloneSection={handleCloneSection}/>
                  </Box>
                  <Box sx={{
                    py: 1,
                    justifyContent: 'center',
                    display: 'flex',
                    // contentVisibility: 'hidden',
                    ':hover': {
                        contentVisibility: 'visible',
                        color: 'red'
                    }
                }}>
                    <Tooltip title={`create section after ${section?.header?.label ?? 'this section'}`} placement='right'>
                      <IconButton color='primary' aria-label={`create section at index ${index + 1}`} onClick={() => handleCreateSection(index + 1)}>
                        <Add />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Fragment>);
        })}
          </Grid>
        </FlowEdit>
      </Box>
      {/* flow: end */}

      <Snackbar open={shareLinkSuccessful} autoHideDuration={1000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() => setShareLinkSuccessful(false)} message='Share URL copied!'/>

      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSaving}>
        <Box sx={{
            minHeight: '100%',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
        }}>
          <Box sx={{ pb: 1 }}>
            <CircularProgress />
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center'
        }}>
            <Typography variant='h3' component='h3' sx={{ pb: 1 }}>
              Saving your flow... please wait!
            </Typography>
            <Typography variant='h4' component='h4'>
              {savingInProgressMessage}
            </Typography>
          </Box>
        </Box>
      </Backdrop>

      <Dialog open={showPreview} keepMounted={false} maxWidth='xl' fullWidth onClose={() => setShowPreview(false)}>
        <DialogTitle>Flow Preview</DialogTitle>
        <DialogContent sx={{ p: 2 }}>
          <>
            <Typography variant='h2' component='header' sx={{
            paddingBottom: 2
        }}>
              {flowEdited.name}
            </Typography>
            <Typography variant='body1' component='p' sx={{
            paddingBottom: 2
        }} dangerouslySetInnerHTML={{
            __html: formatTextarea(flowEdited.description)
        }}/>
          </>
          <FlowOverview endingMessage={null} sections={sortedSections} answers={{}} statuses={{}} 
    // statuses={{ test: 'IN_PROGRESS' }}
    isPreview={true}/>
        </DialogContent>
      </Dialog>
    </FlowEditContext.Provider>);
};
