import { OrganizationSwitcher, SignInButton, UserButton, useUser } from '@clerk/clerk-react';
import { Button } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
export default function Nav() {
    const { user } = useUser();
    const location = useLocation();
    const isPublicRoute = useMemo(() => {
        if (location.pathname === '/' || location.pathname.startsWith('/dashboard'))
            return false;
        if (location.pathname.startsWith('/flow/') &&
            location.search.includes('edit'))
            return false;
        return true;
    }, [location]);
    return (<Box sx={{
            flexGrow: 1,
            minHeight: {
                xs: '56px',
                sm: '64px'
            },
            zIndex: (theme) => theme.zIndex.drawer + 1
        }}>
      <AppBar position='fixed' sx={{
            // backgroundColor: 'gray',
            zIndex: (theme) => theme.zIndex.drawer + 1
        }}>
        <Toolbar>
          {/* <IconButton size='large' edge='start' aria-label='menu'>
          <MenuIcon />
        </IconButton> */}
          <Typography variant='h4' color='text.primary' component='a' href='/' sx={{
            cursor: 'pointer',
            textDecoration: 'none',
            color: 'none'
        }}>
            altflow
          </Typography>
          <span style={{
            flexGrow: 1
        }}></span>
          {!isPublicRoute && (<OrganizationSwitcher afterCreateOrganizationUrl='/dashboard?org=create'/>)}
          {user ? (<>
              <Typography variant='h4' component='h4' sx={{
                mx: 1
            }}>
                {user.firstName}
              </Typography>
              <UserButton />
            </>) : (<SignInButton mode='modal' redirectUrl={location.pathname}>
              <Button size='small' variant='outlined' aria-label='sign in or sign up'>
                Log in / Sign up
              </Button>
            </SignInButton>)}
        </Toolbar>
      </AppBar>
    </Box>);
}
