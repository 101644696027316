import { SignUp, useUser } from '@clerk/clerk-react';
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
/**
 * require user to sign-in if isAnonymous is false
 * @param param0
 * @returns
 */
export const RequireAuth = ({ isRequired, flowName, slug }) => {
    const { user, isLoaded } = useUser();
    return (<Dialog open={isRequired === true && isLoaded === true && !user?.id} keepMounted={false} fullWidth={true} maxWidth='xl' disableEscapeKeyDown sx={{
            display: 'flex',
            justifyContent: 'center'
        }}>
      <DialogTitle>{flowName}</DialogTitle>
      <DialogContent sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          {!user?.id && (<>
              <SignUp afterSignInUrl={`/flow/${slug}`} afterSignUpUrl={`/flow/${slug}`} redirectUrl={`/flow/${slug}`}/>
            </>)}
        </Box>
      </DialogContent>
    </Dialog>);
};
