/**
 *
 * @param list current list
 * @param startIndex starting index
 * @param endIndex ending index
 * @returns new ordered list
 */
export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};
