import { FilePresentOutlined } from '@mui/icons-material';
import { Box, Link } from '@mui/material';
import { formatTextarea } from 'forms/format';
import React, { useState } from 'react';
export const FileUploadPreview = ({ answer }) => {
    const previews = Array.isArray(answer) ? [...answer] : [answer];
    return (<>
      {previews?.map((preview, index) => {
            return <ImagePreview key={`file-preview-${index}`} src={preview}/>;
        })}
    </>);
};
export const ImagePreview = ({ src, alt, children }) => {
    const [isError, setIsError] = useState(false);
    return !isError ? (<Box sx={{
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid black',
            borderRadius: '18px',
            textAlign: 'center',
            p: 1,
            mr: 1
        }}>
      <img src={formatTextarea(src)} alt={alt || 'file preview'} loading='lazy' style={{
            maxHeight: '100px',
            maxWidth: '100%',
            paddingLeft: '8px',
            paddingTop: '8px'
        }} onLoad={() => {
            URL.revokeObjectURL(src);
        }} onError={(e) => {
            setIsError(true);
            e.currentTarget.src = '';
        }}/>
      {children}
    </Box>) : (<>
      <Link target={'_blank'} href={src}>
        <FilePresentOutlined />
        <span>{src}</span>
        {children}
      </Link>
    </>);
};
