import { AddOutlined, PriorityHighOutlined } from '@mui/icons-material';
import { Box, Chip, FormHelperText, IconButton, InputAdornment, InputBase, ListItem, Paper, Typography } from '@mui/material';
import React, { useState } from 'react';
import { fieldHoverStyle } from 'style';
export const ChipList = ({ label, altField, controlledField, getValues, setValue, error, condition }) => {
    const [chipValue, setChipValue] = useState('');
    const handleKeyDownChip = (evt) => {
        if (['Enter', 'Tab'].includes(evt.key)) {
            evt.preventDefault();
            setChipList(evt);
        }
    };
    const setChipList = (evt) => {
        if (!getValues(altField.key)?.includes(chipValue.trim()) &&
            chipValue !== '') {
            setValue(altField.key, [...controlledField?.value, chipValue.trim()], {
                shouldTouch: true,
                shouldValidate: true,
                shouldDirty: true
            });
            setChipValue('');
            evt.target.value = '';
        }
    };
    const handleChangeChip = (evt) => {
        setChipValue(evt.target.value);
    };
    return (<Box key={altField.key}>
      <Typography variant='body1' component='span'>
        {label}
      </Typography>
      <FormHelperText>
        {altField.sub || ''} Friendly Reminder: Be sure to press Enter/Return or
        Tab when you are done writing!
      </FormHelperText>
      <InputBase required={true} type='text' sx={{
            flex: 1,
            height: '2em',
            fontSize: '16px',
            width: '100%',
            ...fieldHoverStyle
        }} {...altField.props} 
    // {...GLOBAL_PROPS}
    placeholder={'Type your answer choice and then press Enter/Return or Tab to save'} inputProps={{ 'aria-label': altField.label }} {...condition?.props} value={chipValue} onKeyDown={handleKeyDownChip} onChange={handleChangeChip} endAdornment={chipValue?.length < 1 && error ? (<InputAdornment position='end'>
              <PriorityHighOutlined color='error'/>
            </InputAdornment>) : (chipValue?.length > 0 && (<InputAdornment position='end'>
                <IconButton onClick={setChipList} size='small' color='primary'>
                  <AddOutlined />
                </IconButton>
              </InputAdornment>))} color={error ? 'error' : 'primary'}/>
      <Paper sx={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            listStyle: 'none',
            p: 0.5,
            m: 0,
            maxHeight: '200px',
            overflowX: 'scroll'
        }} component='ul'>
        {(controlledField?.value || [])
            ?.map((choice, index) => {
            return (<ListItem key={`index-${index}-${choice}`}>
                <Chip 
            // icon={icon}
            label={choice} onDelete={() => {
                    const finalValue = controlledField.value?.filter((v) => v !== choice);
                    controlledField.onChange(finalValue.length ? finalValue : '');
                }}/>
              </ListItem>);
        })
            ?.reverse()}
      </Paper>
    </Box>);
};
