import { Add, ContentCopyOutlined, DeleteOutline, DragIndicator, PriorityHighOutlined, SettingsOutlined } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Divider, Drawer, Grid, IconButton, InputAdornment, InputBase, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Toolbar, Tooltip, Typography } from '@mui/material';
import { FlowEditContext } from 'contexts/FlowContext';
import { useFlags } from 'flagsmith/react';
import { generateNanoId } from 'forms/alt-field';
import get from 'lodash/get';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Code } from 'react-content-loader';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { fieldHoverStyle } from 'style';
import { reorder } from './drag-drop-utils';
import { EditSettings } from './EditSettings';
import { FIELD_CONDITIONS_EDIT_SETTINGS } from './settings/FieldConditionsEditSettings';
import { FIELD_EDIT_SETTINGS } from './settings/FieldEditSettings';
import { SECTION_SETTINGS } from './settings/SectionEditSettings';
/**
 * Change how the Field Setting is shown: via Right Side Drawer instead of inside the section box (compare to SectionEdit v1)
 * @param param0
 * @returns
 */
export const SectionEditV2 = ({ section, handleDeleteSection, handleSaveSection, handleCloneSection }) => {
    const { isSubmitting } = useContext(FlowEditContext);
    const [sortedFields, setSortedFields] = useState([...section?.fields] || []);
    const [currentField, setCurrentField] = useState(null);
    const [openFieldSetting, setOpenFieldSetting] = useState(false);
    const methods = useForm({
        mode: 'onChange',
        shouldUnregister: true
    });
    const { unregister, handleSubmit, control, formState, getValues, setValue } = methods;
    const { errors } = formState;
    // if (Object.values(errors || {})?.length > 0) console.log('errors', errors)
    const [isLoading, setIsLoading] = useState(false);
    const flags = useFlags(['edit_add_conditions']);
    const onDragEnd = ({ destination, source }) => {
        // dropped outside the list
        if (!destination)
            return;
        const newSortedFields = reorder(sortedFields, source.index, destination.index);
        if (JSON.stringify(newSortedFields) !== JSON.stringify(sortedFields)) {
            setSortedFields(newSortedFields?.map((field, index) => {
                return {
                    ...field,
                    ordinal: index
                };
            }));
        }
    };
    useEffect(() => {
        const values = getValues()?.fields;
        section.fields.forEach((field) => {
            /**
             * with new field, when it's first created, there is no ID until API returns it.
             * once it's returned from API, we need to set it so when it's being saved again, it's an update to that field.id
             * as it is now an "old" field that is alredy stored; hence, an update.
             */
            if (!values?.[field.key]?.id && field?.id) {
                setValue(`fields.${field.key}.id`, field?.id, {
                    shouldDirty: true,
                    shouldTouch: false,
                    shouldValidate: true
                });
            }
        });
        setSortedFields(section.fields);
    }, [section.fields]);
    /**
     * get latest section data that were changed without save
     * so new cloned section reflected with those changes
     */
    const handleCloneSectionCall = async () => {
        const { settings, header, fields } = getValues();
        const label = (header?.label ?? section?.header?.label ?? '').trim();
        const clonedSection = {
            ...section,
            settings: { ...settings },
            header: {
                ...section.header,
                ...{
                    ...header,
                    label: `${label} [Copy]`
                }
            },
            fields: Object.values(fields || [])?.map((field) => {
                const foundOldFieldProps = section.fields?.find((oF) => oF?.id === field?.id);
                return {
                    ...foundOldFieldProps,
                    ...field
                };
            })
        };
        setIsLoading(true);
        await handleCloneSection(clonedSection);
        setIsLoading(false);
    };
    const handleCloneField = (_altfield) => {
        const latestField = getValues(`fields.${_altfield.key}`);
        const indexAt = _altfield?.ordinal ?? 999;
        const newSortedFields = reorder([
            ...sortedFields,
            {
                ..._altfield,
                ...latestField,
                label: `${latestField?.label} copy`.trim(),
                key: generateNanoId()
            }
        ], sortedFields.length, indexAt + 1);
        const finalFields = newSortedFields?.map((field, index) => {
            return {
                ...field,
                ordinal: index
            };
        });
        setSortedFields(finalFields);
    };
    const handleRemoveField = (_altfield) => {
        setSortedFields((prev) => {
            return prev.filter((field) => field.key !== _altfield.key);
        });
        unregister([`fields.${_altfield.key}`]);
        sortedFields.forEach((field) => {
            // since that parent field is deleted, we need to find fields that have parent key to be cleared out
            if (field?.parent === _altfield.key)
                setValue(`fields.${field.key}.parentFieldId`, '');
        });
        if (currentField?.key === _altfield.key) {
            setCurrentField(null);
        }
    };
    const showFieldSetting = useMemo(() => (_field) => {
        setOpenFieldSetting(true);
        if (currentField?.key !== _field.key) {
            setCurrentField(_field);
        }
    }, [currentField]);
    const handleAddField = () => {
        const newId = generateNanoId();
        setSortedFields((prev) => {
            return [
                ...prev,
                {
                    key: newId,
                    label: '',
                    sub: '',
                    ordinal: (prev?.length || 0) + 1,
                    required: false
                }
            ];
        });
    };
    const getSectionSettings = useCallback(() => {
        return SECTION_SETTINGS.map((setting) => {
            return {
                ...setting,
                key: `${setting.key}`,
                defaultValue: get(section, setting.key, setting?.defaultValue)
            };
        });
    }, [SECTION_SETTINGS]);
    const getFieldSettings = useCallback((altfield) => {
        return FIELD_EDIT_SETTINGS.filter((setting) => {
            if (!flags.edit_add_conditions.enabled) {
                const keysToNotUse = ['parentFieldId', 'condition', 'id'];
                return !keysToNotUse.includes(setting.key);
            }
            return true;
        }).map((setting) => {
            const defaultValue = altfield?.[setting.key] ?? setting?.defaultValue;
            const key = `fields.${altfield.key}.${setting.key}`;
            const parentKey = setting.parent
                ? `fields.${altfield.key}.${setting.parent}`
                : null;
            return {
                ...setting,
                key: key,
                parent: parentKey,
                defaultValue: defaultValue,
                // need to pass this so find-conditions-v2 can pick up
                _metadata: {
                    key: `fields.${altfield.key}`
                }
            };
        });
    }, [FIELD_EDIT_SETTINGS]);
    const getFieldConditionsSettings = useCallback((altfield) => {
        const result = FIELD_CONDITIONS_EDIT_SETTINGS.filter((setting) => {
            if (!flags.edit_add_conditions.enabled) {
                const keysToNotUse = ['parentFieldId', 'condition', 'id'];
                return !keysToNotUse.includes(setting.key);
            }
            return true;
        }).map((setting) => {
            const defaultValue = get(altfield, setting.key, setting?.defaultValue);
            if (setting.key === 'parentFieldId') {
                const choices = sortedFields
                    .filter((f) => f.id !== altfield.id)
                    .map((f) => {
                    return {
                        value: f.id,
                        display: f.label
                    };
                });
                const foundParentField = sortedFields.find((f) => {
                    return defaultValue === f.id;
                });
                return {
                    ...setting,
                    key: `fields.${altfield.key}.${setting.key}`,
                    parent: setting.parent
                        ? `fields.${altfield.key}.${setting.parent}`
                        : null,
                    choices: ['', ...choices],
                    defaultValue: foundParentField?.id || '',
                    // need to pass this so find-conditions-v2 can pick up
                    _metadata: {
                        key: `fields.${altfield.key}`
                    }
                };
            }
            if (setting.key === 'condition.value') {
                const parentFieldValue = altfield?.parentFieldId ?? setting?.defaultValue;
                const foundParentField = sortedFields.find((f) => {
                    return parentFieldValue === f.id;
                });
                return {
                    ...setting,
                    // component: foundParentField?.choices ? 'select' : setting.component,
                    key: `fields.${altfield.key}.${setting.key}`,
                    parent: setting.parent
                        ? `fields.${altfield.key}.${setting.parent}`
                        : null,
                    choices: ['', ...(foundParentField?.choices || [])],
                    defaultValue: defaultValue,
                    // need to pass this so find-conditions-v2 can pick up
                    _metadata: {
                        key: `fields.${altfield.key}`
                    }
                };
            }
            return {
                ...setting,
                key: `fields.${altfield.key}.${setting.key}`,
                parent: setting.parent
                    ? `fields.${altfield.key}.${setting.parent}`
                    : null,
                defaultValue: defaultValue,
                // need to pass this so find-conditions-v2 can pick up
                _metadata: {
                    key: `fields.${altfield.key}`
                }
            };
        });
        return result;
    }, [FIELD_CONDITIONS_EDIT_SETTINGS, sortedFields]);
    useEffect(() => {
        // don't know why but `formState` requires destructuring outside of `if` condition in order for useEffect to work
        // https://react-hook-form.com/api/useform/formstate#:~:text=formState%20is%20updated%20in%20batch.
        const { isValid, errors } = formState;
        if (isSubmitting && isValid === true) {
            try {
                handleSubmit(async (data) => {
                    await handleSaveSection(data, sortedFields?.map((field) => field.key));
                })();
            }
            catch (error) {
                alert(`error saving ${section?.header}`);
            }
            finally {
            }
        }
    }, [isSubmitting]);
    return (<FormProvider {...methods} key={`form-${section.id}`}>
      <form 
    // onSubmit={handleSubmit((data) =>
    //   handleSaveSection(
    //     data,
    //     sortedFields?.map((field) => field.key)
    //   )
    // )}
    noValidate>
        {/* <pre>{JSON.stringify(sortedFields, null, 1)}</pre> */}
        <Card variant='outlined' key={section.id} sx={{
        // borderLeft: 0
        // borderRadius: '0 18px 18px 0px'
        }}>
          <CardContent>
            {isLoading && <Code />}
            {!isLoading && (<Grid container spacing={1}>
                <Grid item sm={12} key={`${section.id}-edit-actions`} sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                  <Box>
                    <Typography variant='h4' component='header'>
                      Edit Section
                    </Typography>
                  </Box>
                  <Box sx={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                    <Typography variant='h4' component='header'>
                      <Tooltip title={`Clone ${section?.header?.label}`} placement='right'>
                        <IconButton size='small' color='default' type='button' onClick={handleCloneSectionCall} aria-label='clone section'>
                          <ContentCopyOutlined />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                    <Typography variant='h4' component='header'>
                      <Tooltip title={`Delete ${section?.header?.label}`} placement='right'>
                        <IconButton size='small' color='default' type='button' onClick={async () => {
                setIsLoading(true);
                await handleDeleteSection(section);
                setIsLoading(false);
            }} aria-label='delete section'>
                          <DeleteOutline />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                  </Box>
                </Grid>
                <Divider variant='fullWidth' component='li' sx={{ listStyle: 'none' }}/>
                <Grid item sm={12} md={7} key={`${section.id}-edit`} sx={{
                width: '100%'
            }}>
                  <Controller name={`header.label`} control={control} rules={{ required: true }} defaultValue={section.header.label} render={({ field: cfield }) => {
                return (<InputBase key={`${section.id}-header-edit`} type='text' sx={{
                        flex: 1,
                        fontSize: '20px',
                        ...fieldHoverStyle
                    }} fullWidth placeholder='Section Header*' inputProps={{ 'aria-label': 'Section Header' }} endAdornment={errors?.header?.label ? (<InputAdornment position='end'>
                                <PriorityHighOutlined color='error'/>
                              </InputAdornment>) : null} {...cfield}/>);
            }}/>
                  <Controller name={`header.sub`} control={control} rules={{ required: false }} defaultValue={section.header.sub} render={({ field: cfield }) => {
                return (<InputBase key={`${section.id}-sub-edit`} type='text' fullWidth componentsProps={{
                        input: {
                            style: {
                                resize: 'vertical'
                            }
                        }
                    }} sx={{
                        fontSize: '16px',
                        ...fieldHoverStyle
                    }} multiline={true} minRows={2} placeholder='Section Sub Header (Optional)' inputProps={{
                        'aria-label': 'Section Sub Header (Optional)'
                    }} {...cfield}/>);
            }}/>
                  <List sx={{
                // width: '100%',
                py: 0,
                bgcolor: 'background.paper'
            }} subheader={<ListSubheader sx={{ px: 0, lineHeight: 2 }}>
                        Fields/Questions (You can drag and drop to re-arrange)
                      </ListSubheader>}>
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId='droppable-list' key={`${section.id}-droppable`}>
                        {(provided) => {
                return (<div ref={provided.innerRef} {...provided.droppableProps}>
                              {sortedFields.map((altfield, index) => {
                        return (<Draggable 
                        // isDragDisabled={true}
                        draggableId={altfield.key || 'field-unknown'} index={index} key={`section-${section.slug}-${altfield?.key}-draggable`}>
                                    {(providedDrag, snapshot) => {
                                return (<Box ref={providedDrag.innerRef} sx={{
                                        backgroundColor: `${altfield?.key ===
                                            currentField?.key
                                            ? '#f3f3f3'
                                            : 'none'}`,
                                        borderRadius: '18px',
                                        ...(snapshot.isDragging
                                            ? {
                                                background: '#f3f3f3',
                                                border: '1px dashed #000000'
                                            }
                                            : {})
                                    }} {...providedDrag.draggableProps} {...providedDrag.dragHandleProps} key={`section-${section.slug}-${altfield?.key}-field`}>
                                          <>
                                            <ListItem id={`${altfield.key}-edit-list`} key={`${altfield.key}-edit-list`} onDoubleClick={() => {
                                        showFieldSetting(altfield);
                                    }} onClick={() => {
                                        setCurrentField(altfield);
                                        // showFieldSetting(altfield)
                                    }} sx={{
                                        px: 0,
                                        ':hover': {
                                            border: '1px dashed #000000',
                                            borderRadius: '18px'
                                        }
                                    }}>
                                              <ListItemIcon sx={{
                                        px: 1,
                                        minWidth: '40px'
                                    }}>
                                                <DragIndicator />
                                              </ListItemIcon>
                                              <ListItemText id={`${altfield.key}-header-and-sub`} key={`${altfield.key}-header-and-sub`} primary={<>
                                                    <Controller name={`fields.${altfield.key}.label`} control={control} rules={{
                                            required: true
                                        }} defaultValue={altfield.label} render={({ field: cfield }) => (<InputBase key={`${altfield.key}-label-edit`} type='text' {...cfield} sx={{
                                                flex: 1,
                                                height: '2em',
                                                fontSize: '16px',
                                                ...fieldHoverStyle
                                            }} endAdornment={errors?.fields?.[altfield.key]?.label ? (<InputAdornment position='end'>
                                                                <PriorityHighOutlined color='error'/>
                                                              </InputAdornment>) : null} color={errors?.fields?.[altfield.key]?.label
                                                ? 'error'
                                                : 'primary'} error={errors?.fields?.[altfield.key]?.label
                                                ? true
                                                : false} fullWidth placeholder='Put your question here *' inputProps={{
                                                'aria-label': 'Put your question here'
                                            }}/>)}/>
                                                  </>} secondary={<>
                                                    <Controller name={`fields.${altfield.key}.sub`} control={control} rules={{
                                            required: false
                                        }} defaultValue={altfield.sub || ''} render={({ field: cfield }) => (<InputBase key={`${altfield.key}-sub-edit`} type='text' componentsProps={{
                                                input: {
                                                    style: {
                                                        resize: 'vertical'
                                                    }
                                                }
                                            }} {...cfield} sx={{
                                                flex: 1,
                                                fontSize: '16px',
                                                ...fieldHoverStyle
                                            }} fullWidth multiline={(altfield?.sub
                                                ?.length ?? 0) >
                                                50} placeholder='Description (Optional)' inputProps={{
                                                'aria-label': 'Description (Optional)'
                                            }}/>)}/>
                                                  </>} secondaryTypographyProps={{
                                        component: 'div'
                                    }}/>
                                              {/* Field Actions: clone/delete */}
                                              <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        px: 1
                                    }}>
                                                <Tooltip title='Field Setting'>
                                                  <IconButton size='small' color='default' aria-label='field setting' onClick={() => showFieldSetting(altfield)}>
                                                    <SettingsOutlined />
                                                  </IconButton>
                                                </Tooltip>
                                                <Tooltip title='Clone Field'>
                                                  <IconButton size='small' color='default' aria-label='clone field' onClick={() => handleCloneField(altfield)}>
                                                    <ContentCopyOutlined />
                                                  </IconButton>
                                                </Tooltip>
                                                <Tooltip title='Delete Field'>
                                                  <IconButton size='small' color='default' aria-label='delete field' onClick={() => handleRemoveField(altfield)}>
                                                    <DeleteOutline />
                                                  </IconButton>
                                                </Tooltip>
                                              </Box>
                                            </ListItem>
                                            <Divider variant='middle' component='li'/>
                                          </>
                                        </Box>);
                            }}
                                  </Draggable>);
                    })}
                              {provided.placeholder}
                            </div>);
            }}
                      </Droppable>
                    </DragDropContext>
                    <Box sx={{
                px: 2,
                py: 1,
                display: 'flex',
                justifyContent: 'center'
            }}>
                      <Button variant='outlined' aria-label='Create Field' startIcon={<Add />} onClick={handleAddField}>
                        Add
                      </Button>
                    </Box>
                  </List>
                </Grid>
                <Grid item sm={12} md={5} key={`${section.id}-field-setting`} sx={{
                width: '100%'
            }}>
                  <EditSettings id={section.slug} header={'Section Settings'} useExpand={true} settings={getSectionSettings()}/>
                  <Divider variant='fullWidth' component='li' sx={{ listStyle: 'none' }}/>
                  {/* {sortedFields.map((altfield: AltField) => {
              return (
                <Box
                  key={altfield.key}
                  sx={{
                    display:
                      altfield.key === currentField?.key
                        ? 'flex'
                        : 'none',
                    flexDirection: 'column'
                  }}>
                  <EditSettings
                    key={`${altfield.key}-settings`}
                    id={`${altfield.key}-settings`}
                    header={'Field Settings'}
                    useExpand={false}
                    settings={getFieldSettings(altfield)}
                  />
                  <EditSettings
                    key={`${altfield.key}-conditions-settings`}
                    id={`${altfield.key}-conditions-settings`}
                    header={`Field Conditions`}
                    subHeader={`Optional: Leave Blank if you don't have any conditions for this field`}
                    useExpand={false}
                    settings={getFieldConditionsSettings(altfield)}
                  />
                </Box>
              )
            })} */}
                </Grid>
              </Grid>)}
          </CardContent>
        </Card>
        <Drawer keepMounted={true} // need this in order to get the field settings to persist in dom
     sx={{
            width: {
                xs: '95%',
                md: '70%'
            },
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                width: {
                    xs: '95%',
                    md: '50%'
                },
                px: 3
            }
        }} variant='temporary' ModalProps={{
            BackdropProps: {
                invisible: true
            }
        }} anchor='right' open={openFieldSetting} onClose={(_event) => {
            setOpenFieldSetting(false);
        }}>
          <Toolbar />
          {sortedFields.map((altfield) => {
            return (<Box key={altfield.key} sx={{
                    display: altfield.key === currentField?.key ? 'flex' : 'none',
                    flexDirection: 'column'
                }}>
                <EditSettings key={`${altfield.key}-settings`} id={`${altfield.key}-settings`} header={'Field Settings'} useExpand={false} settings={getFieldSettings(altfield)}/>
                <EditSettings key={`${altfield.key}-conditions-settings`} id={`${altfield.key}-conditions-settings`} header={`Field Conditions`} subHeader={`Optional: Leave Blank if you don't have any conditions for this field`} useExpand={false} settings={getFieldConditionsSettings(altfield)}/>
              </Box>);
        })}
        </Drawer>
      </form>
    </FormProvider>);
};
