import { PriorityHighOutlined } from '@mui/icons-material';
import { Box, Card, CardContent, Divider, Grid, InputAdornment, InputBase, Typography } from '@mui/material';
import { Heading } from 'components/Heading';
import { sanitize } from 'forms/sanitize';
import React, { memo, useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { updateFlowBySlug } from 'services/flow-admin-service';
import { fieldHoverStyle } from 'style';
import { EditSettings } from './EditSettings';
import { FLOW_SETTINGS } from './settings/FlowEditSetting';
import { FLOW_THEME_SETTINGS } from './settings/FlowThemeEditSetting';
export const FlowEdit = memo(({ flow, isSubmitting, setInProgressSavings, children }) => {
    const methods = useForm({
        mode: 'onChange',
        shouldUnregister: false
    });
    const { control, formState, getValues } = methods;
    const { errors } = formState;
    const handleUpdateFlow = async () => {
        const values = getValues();
        console.log('flow.values', values);
        setInProgressSavings((prev) => {
            return { ...prev, [flow.slug]: 'headers + setting' };
        });
        const settings = {
            showControls: values.showControls,
            showSidebar: values.showSidebar,
            navigateFreely: values?.navigateFreely || false,
            toRequirePassword: values?.toRequirePassword || false,
            toRequireAuth: values?.toRequireAuth || false,
            passwordRequirement: values?.passwordRequirement || ''
        };
        try {
            await updateFlowBySlug(flow.slug, {
                name: values?.flow?.name ?? flow.name,
                description: sanitize(values?.flow?.description ?? flow.description),
                ending: sanitize(values?.flow?.ending ?? flow?.ending),
                theme: values?.theme,
                settings
            });
        }
        catch (error) {
            console.error('error', error);
        }
        finally {
            setInProgressSavings((prev) => {
                return { ...prev, [flow.slug]: false };
            });
        }
    };
    const submitFlowHead = async () => {
        await handleUpdateFlow();
    };
    useEffect(() => {
        // don't know why but `formState` requires destructuring outside of `if` condition in order for useEffect to work
        // https://react-hook-form.com/api/useform/formstate#:~:text=formState%20is%20updated%20in%20batch.
        // const { isValid, dirtyFields } = formState
        const { isValid, dirtyFields } = formState;
        if (isSubmitting &&
            isValid === true &&
            Object?.keys(dirtyFields)?.length) {
            console.log('calling from useEffect isSubmitting');
            submitFlowHead();
        }
    }, [isSubmitting]);
    const flowEndingMessage = (<>
        Once your user is done with the flow, provide an ending message for
        them. Example:
        <br />
        Thank you for submitting!
        <br />
        We can't wait to connect with you soon!
      </>);
    return (<Box component='main' sx={{ p: 2 }}>
        <Card variant='outlined' key={'flow-edit'}>
          <CardContent>
            <Grid container spacing={1} id={`flow-${flow.slug}`}>
              <Grid item sm={12} md={7} sx={{
            width: '100%'
        }}>
                <Controller name={`flow.name`} control={control} rules={{ required: true }} defaultValue={flow.name} render={({ field: cfield }) => {
            return (<InputBase key={`${flow.slug}-name-edit`} type='text' sx={{
                    flex: 1,
                    fontSize: '20px',
                    fontWeight: 'bold',
                    ...fieldHoverStyle
                }} fullWidth placeholder='Flow name*' inputProps={{ 'aria-label': 'Flow name' }} endAdornment={errors?.flow?.name ? (<InputAdornment position='end'>
                              <PriorityHighOutlined color='error'/>
                            </InputAdornment>) : null} {...cfield}/>);
        }}/>
                <Controller name={`flow.description`} control={control} rules={{ required: false }} defaultValue={flow?.description || ''} render={({ field: cfield }) => {
            return (<InputBase key={`${flow.slug}-description-edit`} type='text' fullWidth componentsProps={{
                    input: {
                        style: {
                            resize: 'vertical'
                        }
                    }
                }} sx={{
                    fontSize: '16px',
                    ...fieldHoverStyle
                }} multiline={true} minRows={2} placeholder='Flow description (Optional)' inputProps={{
                    'aria-label': 'Flow description (Optional)'
                }} {...cfield}/>);
        }}/>
                <Box id='flow-theme' sx={{ pt: 2 }}>
                  <Divider />
                  <Heading>
                    <Typography variant='h4' component='header' sx={{ pt: 2 }}>
                      Flow Theme
                    </Typography>
                  </Heading>
                  <FormProvider {...methods} key={'flow-theme'}>
                    <EditSettings id={'flow-theme'} header={''} useExpand={false} settings={FLOW_THEME_SETTINGS.map((setting) => {
            return {
                ...setting,
                key: `theme.${setting.key}`,
                defaultValue: flow?.theme?.[setting.key] || // others: using section.theme[key]
                    setting?.defaultValue
            };
        })}/>
                  </FormProvider>
                </Box>
              </Grid>
              <Grid item sm={12} md={5} sx={{
            width: '100%'
        }}>
                {/* <Box sx={{ display: 'flex', justifyContent: 'end' }}> */}
                <FormProvider {...methods} key={flow.slug}>
                  <EditSettings id={'flow-settings'} header={'Flow Settings'} useExpand={false} settings={FLOW_SETTINGS.map((setting) => {
            return {
                ...setting,
                key: `${setting.key}`,
                defaultValue: flow?.settings?.[setting.key] ?? // others: using section.settings[key]
                    setting?.defaultValue
            };
        })}/>
                </FormProvider>
                {/* </Box> */}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {children}
        <Divider />
        <Card variant='outlined' key={`flow-ending-edit`} id={`flow-ending-edit`} sx={{
            mt: 3
        }}>
          <CardContent>
            <Grid container sx={{ pt: 1 }} spacing={3}>
              <Grid item sm={12}>
                <Heading>
                  <Typography variant='h4' component='header' sx={{ pb: 1 }}>
                    Flow Ending Message
                  </Typography>
                  <Typography variant='body1' component='p'>
                    {flowEndingMessage}
                  </Typography>
                </Heading>
                <Controller name={`flow.ending`} control={control} rules={{ required: false }} defaultValue={flow?.ending || ''} render={({ field: cfield }) => {
            return (<InputBase key={`${flow.slug}-ending-edit`} type='text' fullWidth componentsProps={{
                    input: {
                        style: {
                            resize: 'vertical'
                        }
                    }
                }} sx={{
                    fontSize: '16px',
                    ...fieldHoverStyle
                }} multiline={true} minRows={2} placeholder='Flow Ending (Optional)' inputProps={{
                    'aria-label': 'Flow ending (Optional)'
                }} {...cfield}/>);
        }}/>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>);
});
