import { customAlphabet } from 'nanoid';
import { findConditionsV2 } from './conditions-v2';
export const generateFieldModel = (currentField, getValues) => {
    const currentParentValue = getValues(`${currentField?.parent}`) || undefined;
    const condition = currentField?.condition &&
        currentField.parent &&
        findConditionsV2(currentField, currentField?.condition || null, getValues);
    if (condition?.hide)
        return null;
    if (currentField?.parent && currentParentValue === undefined)
        return null;
    return {
        ...currentField,
        props: {
            ...(currentField?.props || undefined),
            ...(condition?.props || undefined)
        }
    };
};
export function generateNanoId(length = 5) {
    const nanoid = customAlphabet('abcdefghijklmnopqrstuvwxyz', 10);
    const generated = nanoid(length);
    return generated.toLowerCase();
}
export const CREATE_NEW_FLOW_SLUG = 'create-flow';
export const DEFAULT_NEW_TEMPLATE_NAME = 'template-default-flow';
