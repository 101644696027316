import { Button } from '@mui/material';
import { FileUploadContext } from 'contexts/FileUploadContext';
import React, { useEffect, useMemo, useState } from 'react';
import { useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { uploadFile } from 'services/upload-service';
const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};
const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};
const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};
const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};
const focusedStyle = {
    borderColor: '#2196f3'
};
const acceptStyle = {
    borderColor: '#00e676'
};
const rejectStyle = {
    borderColor: '#ff1744'
};
const UPLOAD_TYPE_MAP = {
    // image: { 'image/*': ['.jpeg', '.png'] },
    image: { 'image/*': [] },
    all: { 'image/*': [], 'application/pdf': ['.pdf'] }
};
/**
 * Note: this implementation is tightly connect to flow right now (not generic enough)
 * @param AltField
 * @returns
 */
export const FileUpload = ({ altField, type, fileType }) => {
    const params = useParams();
    const slug = params.slug;
    const context = useContext(FileUploadContext);
    const [files, setFiles] = useState(altField.defaultValue
        ? [
            {
                name: altField.defaultValue,
                preview: altField.defaultValue
            }
        ]
        : []);
    const { getRootProps, open, getInputProps, isFocused, isDragAccept, isDragReject, fileRejections } = useDropzone({
        accept: UPLOAD_TYPE_MAP[fileType],
        maxFiles: 1,
        onDrop: async (acceptedFiles) => {
            const file = acceptedFiles?.[0];
            const fileUploadUrl = await uploadFile(slug, type, file.type, file);
            /**
             * this is used for PictureSelection and FileUpload
             * because rhf won't trigger setValue and savei t to field-array, this is a workaround
             * ref: altflow-ui/src/components/MatrixGroup.tsx
             */
            context.uploads = {
                ...context.uploads,
                [altField.key]: fileUploadUrl
            };
            if (altField?.setValue) {
                altField?.setValue(altField.key, fileUploadUrl, {
                    shouldDirty: true
                });
            }
            setFiles(acceptedFiles.map((_file) => Object.assign(_file, {
                preview: URL.createObjectURL(_file)
            })));
        }
    });
    const style = useMemo(() => ({
        ...{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '20px',
            borderWidth: 2,
            borderRadius: '18px',
            borderColor: '#eeeeee',
            borderStyle: 'dashed',
            backgroundColor: '#fafafa',
            color: '#bdbdbd',
            outline: 'none',
            transition: 'border .24s ease-in-out'
        },
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [isFocused, isDragAccept, isDragReject]);
    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
    }, []);
    const thumbs = files.map((file) => (<div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} 
    // Revoke data uri after image is loaded
    onLoad={() => {
            URL.revokeObjectURL(file.preview);
        }} onError={(e) => (e.currentTarget.src = '')}/>
      </div>
    </div>));
    return (<>
      <div className='container'>
        {files?.length < 1 && (<div {...getRootProps({ style })}>
            <input {...getInputProps()}/>
            <p>Drag file here or</p>
            <Button size='small' variant='outlined' aria-label='upload' onClick={open}>
              Select file
            </Button>
          </div>)}
        <aside style={thumbsContainer}>{thumbs}</aside>
      </div>
      {fileRejections?.map(({ file, errors }) => (<li key={file.path}>
            {file.path}
            <ul>
              {errors.map((e) => (<li key={e.code}>{e.message}</li>))}
            </ul>
          </li>))}
      {files.length > 0 && (<Button size='small' color='error' variant='outlined' aria-label='delete' onClick={() => {
                files?.forEach((file) => URL.revokeObjectURL(file.preview));
                setFiles([]);
                if (altField?.setValue) {
                    altField?.setValue(altField.key, '', {
                        shouldDirty: true
                    });
                }
            }}>
          Delete
        </Button>)}
    </>);
};
