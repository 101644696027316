import { PaletteOutlined, UploadFileOutlined } from '@mui/icons-material';
import React from 'react';
export const FLOW_THEME_SETTINGS = [
    {
        id: '63536644f662ae82d97a160f',
        component: 'upload',
        key: 'brand-logo',
        label: 'Flow Logo upload',
        sub: 'Set the logo for the flow. Max size: 5MB',
        icon: <UploadFileOutlined />,
        type: 'file',
        choices: null,
        required: false,
        autocomplete: false,
        multiple: false,
        ordinal: 0,
        isPrivate: false,
        isHidden: false,
        hasChildren: false,
        props: {},
        parent: null
    },
    {
        id: '63536644f662ae82d97a160f',
        component: 'input',
        key: 'color-primary',
        label: 'Primary color',
        sub: 'Set the primary color to match your branding using hex',
        example: () => 'eg: #2196f3',
        transform: (value) => {
            if (!value)
                return '';
            return value?.startsWith('#') ? value : `#${value}`;
        },
        icon: <PaletteOutlined />,
        type: 'text',
        choices: null,
        required: false,
        autocomplete: false,
        multiple: false,
        ordinal: 0,
        isPrivate: false,
        isHidden: false,
        hasChildren: false,
        props: {},
        parent: null
    }
];
