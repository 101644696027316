import { PictureAsPdfOutlined } from '@mui/icons-material';
import { Avatar, Box, Button, Grid, Typography } from '@mui/material';
import { PoweredBy } from 'components/altfield/PoweredBy';
import { Placeholder } from 'components/Placeholder';
import { formatTextarea } from 'forms/format';
import React, { useState } from 'react';
import { ArdentMentoringPrint } from '../white-label/ArdentMentoringPrint';
import { PasswordVerification } from './flow/PasswordVerification';
import { FlowOverview } from './FlowOverview';
export const FlowOverviewRouterPublic = ({ flow, answers, statuses }) => {
    const [passwordVerificationSuccess, setPasswordVerificationSuccess] = useState(!flow.settings?.toRequirePassword);
    const [showPrint, setShowPrint] = useState(false);
    const handleClickPrint = () => {
        if (flow.slug !== 'ardent-roadmap') {
            window.print();
        }
        else {
            setShowPrint(true);
        }
    };
    return (<>
      <Box id={`flow-main`} component='main' sx={{
            width: '100%'
        }}>
        <Box sx={{ p: 4 }}>
          <Grid container 
    // spacing={1}
    alignItems='center' justifyItems='center' justifyContent='center'>
            <Grid item sm={3} display='flex' alignItems='center' justifyItems='center' justifyContent='center' sx={{ textAlign: 'center' }}>
              <Avatar alt={`${flow.name} logo`} variant='square' src={flow?.theme?.['brand-logo']} imgProps={{
            sx: {
                objectFit: 'contain'
            }
        }} sx={{
            width: 200,
            minHeight: 200,
            mb: 2
        }}>
                {flow.name?.substring(0, 1)}
              </Avatar>
            </Grid>
            <Grid item sm={9} sx={{ px: 2 }}>
              <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: {
                xs: 'column',
                sm: 'row'
            }
        }}>
                <Box sx={{
            paddingBottom: 2
        }}>
                  <Typography variant='h2' component='header'>
                    {flow.name}
                  </Typography>
                  <Typography variant='caption' component='p' sx={{
            paddingBottom: 2
        }}>
                    {'by '}
                    {flow?.org?.name ??
            `${flow.owner.firstName} ${flow.owner.lastName}`}
                  </Typography>
                </Box>
                <Box>
                  {answers && !flow.settings?.toRequirePassword ? (<Button size='small' variant='outlined' aria-label='print' onClick={handleClickPrint} startIcon={<PictureAsPdfOutlined />}>
                      PDF
                    </Button>) : null}
                </Box>
              </Box>
              <Box>
                <Typography variant='h5' component='p' sx={{
            paddingBottom: 2
        }} dangerouslySetInnerHTML={{
            __html: formatTextarea(flow?.description)
        }}/>
              </Box>
            </Grid>
          </Grid>
          {flow?.sections?.length > 0 &&
            flow.settings?.toRequirePassword &&
            !passwordVerificationSuccess && (<PasswordVerification flowSlug={flow.slug} setPasswordVerificationSuccess={setPasswordVerificationSuccess}/>)}
          {passwordVerificationSuccess && (<>
              {flow?.sections?.length > 0 && answers ? (<>
                  <FlowOverview endingMessage={flow.ending || null} sections={flow?.sections} answers={answers} statuses={statuses}/>
                </>) : (<Placeholder size={1}/>)}
            </>)}
          <PoweredBy />
        </Box>
      </Box>

      {flow.slug === 'ardent-roadmap' && (<ArdentMentoringPrint flow={flow} answers={answers} setShowPrint={setShowPrint} showPrint={showPrint}/>)}
    </>);
};
