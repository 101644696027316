import altflowAwsApi from './altflow-aws-api';
export const deleteSection = async (flowSlug, sectionId) => {
    try {
        await altflowAwsApi.delete(`/flows/${flowSlug}/sections/${sectionId}`);
        return true;
    }
    catch (error) {
        throw error;
    }
};
export const updateSection = async (flowSlug, sectionId, sectionData) => {
    try {
        const { data } = await altflowAwsApi.put(`/flows/${flowSlug}/sections/${sectionId}`, sectionData);
        return data;
    }
    catch (error) {
        throw error;
    }
};
