import { sanitize } from './sanitize';
export const formatTextarea = (data, toSanitize = true) => {
    try {
        return sanitize(data
            ?.split('\n')
            ?.map((_) => (toSanitize ? sanitize(_) : _))
            ?.join('<br/>')
            ?.toString());
    }
    catch (error) {
        return sanitize(data);
    }
};
/**
 * convert text (string) to number
 * @param value
 * @returns
 */
export const textToNumber = (value) => {
    try {
        return Number(value);
    }
    catch (error) {
        console.error(`textToNumber - failed to convert value: ${value} to number`, error);
        return 0;
    }
};
