import { ClearAllOutlined, InfoOutlined } from '@mui/icons-material';
import { Box, Button, Divider, FormHelperText, InputLabel, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
export const Signature = ({ altField, label }) => {
    const [sigCanvas, setSigCanvas] = useState(null);
    const handleClearSignature = () => {
        sigCanvas?.clear();
        if (altField?.setValue)
            altField?.setValue(altField.key, null);
    };
    useEffect(() => {
        sigCanvas?.fromDataURL(altField.defaultValue);
    }, [sigCanvas]);
    return (<>
      <InputLabel id={`${altField.key}-label`} sx={{ display: 'flex', alignItems: 'center' }}>
        {label}
        <Tooltip title={'This is a signature pad, start drawing within the box'} placement='right' sx={{
            ml: '4px'
        }}>
          <InfoOutlined fontSize='inherit'/>
        </Tooltip>
        <br />
      </InputLabel>
      <FormHelperText>{altField.sub || ''}</FormHelperText>
      <Box id='signature-box' sx={{
            // width: '100%',
            textAlign: 'center'
        }} style={{
            border: '1px dotted black',
            borderRadius: '4px',
            padding: '4px'
        }}>
        <SignatureCanvas ref={(ref) => {
            setSigCanvas(ref);
        }} onEnd={() => {
            if (sigCanvas?.isEmpty())
                throw Error('Signature not signed');
            try {
                const base64Signature = sigCanvas?.getTrimmedCanvas().toDataURL();
                if (altField?.setValue)
                    altField?.setValue(altField.key, base64Signature);
            }
            catch (sigCanvasError) {
                console.log('signature-canvas error', sigCanvasError);
            }
        }} clearOnResize={false} penColor='black' canvasProps={{
            height: 450,
            className: 'altflow-signature-canvas',
            style: {
                width: '100%'
            }
        }}/>
        <Divider />
        <Button size='small' variant='outlined' aria-label='clear signature canvas' fullWidth onClick={handleClearSignature} startIcon={<ClearAllOutlined />} sx={{
            mt: '4px'
        }}>
          Clear
        </Button>
      </Box>
    </>);
};
