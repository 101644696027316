import { Alert, AlertTitle, Box, LinearProgress, Typography } from '@mui/material';
import { formatTextarea } from 'forms/format';
import React, { useEffect, useMemo, useState } from 'react';
import { SectionOverview } from './SectionOverview';
export const FlowOverview = ({ endingMessage, sections, answers, isPreview, statuses } = {
    endingMessage: null,
    sections: [],
    answers: null,
    statuses: null,
    isPreview: false
}) => {
    const isIncomplete = useMemo(() => {
        return sections.some((s) => statuses?.[s.id] !== 'COMPLETED');
    }, [statuses]);
    const percentage = useMemo(() => {
        const totalCompleted = Object.values(statuses || {})
            ?.map((status) => status === 'COMPLETED')
            ?.filter(Boolean).length;
        return (totalCompleted / sections?.length) * 100 || 0;
    }, [statuses]);
    const [isFlowIncomplete, setIsFlowIncomplete] = useState(isIncomplete || true);
    const [completionPercentage, setCompletionPercentage] = useState(percentage);
    useEffect(() => {
        setIsFlowIncomplete(isIncomplete);
        setCompletionPercentage(percentage);
    }, [statuses]);
    const [disableActionAtIndex] = useState(sections.findIndex(({ id }) => (statuses?.[id] ?? 'CREATED') !== 'COMPLETED') || 0);
    return (<Box sx={{ minWidth: '100%' }} id='flow-overview-main'>
      <LinearProgressWithLabel variant='determinate' value={completionPercentage}/>

      {!isFlowIncomplete && (<Alert severity='success'>
          <AlertTitle>Woohoo! You've completed this flow!</AlertTitle>
          <p dangerouslySetInnerHTML={{
                __html: formatTextarea(endingMessage ?? '')
            }}/>
        </Alert>)}
      {sections?.map((section, index) => {
            return (<Box sx={{ paddingTop: 2, paddingBottom: 2 }} key={section.slug} className='flow-overview'>
            {answers ? (<SectionOverview section={section} answers={answers?.[section?.id] || {}} status={statuses?.[section?.id] || null} isActionButtonDisabled={isPreview ||
                        section?.fields?.length < 1 ||
                        index > disableActionAtIndex}/>) : null}
          </Box>);
        })}
    </Box>);
};
export function LinearProgressWithLabel(props) {
    return (<Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant='determinate' {...props}/>
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant='body2' color='text.secondary'>{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>);
}
