/**
 * Using conditions to make the field show/hide/disabled either reference to a parent field or sibling field(s)
 * This is not the final solution as this is a conditions array with .find... if you have 2 AndConditions
 * @param field
 * @param condition
 * @param getValues
 * @returns
 */
export const findConditionsV2 = (field, condition, getValues) => {
    const conditions = Array.isArray(condition) ? condition : [condition];
    const parentValue = (getValues && getValues(`${field?.parent}`)) || undefined;
    // console.log('conditions', conditionList, '| parentValue', parentValue)
    const checkCondition = (_condition) => {
        if (!_condition?.value?.and)
            return _condition?.value === parentValue;
        // and condition(s)
        if (_condition?.value?.and)
            return true;
    };
    const conditionFound = conditions?.find(checkCondition);
    if (!conditionFound)
        return { hide: true };
    if (!conditionFound?.value?.and) {
        return findCondition(conditionFound, parentValue);
    }
    return checkAndConditions(field, conditionFound, getValues);
};
const checkAndConditions = (field, parentCondition, getValues) => {
    // this is condition that ALL must match
    const and = parentCondition.value.and;
    const isMatch = and.every((condition) => {
        // means reference the parent key
        if (!condition?.key) {
            const parentValue = (getValues && getValues(`${field.parent}`)) || undefined;
            const parentConditionMatch = findCondition({
                type: parentCondition.type,
                value: condition.value
            }, parentValue);
            return parentConditionMatch.hide === false;
        }
        const keyValue = (getValues && getValues(`${field._metadata.key}.${condition.key}`)) ||
            undefined;
        const conditionMatch = findCondition({
            type: parentCondition.type,
            value: condition.value
        }, keyValue);
        return conditionMatch.hide === false;
    });
    return isMatch === false
        ? {
            hide: true
        }
        : {
            [parentCondition.type]: true,
            props: { ...parentCondition.props }
        };
};
// this needs to be refactor or simplified
const findCondition = (condition, value) => {
    if (condition === null) {
        return {
            hide: true
        };
    }
    const matchedCondition = `${condition.value}` === `${value}`;
    if (!matchedCondition && condition.type === 'hide') {
        return {
            hide: false
        };
    }
    if (!matchedCondition && condition.type === 'show') {
        return {
            hide: true
        };
    }
    switch (condition.type) {
        case 'disabled':
            return {
                [condition.type]: matchedCondition,
                props: { ...condition.props }
            };
        case 'show':
            return {
                hide: !matchedCondition,
                props: { ...condition.props }
            };
        default:
            // you can show and disable that field thanks to props
            return {
                [condition.type]: true,
                props: { ...condition.props }
            };
    }
};
