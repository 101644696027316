import { Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel } from '@mui/material';
import React, { useState } from 'react';
/**
 * Use this for multiple selection for checkbox
 * https://react-hook-form.com/api/usecontroller#example
 */
export const Checkboxes = ({ altField, controlledField, condition, label }) => {
    const [value, setValue] = useState(controlledField.value || []);
    return (<>
      <FormControl component='fieldset' variant='outlined' 
    // {...controlledField} // this is not used as we are saving down the string[] of what the user selected.. controlledField value for checkbox is only boolean
    {...condition}>
        <FormLabel component='legend'>{label}</FormLabel>
        {altField?.choices?.map((choice, index) => (<FormControlLabel key={`${choice?.value}-${index}`} value={choice?.value} control={<Checkbox key={choice?.value} name={choice?.display} checked={value.includes(choice?.value)} onChange={(e) => {
                    // update checkbox value
                    const currentValue = e.target.value;
                    const newValues = e.target.checked
                        ? [...value, currentValue]
                        : value.filter((v) => v !== currentValue);
                    // send data to react hook form
                    // if there are no selection, we need to make it undefined so rhf will manage the error/required state
                    controlledField.onChange(newValues.length > 0 ? newValues : undefined);
                    // update local state
                    setValue(newValues);
                }} value={choice?.value}/>} label={choice?.display}/>))}
        <FormHelperText>{altField.sub}</FormHelperText>
      </FormControl>
    </>);
};
