import { ArtTrack, Tag, TimesOneMobiledata } from '@mui/icons-material';
import { FlowEditContext } from 'contexts/FlowContext';
import React, { useContext } from 'react';
import { paramCase } from 'shared';
export const SECTION_SETTINGS = [
    {
        id: '63536644f662ae82d97a160f',
        key: 'id',
        component: 'input',
        label: 'ID',
        tip: null,
        type: null,
        choices: null,
        info: null,
        watch: null,
        required: true,
        autocomplete: false,
        multiple: false,
        ordinal: 0,
        isPrivate: false,
        isHidden: true,
        hasChildren: false,
        sectionId: '63536643f662ae82d97a1678',
        createdAt: '2022-10-22T03:40:52.898Z',
        updatedAt: '2022-10-22T03:40:52.898Z',
        parent: null,
        props: {
            disabled: true,
            sx: {
                display: 'none'
            }
        }
    },
    {
        id: '63536644f662ae82d97a160f',
        key: 'slug',
        component: 'input',
        label: 'Slug',
        sub: 'This is a slug to your flow path',
        example: (data) => {
            return <SlugExample data={data}/>;
        },
        icon: <Tag />,
        type: 'text',
        choices: null,
        required: true,
        autocomplete: false,
        multiple: false,
        ordinal: 0,
        isPrivate: false,
        isHidden: false,
        hasChildren: false,
        props: {
            disabled: false
        },
        transform: (value) => {
            return paramCase(value);
        }
    },
    {
        id: '63536643f662ae82d97a167b',
        key: 'settings.showOneFieldAtATime',
        component: 'toggle',
        label: 'Show 1 question at a time',
        sub: `Enduser's experience for this section`,
        icon: <TimesOneMobiledata />,
        type: 'text',
        defaultValue: false,
        choices: null,
        info: null,
        watch: null,
        autocomplete: false,
        multiple: false,
        ordinal: 1,
        isPrivate: false,
        isHidden: false,
        hasChildren: false,
        createdAt: '2022-10-22T03:40:51.592Z',
        updatedAt: '2022-10-22T03:40:51.595Z',
        parent: null
    },
    {
        id: '63536643f662ae82d97a167b',
        key: 'settings.displayOverview',
        component: 'toggle',
        label: 'Show the section overview of questions',
        sub: '',
        icon: <ArtTrack />,
        tip: '',
        type: 'text',
        defaultValue: false,
        choices: null,
        autocomplete: false,
        multiple: false,
        ordinal: 1,
        isPrivate: false,
        isHidden: false,
        hasChildren: false,
        parent: null
    }
];
const SlugExample = ({ data }) => {
    const { slug } = useContext(FlowEditContext);
    return (<>
      eg: /flow/{slug}/{data ?? ''}
    </>);
};
