import { useUser } from '@clerk/clerk-react';
import SailingIcon from '@mui/icons-material/Sailing';
import { Avatar, Box, Card, CardContent, CardHeader, Grid, Link, Typography } from '@mui/material';
import { Placeholder } from 'components/Placeholder';
import React, { useEffect, useState } from 'react';
import { getMySubmissions } from 'services/user-service';
export const MySubmissions = () => {
    const { user } = useUser();
    const [submissions, setSubmissions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const fetchUserSubmissions = async () => {
            try {
                const data = await getMySubmissions();
                setSubmissions(data);
            }
            catch (error) {
                // do something
            }
            finally {
                setIsLoading(false);
            }
        };
        fetchUserSubmissions();
    }, [user?.id]);
    return (<Box sx={{
            minWidth: 275,
            padding: 0
        }}>
      {isLoading ? (<Placeholder size={2}/>) : (<>
          <Grid container spacing={3} paddingBottom={1} key='my-submissions'>
            {submissions.length > 0 &&
                submissions?.map((submission) => {
                    return (<Grid item sm={6} md={4} key={submission.name} sx={{
                            display: 'flex',
                            width: '100%'
                        }}>
                    <Card variant='outlined' key={submission.name} sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            overflowWrap: 'anywhere'
                        }}>
                      <CardHeader avatar={<Avatar aria-label='flow-avatar' sx={{
                                backgroundColor: 'rgb(25, 118, 210)'
                            }}>
                            <SailingIcon />
                          </Avatar>} title={<Link href={`/flow/${submission.name}`} underline='none'>
                            {submission.name}
                          </Link>} titleTypographyProps={{ variant: 'h6' }}/>
                      <CardContent sx={{
                            padding: 2,
                            maxHeight: '175px',
                            minHeight: '175px',
                            backgroundColor: 'white',
                            overflowY: 'auto'
                        }}>
                        <ul key='section-list' style={{
                            paddingInlineStart: '15px',
                            marginTop: 0,
                            maxHeight: '175px',
                            minHeight: '175px'
                        }}>
                          {submission.data?.map(({ fieldKey, data, section }, index) => {
                            return (<li key={`${section?.slug}.${fieldKey}`}>
                                  <Typography key={index} color='text.primary' variant='body2' component='p'>
                                    {`${section?.slug}.${fieldKey}:  ${data}`}
                                  </Typography>
                                </li>);
                        })}
                        </ul>
                      </CardContent>
                    </Card>
                  </Grid>);
                })}
            {!submissions.length && (<>
                <Grid item sm={12} sx={{
                    display: 'grid',
                    width: '100%'
                }} key={'no submission'}>
                  <Typography color='text.secondary'>No Submissions</Typography>
                </Grid>
              </>)}
          </Grid>
        </>)}
    </Box>);
};
