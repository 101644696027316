import { Box, Divider, Typography } from '@mui/material';
import { Heading } from 'components/Heading';
import { formatTextarea } from 'forms/format';
import React, { memo } from 'react';
import { filterFieldProperties } from '../filter-field-properties';
import { renderFieldDataByComponent } from './FlowBoardView';
export const TaskDetailsCard = memo(({ task, meta }) => {
    // const sections: Array<{ slug: string; label: string; ordinal: number }> =
    //   Object.values(meta?.sections)
    const sections = { ...meta?.sections };
    const entries = Object.entries(task);
    entries
        ?.filter(([key, _value]) => filterFieldProperties(key))
        .forEach(([key, value]) => {
        const component = meta?.fields?.[key]?.component;
        const sectionKey = key?.split('.')[0];
        sections[sectionKey] = {
            ...sections[sectionKey],
            fields: [
                ...(sections[sectionKey]?.fields || []),
                {
                    key,
                    component,
                    answer: value
                }
            ]
        };
    });
    return (<>
      {Object.values(sections).map((section) => {
            return (<Box key={section.slug} sx={{ pb: 1 }}>
            <Heading>
              <Typography variant='h2' component='header'>
                {section?.label}
              </Typography>
            </Heading>
            {section?.fields?.map(({ key, component, answer }, index) => {
                    return (<Box key={index} sx={{ pb: 1 }}>
                  <Typography variant='h6' component='header'>
                    {meta?.fields?.[key]?.label}
                  </Typography>
                  {component !== 'signature' ? (<Typography dangerouslySetInnerHTML={{
                                __html: formatTextarea(renderFieldDataByComponent(component, answer || ' '))
                            }}/>) : answer ? (<img src={formatTextarea(answer)} style={{ maxWidth: '100%', maxHeight: '75px' }}/>) : ('')}
                </Box>);
                })}
            <Divider />
          </Box>);
        })}
    </>);
});
