import { debounce } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
export const DebounceField = ({ callBackApi, defaultValue, context, Component, componentProps }) => {
    const [inputValue, setInputValue] = useState(defaultValue);
    const callApi = useCallback(debounce((value) => {
        callBackApi(value, context);
    }, 2000), [context]);
    useEffect(() => {
        if (inputValue !== defaultValue)
            callApi(inputValue);
    }, [inputValue]);
    return (<Component type='text' {...componentProps} defaultValue={inputValue} onChange={(e) => setInputValue(e.target.value)}/>);
};
