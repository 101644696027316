import { Checkbox, FormControl, FormControlLabel, FormLabel, ImageListItem, ImageListItemBar, Radio, RadioGroup } from '@mui/material';
import React, { useState } from 'react';
const STYLE = {
    display: 'unset',
    border: '1px solid black',
    borderRadius: '18px',
    ml: '0',
    my: 1,
    p: 2,
    textAlign: 'center'
};
/**
 * Use this for multiple selection for checkbox
 * https://react-hook-form.com/api/usecontroller#example
 */
export const PictureSelection = ({ altField, controlledField, condition, label }) => {
    const [value, setValue] = useState(controlledField.value);
    return (<>
      {altField.multiple ? (<FormControl component='div' variant='outlined' 
        // {...controlledField} // this is not used as we are saving down the string[] of what the user selected.. controlledField value for checkbox is only boolean
        {...condition}>
          <FormLabel component='legend'>{label}</FormLabel>
          {altField?.choices?.map((choice, index) => (<FormControlLabel key={`${choice?.value}-${index}`} value={choice?.value} disableTypography={true} sx={STYLE} control={<ImageListItem key={`${choice?.value}-${index}`} sx={{
                        display: 'flex'
                        // flexDirection: 'column'
                    }}>
                  <Checkbox key={choice?.value} name={choice?.display} checked={value.includes(choice?.value)} onChange={(e) => {
                        // update checkbox value
                        const currentValue = e.target.value;
                        const newValues = e.target.checked
                            ? [...value, currentValue]
                            : value.filter((v) => v !== currentValue);
                        // send data to react hook form
                        // if there are no selection, we need to make it undefined so rhf will manage the error/required state
                        controlledField.onChange(newValues);
                        // update local state
                        setValue(newValues);
                    }} value={choice?.value}/>
                  <img src={`${choice?.image}?w=248&fit=crop&auto=format`} srcSet={`${choice?.image}?w=248&fit=crop&auto=format&dpr=2 2x`} alt={choice?.display} loading='lazy' style={{
                        maxHeight: '200px'
                    }}/>
                  <ImageListItemBar 
                // title={choice.display}
                // subtitle={<span>by: {choice.author}</span>}
                position='below' sx={{
                        textAlign: 'center'
                    }}/>
                </ImageListItem>} label={choice.display}/>))}
        </FormControl>) : (<FormControl component='div' variant='outlined' {...controlledField} // this is used for radio (multiple = false)
         {...condition?.props}>
          <FormLabel component='legend'>{label}</FormLabel>
          <RadioGroup value={controlledField.value}>
            {altField?.choices?.map((choice, index) => (<FormControlLabel key={`${choice?.value}-${index}`} value={choice?.value} disableTypography={true} sx={STYLE} control={<ImageListItem key={`${choice?.value}-${index}`} sx={{
                        display: 'flex'
                        // flexDirection: 'column'
                    }}>
                    <Radio value={choice?.value}/>
                    <img src={`${choice?.image}?w=248&fit=crop&auto=format`} srcSet={`${choice?.image}?w=248&fit=crop&auto=format&dpr=2 2x`} alt={choice?.display} loading='lazy' style={{
                        maxHeight: '200px'
                    }}/>
                    <ImageListItemBar 
                // title={choice.display}
                // subtitle={<span>by: {choice.author}</span>}
                position='below' sx={{
                        textAlign: 'center'
                    }}/>
                  </ImageListItem>} label={choice.display}/>))}
          </RadioGroup>
        </FormControl>)}
    </>);
};
