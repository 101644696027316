/**
 *
 * @param id element-id
 * @param waitFor seconds @default 0
 */
export const handleScrollTo = (id, waitFor = 0) => {
    const scroll = () => {
        const element = document.getElementById(id);
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            window.scroll({ top: element.offsetTop - 120, behavior: 'smooth' });
            // element.scrollIntoView({ behavior: 'smooth', inline: 'start' })
        }
    };
    setTimeout(scroll, waitFor);
};
