import { RedirectToSignIn, SignedIn, SignedOut, SignUp, useAuth, useOrganization, useUser } from '@clerk/clerk-react';
import { Box, Typography } from '@mui/material';
import { Heading } from 'components/Heading';
import Nav from 'components/NavBar';
import { Placeholder } from 'components/Placeholder';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import { useFlagsmith } from 'flagsmith/react';
import LogRocket from 'logrocket';
import AdminHome from 'pages/admin/AdminHome';
import { FlowDash } from 'pages/admin/flow/dashboard/FlowDash';
import { FlowRoutes } from 'pages/public/FlowRoutes';
import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import { altflowApiConfig } from 'services/altflow-api';
import { altflowAwsApiConfig } from 'services/altflow-aws-api';
import { updateOrg } from 'services/org-service';
import { adminFieldsOverrides } from 'services/overrides/admin-fields-override';
import { updateUser } from 'services/user-service';
const REACT_APP_LOG_ROCKET_ID = process.env.REACT_APP_LOG_ROCKET_ID || '';
const App = () => {
    const location = useLocation();
    const flagsmith = useFlagsmith();
    const [isLoading, setIsLoading] = useState(true);
    const auth = useAuth();
    const authUser = useUser();
    const [searchParams, setSearchParams] = useSearchParams();
    const { organization } = useOrganization();
    if (REACT_APP_LOG_ROCKET_ID?.length)
        LogRocket.init(REACT_APP_LOG_ROCKET_ID);
    useEffect(() => {
        const setAuthToken = async () => {
            await altflowAwsApiConfig(auth);
            await altflowApiConfig(auth);
            const identify = async () => {
                // This will re-render the component if the user has the trait example_trait or they have a different feature value for font_size
                await flagsmith.identify(authUser.user?.primaryEmailAddress?.emailAddress);
            };
            if (authUser?.user)
                identify();
            if (REACT_APP_LOG_ROCKET_ID?.length && authUser?.user) {
                LogRocket.identify(authUser.user.id, {
                    name: authUser.user.fullName || 'Alt Flow',
                    email: authUser.user.primaryEmailAddress?.emailAddress || ''
                    // Add your own custom user variables here, ie:
                    // subscriptionType: 'pro'
                });
            }
            const fetchUserData = async () => {
                if (!authUser?.user?.id)
                    return;
                try {
                    const minsDiff = differenceInMinutes(new Date(), authUser.user.createdAt);
                    if (minsDiff < 5) {
                        await updateUser(authUser.user.id, {
                            lastName: authUser?.user?.lastName || '',
                            firstName: authUser?.user?.firstName || '',
                            email: authUser?.user?.primaryEmailAddress?.emailAddress || ''
                        });
                    }
                }
                catch (error) {
                    console.log('error fetchUserData', error);
                }
            };
            await fetchUserData();
            setIsLoading(false);
        };
        setIsLoading(true);
        setAuthToken();
    }, [authUser?.user?.id]);
    useEffect(() => {
        const minsDiff = differenceInMinutes(new Date(), organization?.createdAt);
        const createOrg = async () => {
            await updateOrg(organization?.id, {
                name: organization?.name
            });
        };
        if (minsDiff < 4 &&
            organization?.id &&
            searchParams.get('org') === 'create') {
            createOrg();
            // organization.destroy()
            searchParams.delete('org');
            setSearchParams(searchParams);
        }
    }, [searchParams.get('org'), organization?.name]);
    const ProtectRouteWrap = (child) => {
        if (!authUser.isSignedIn)
            return (<SignedOut>
          <RedirectToSignIn afterSignInUrl={location.pathname} afterSignUpUrl={location.pathname} redirectUrl={location.pathname}/>
        </SignedOut>);
        return <SignedIn>{child}</SignedIn>;
    };
    return (<>
      <Nav />
      <Box sx={{ px: 0 }}>
        <Routes>
          <Route path={'/'} element={<>
                {authUser.isSignedIn === true && location.pathname === '/' ? (<Navigate to='/dashboard' replace={true}/>) : (<Box sx={{
                    textAlign: 'center',
                    pt: 2
                }}>
                    <Heading>
                      <Typography variant='h2' component='header' sx={{ p: 1 }}>
                        Welcome to altflow
                      </Typography>
                      <Typography variant='h5' component='header'>
                        Please sign-in / sign-up to create your flow!
                      </Typography>
                    </Heading>
                    <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    pt: 2
                }}>
                      {/* <SignIn /> */}
                      <SignUp />
                    </Box>
                  </Box>)}
              </>}/>
          <Route path={'/dashboard/*'} element={ProtectRouteWrap(<AdminHome />)}/>
          <Route path={'/dashboard/flow/:slug'} element={ProtectRouteWrap(<FlowDash />)}/>
          <Route path={'/dashboard/flow/:slug/*'} // this is for create new flow
     element={ProtectRouteWrap(<FlowRoutes fieldOverrides={adminFieldsOverrides}/>)}/>
          <Route path={`/flow/:slug/*`} element={<FlowRoutes />}/>
          <Route path={`*`} element={isLoading && (<Box sx={{ padding: 2 }}>
                  <Heading>
                    <Typography variant='h2' component='header' sx={{ pb: 1 }}>
                      Let the river flow!
                    </Typography>
                    <Typography variant='h2' component='header'></Typography>
                  </Heading>
                  <Placeholder size={3}/>
                </Box>)}/>
        </Routes>
      </Box>
    </>);
};
export default App;
