import { useOrganization, useUser } from '@clerk/clerk-react';
import { CreateOutlined, Delete, Edit, InsightsOutlined, MoreVertOutlined, Sailing, Share } from '@mui/icons-material';
import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Menu, MenuItem, Snackbar, Typography } from '@mui/material';
import { Heading } from 'components/Heading';
import { Placeholder } from 'components/Placeholder';
import { useFlags } from 'flagsmith/react';
import { CREATE_NEW_FLOW_SLUG } from 'forms/alt-field';
import { formatTextarea } from 'forms/format';
import React, { Suspense, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteFlow } from 'services/flow-admin-service';
import { formatDate } from 'services/time-format';
import { getUser } from 'services/user-service';
import { getOrgFlows } from '../../services/org-service';
import { MySubmissions } from './MySubmissions';
const FlowsTemplate = React.lazy(() => import('pages/admin/FlowsTemplate'));
const AdminHome = () => {
    const navigate = useNavigate();
    const { user } = useUser();
    const { organization } = useOrganization();
    const [flows, setFlows] = useState([]);
    const [orgFlows, setOrgFlows] = useState([]);
    const [shareLinkSuccessful, setShareLinkSuccessful] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentFlow, setCurrentFlow] = useState(null);
    const flags = useFlags(['admin_show_my_submission']);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingOrgFlows, setIsLoadingOrgFlows] = useState(true);
    const [openPrompt, setOpenPrompt] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const data = await getUser(user.id);
                setFlows(data.flows);
            }
            catch (error) {
                // do something
            }
            finally {
                setIsLoading(false);
            }
        };
        setIsLoading(true);
        fetchUserData();
    }, [user?.primaryEmailAddress?.emailAddress, organization?.id]);
    useEffect(() => {
        const fetchOrgFlows = async () => {
            setOrgFlows([]);
            try {
                const data = await getOrgFlows(organization?.id);
                setOrgFlows(data.flows);
            }
            catch (error) {
                // do something
            }
            finally {
                setIsLoadingOrgFlows(false);
            }
        };
        setIsLoadingOrgFlows(true);
        if (organization?.id) {
            fetchOrgFlows();
        }
    }, [user?.primaryEmailAddress?.emailAddress, organization?.id]);
    const handleDeleteFlow = async () => {
        if (currentFlow?.slug) {
            setIsDeleting(true);
            try {
                await deleteFlow(currentFlow?.slug);
                setFlows((prevFlows) => prevFlows.filter((flow) => flow.slug !== currentFlow.slug));
            }
            catch (error) {
                alert(`Failed to delete ${currentFlow?.slug}. Please try again!`);
            }
            finally {
                setAnchorEl(null);
                setOpenPrompt(false);
                setIsDeleting(false);
            }
        }
    };
    return (<>
      {user ? (<>
          <Box sx={{
                minWidth: 275,
                padding: 2
            }}>
            <Heading>
              <Typography variant='h2' component='header'>
                Hello {user.firstName}! Here are your flows
                {organization?.name ? ` with ${organization?.name}` : null}
              </Typography>
              <Typography variant='h2' component='header'></Typography>
            </Heading>
            {isLoading ? (<Placeholder size={2}/>) : (<Grid container spacing={3} paddingBottom={1} key='flows'>
                {flows?.map((flow) => {
                    return (<Grid item sm={6} md={4} key={flow.slug} sx={{
                            display: 'flex',
                            width: '100%'
                        }}>
                      <Card variant='outlined' sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                        }}>
                        <CardHeader avatar={<Avatar aria-label='flow-avatar' sx={{
                                backgroundColor: 'rgb(25, 118, 210)'
                            }}>
                              <Sailing />
                            </Avatar>} action={<IconButton aria-label='settings' onClick={(event) => {
                                setAnchorEl(event?.currentTarget);
                                setCurrentFlow(flow);
                            }}>
                              <MoreVertOutlined />
                            </IconButton>} title={flow.name} titleTypographyProps={{ variant: 'h6' }} subheader={`by ${flow?.owner.firstName} ${flow?.owner.lastName} ${formatDate(flow.createdAt)}`}/>
                        <Menu id='admin-options' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                          <MenuItem>
                            <Button size='small' 
                    // variant='outlined'
                    aria-label='edit' onClick={() => {
                            if (currentFlow?.slug) {
                                navigate({
                                    pathname: `/flow/${currentFlow.slug}`,
                                    search: '?mode=edit'
                                });
                            }
                        }} startIcon={<Edit />}>
                              Edit
                            </Button>
                          </MenuItem>
                        </Menu>
                        <CardContent sx={{
                            padding: 2,
                            maxHeight: '250px',
                            minHeight: '250px',
                            overflowX: 'scroll'
                        }}>
                          <Typography color='text.secondary' dangerouslySetInnerHTML={{
                            __html: formatTextarea(flow?.description || 'No Description')
                        }}/>
                        </CardContent>
                        <CardActions sx={{
                            justifyContent: 'space-around'
                        }}>
                          <Button size='small' variant='contained' aria-label='view results' onClick={() => navigate(`/dashboard/flow/${flow.slug}`)} startIcon={<InsightsOutlined />}>
                            Results
                          </Button>
                          <Button size='small' variant='outlined' aria-label='edit' onClick={() => {
                            navigator.clipboard.writeText(`${process.env.REACT_APP_ALTFLOW_URL}flow/${flow.slug}`);
                            setShareLinkSuccessful(true);
                        }} sx={{
                            backgroundColor: '#1976d21f',
                            border: 'none',
                            fontSize: '16px'
                        }} startIcon={<Share />}>
                            Share
                          </Button>
                          <Button size='small' variant='outlined' aria-label='delete' sx={{
                            backgroundColor: '#1976d21f',
                            border: 'none',
                            fontSize: '16px'
                        }} onClick={() => {
                            setCurrentFlow(flow);
                            setOpenPrompt(true);
                        }} startIcon={<Delete />}>
                            Delete
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>);
                })}

                <Grid item sm={6} md={4} key={'create-flow'} sx={{
                    display: 'flex',
                    width: '100%'
                }}>
                  <Card variant='outlined' style={{
                    cursor: 'pointer',
                    backgroundColor: '#1976d2',
                    color: 'white',
                    minWidth: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }} onClick={() => navigate(`/dashboard/flow/${CREATE_NEW_FLOW_SLUG}/new`)}>
                    <CardContent sx={{
                    // display: 'flex',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    textAlign: 'center'
                }}>
                      <Box sx={{}}>
                        <CreateOutlined />
                      </Box>
                      <Typography color='white' variant='h4' component='h4'>
                        Create new flow
                        <br />
                        {organization?.name ? ` for ${organization.name}` : ''}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>)}
          </Box>
        </>) : null}

      {organization?.id ? (<>
          <Box sx={{
                minWidth: 275,
                padding: 2
            }}>
            <Heading>
              <Typography variant='h2' component='header'>
                Here are other flows with {organization?.name}
              </Typography>
              <Typography variant='h2' component='header'></Typography>
            </Heading>
            {isLoadingOrgFlows ? (<Placeholder size={2}/>) : (<Grid container spacing={3} paddingBottom={1} key='flows'>
                {orgFlows?.map((flow) => {
                    return (<Grid item sm={6} md={4} key={flow.slug} sx={{
                            display: 'flex',
                            width: '100%'
                        }}>
                      <Card variant='outlined' sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                        }}>
                        <CardHeader avatar={<Avatar aria-label='flow-avatar' sx={{
                                backgroundColor: 'rgb(25, 118, 210)'
                            }}>
                              <Sailing />
                            </Avatar>} action={<IconButton aria-label='settings' onClick={(event) => {
                                setAnchorEl(event?.currentTarget);
                                setCurrentFlow(flow);
                            }}>
                              <MoreVertOutlined />
                            </IconButton>} title={flow.name} titleTypographyProps={{ variant: 'h6' }} subheader={`by ${flow?.owner.firstName} ${flow?.owner.lastName} ${formatDate(flow.createdAt)}`}/>
                        <Menu id='admin-options' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                          <MenuItem>
                            <Button size='small' 
                    // variant='outlined'
                    aria-label='edit' onClick={() => {
                            if (currentFlow?.slug) {
                                navigate({
                                    pathname: `/flow/${currentFlow.slug}`,
                                    search: '?mode=edit'
                                });
                            }
                        }} startIcon={<Edit />}>
                              Edit
                            </Button>
                          </MenuItem>
                        </Menu>
                        <CardContent sx={{
                            padding: 2,
                            minHeight: '150px',
                            maxHeight: '250px',
                            overflowX: 'scroll'
                        }}>
                          <Typography color='text.secondary' dangerouslySetInnerHTML={{
                            __html: formatTextarea(flow?.description || 'No Description')
                        }}/>
                        </CardContent>
                        <CardActions sx={{
                            justifyContent: 'space-around'
                        }}>
                          <Button size='small' variant='contained' aria-label='view results' onClick={() => navigate(`/dashboard/flow/${flow.slug}`)} startIcon={<InsightsOutlined />}>
                            Results
                          </Button>
                          <Button size='small' variant='outlined' aria-label='edit' onClick={() => {
                            navigator.clipboard.writeText(`${process.env.REACT_APP_ALTFLOW_URL}flow/${flow.slug}`);
                            setShareLinkSuccessful(true);
                        }} sx={{
                            backgroundColor: '#1976d21f',
                            border: 'none',
                            fontSize: '16px'
                        }} startIcon={<Share />}>
                            Share
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>);
                })}
                {orgFlows?.length < 1 && (<Grid item sm={12} sx={{
                        display: 'grid',
                        width: '100%'
                    }} key={'no organiztion flows'}>
                    <Typography color='text.secondary'>
                      {organization?.name} does not have any flows from any
                      other organization members
                    </Typography>
                  </Grid>)}
              </Grid>)}
          </Box>
        </>) : null}

      <Dialog open={openPrompt} disableEscapeKeyDown={true} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>Delete Flow</DialogTitle>
        <DialogContent>
          {isDeleting ? (<Box sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
              <CircularProgress />
              <DialogContentText id='alert-dialog-description' sx={{ pt: 2 }}>
                Deleting {currentFlow?.slug}...
              </DialogContentText>
            </Box>) : (<DialogContentText id='alert-dialog-description'>
              Are you sure you want to delete {currentFlow?.slug}?
            </DialogContentText>)}
        </DialogContent>
        {!isDeleting && (<DialogActions>
            <Button onClick={() => setOpenPrompt(false)}>Cancel</Button>
            <Button onClick={handleDeleteFlow} autoFocus>
              Yes
            </Button>
          </DialogActions>)}
      </Dialog>

      <Snackbar open={shareLinkSuccessful} autoHideDuration={1000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} onClose={() => setShareLinkSuccessful(false)} message='Share URL copied!'/>

      {flags?.admin_show_my_submission?.enabled && (<Box sx={{
                minWidth: 275,
                padding: 2
            }}>
          <Typography variant='h2' component='header' sx={{ paddingBottom: 4 }}>
            Your submissions
          </Typography>
          <MySubmissions key='my-submissions'/>
        </Box>)}
      <Box sx={{
            minWidth: 275,
            padding: 2
        }}>
        <Typography variant='h2' component='header' sx={{
            paddingBottom: 2
        }}>
          Need inspo to create a flow?
        </Typography>
        <Typography variant='h4' component='h4' sx={{
            paddingBottom: 4
        }}>
          Select a template or example
        </Typography>
        <Suspense fallback={<Placeholder size={3}/>}>
          <FlowsTemplate />
        </Suspense>
      </Box>
    </>);
};
export default AdminHome;
