import { FlowOverviewEdit } from 'pages/admin/flow/FlowOverviewEdit';
import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FlowOverviewRouterPublic } from './FlowOverviewRouterPublic';
export const FlowOverviewRouterV2 = ({ flow, answers, statuses }) => {
    const [searchParams] = useSearchParams();
    const isEditMode = useMemo(() => (searchParams?.get('mode') && searchParams?.get('mode') === 'edit') ||
        false, [searchParams]);
    return isEditMode ? (<FlowOverviewEdit flow={flow} sections={flow?.sections}/>) : (<FlowOverviewRouterPublic flow={flow} answers={answers} statuses={statuses}/>);
};
