import { grey } from '@mui/material/colors';
import createTheme from '@mui/material/styles/createTheme';
export const fieldHoverStyle = {
    ':hover': {
        borderBottom: '1px dashed #000000'
    },
    '&.Mui-focused': {
        borderBottom: '1px dashed #000000'
    }
};
export const DRAWER_WIDTH = 240;
export const rawTheme = createTheme({
    palette: {
        // primary: {
        //   light: '#fff5f8',
        //   main: '#ff3366',
        //   dark: '#e62958'
        // },
        secondary: {
            light: '#fff5f8',
            main: '#ff3366',
            dark: '#e62958'
        }
    },
    typography: {
        fontFamily: 'Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif',
        fontSize: 16,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 600 // Roboto Condensed
    }
});
const fontHeader = {
    color: rawTheme.palette.text.primary,
    fontWeight: rawTheme.typography.fontWeightMedium
    // fontFamily: "'Roboto Condensed', sans-serif",
    // textTransform: 'uppercase'
};
const components = {
    MuiAppBar: {
        styleOverrides: {
            root: {
                backgroundColor: rawTheme.palette.common.white,
                borderColor: '#f5f5f5',
                border: 'solid thin #f5f5f5',
                boxShadow: 'none'
            }
        }
    },
    MuiCard: {
        styleOverrides: {
            root: {
                borderRadius: '18px'
            }
        }
    },
    MuiCardHeader: {
        styleOverrides: {
            root: {
                // padding: '8px',
                borderBottom: '1px solid rgb(235, 235, 235) !important'
            }
        }
    },
    MuiCardContent: {
        styleOverrides: {
            root: {
                padding: '32px'
                // padding: '20px'
            }
        }
    },
    MuiCardActions: {
        styleOverrides: {
            root: {
                padding: '0px 32px 32px 32px'
            }
        }
    },
    MuiButton: {
        styleOverrides: {
            root: {
                textTransform: 'none',
                boxShadow: 'none',
                minWidth: '60px'
                // borderRadius: '12px'
            }
        }
    },
    MuiIconButton: {
        styleOverrides: {
            root: {
                // color: '#007FFF'
                boxShadow: 'none'
            }
        }
    },
    MuiInputBase: {
        styleOverrides: {
            root: {
                resize: 'both',
                '& .Mui-error': {
                    color: 'red',
                    // border: '1px solid red',
                    border: '1px solid red'
                },
                '.Mui-error': {
                    color: 'red',
                    border: '1px solid red'
                }
            }
        }
    },
    MuiListSubheader: {
        styleOverrides: {
            root: {
                lineHeight: '32px'
            }
        }
    }
};
export const theme = {
    ...rawTheme,
    palette: {
        ...rawTheme.palette,
        background: {
            ...rawTheme.palette.background,
            backgroundColor: rawTheme.palette.common.black,
            default: rawTheme.palette.common.white,
            placeholder: grey[200]
        }
    },
    typography: {
        ...rawTheme.typography,
        fontHeader,
        h1: {
            ...rawTheme.typography.h1,
            ...fontHeader,
            letterSpacing: 0,
            fontSize: 32
        },
        h2: {
            ...rawTheme.typography.h2,
            ...fontHeader,
            fontSize: 26
        },
        h3: {
            ...rawTheme.typography.h3,
            ...fontHeader,
            fontSize: 22
        },
        h4: {
            ...rawTheme.typography.h4,
            ...fontHeader,
            fontSize: 20
        },
        h5: {
            ...rawTheme.typography.h5,
            fontWeight: rawTheme.typography.fontWeightLight,
            fontSize: 18
        },
        h6: {
            ...rawTheme.typography.h6,
            ...fontHeader,
            fontSize: 16
        },
        subtitle1: {
            ...rawTheme.typography.subtitle1,
            fontSize: 14
        },
        body1: {
            ...rawTheme.typography.body1,
            fontWeight: rawTheme.typography.fontWeightRegular,
            fontSize: 14
        },
        body2: {
            ...rawTheme.typography.body2,
            fontSize: 12
        }
    },
    components
};
