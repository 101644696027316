import { Alert, Box, Button, InputLabel, TextField, Typography } from '@mui/material';
import { Heading } from 'components/Heading';
import React, { useState } from 'react';
import { validateFlowPassword } from 'services/flow-service';
export const PasswordVerification = ({ flowSlug, setPasswordVerificationSuccess }) => {
    const [password, setPassword] = useState('');
    const [isError, setIsError] = useState(false);
    return (<Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Heading>
        <Typography variant='h3' component='header' sx={{ pb: 1 }}>
          Password required to go through flow
        </Typography>
        {isError && (<Alert severity='error'>
            This flow's password is wrong! Please try again.
          </Alert>)}
      </Heading>
      <InputLabel id={`password-label`}>Enter password</InputLabel>
      <TextField required={true} type='password' sx={{
            pb: 2
        }} onChange={(e) => setPassword(e.target.value)}/>
      {password.length > 0 && (<Button type='submit' size='small' variant='outlined' aria-label='validate-password' onClick={async () => {
                try {
                    setIsError(false);
                    await validateFlowPassword(flowSlug, password);
                    setPasswordVerificationSuccess(true);
                }
                catch (error) {
                    setIsError(true);
                    setPasswordVerificationSuccess(false);
                }
            }}>
          Continue
        </Button>)}
    </Box>);
};
