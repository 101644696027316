import { Box } from '@mui/material';
import { renderAltFieldIcon } from 'components/altfield/renderAltFieldIcon';
import React from 'react';
import { AltFieldComponents } from 'shared/alt-field';
export const FIELD_EDIT_SETTINGS = [
    // {
    //   id: '63536644f662ae82d97a160f',
    //   key: 'id',
    //   component: 'input',
    //   label: 'ID',
    //   tip: null,
    //   type: null,
    //   choices: null,
    //   info: null,
    //   watch: null,
    //   required: false,
    //   autocomplete: false,
    //   multiple: false,
    //   ordinal: 0,
    //   isPrivate: false,
    //   isHidden: true,
    //   hasChildren: false,
    //   sectionId: '63536643f662ae82d97a1678',
    //   createdAt: '2022-10-22T03:40:52.898Z',
    //   updatedAt: '2022-10-22T03:40:52.898Z',
    //   parent: null,
    //   props: {
    //     disabled: true
    //     // sx: {
    //     // display: 'none'
    //     // }
    //   }
    // },
    {
        id: '63536644f662ae82d97a160f',
        key: 'key',
        component: 'input',
        label: 'Key',
        tip: null,
        type: null,
        choices: null,
        info: null,
        watch: null,
        required: true,
        autocomplete: false,
        multiple: false,
        ordinal: 0,
        isPrivate: false,
        isHidden: false,
        hasChildren: false,
        sectionId: '63536643f662ae82d97a1678',
        createdAt: '2022-10-22T03:40:52.898Z',
        updatedAt: '2022-10-22T03:40:52.898Z',
        parent: null,
        props: {
            disabled: true,
            sx: {
                display: 'none'
            }
        }
    },
    {
        id: '63536643f662ae82d97a167b',
        key: 'required',
        component: 'toggle',
        label: 'Required',
        sub: null,
        defaultValue: false,
        required: false,
        tip: null,
        type: null,
        condition: null,
        choices: null,
        info: null,
        watch: null,
        autocomplete: false,
        multiple: false,
        ordinal: 1,
        isPrivate: false,
        isHidden: false,
        hasChildren: false,
        sectionId: '63536643f662ae82d97a1678',
        createdAt: '2022-10-22T03:40:51.592Z',
        updatedAt: '2022-10-22T03:40:51.595Z',
        parent: null
    },
    {
        id: '63536643f662ae82d97a167c',
        key: 'component',
        component: 'select',
        label: 'Type',
        sub: null,
        tip: null,
        type: null,
        condition: null,
        choices: AltFieldComponents.map((choice) => {
            return {
                value: choice.value,
                display: (<Box key={choice.value} sx={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '4px' }}>
              {renderAltFieldIcon(choice.value)}
            </span>
            <span>{choice?.display}</span>
          </Box>)
            };
        }),
        info: null,
        watch: null,
        required: true,
        defaultValue: 'input',
        autocomplete: false,
        multiple: false,
        ordinal: 2,
        isPrivate: false,
        isHidden: false,
        hasChildren: true,
        sectionId: '63536643f662ae82d97a1678',
        createdAt: '2022-10-22T03:40:51.592Z',
        updatedAt: '2022-10-22T03:40:51.595Z',
        parent: null
    },
    {
        id: '63536644f662ae82d97a167f',
        key: 'choices',
        component: 'matrix',
        label: 'Choices for users to pick',
        sub: 'Type something and then press Enter/Return or Tab',
        tip: null,
        type: null,
        condition: [
            {
                type: 'show',
                value: 'select'
            },
            {
                type: 'show',
                value: 'linear',
                props: {
                    isAdvanced: true,
                    valueType: 'number'
                }
            },
            {
                type: 'show',
                value: 'range',
                props: {
                    isAdvanced: true,
                    valueType: 'number'
                }
            },
            {
                type: 'show',
                value: 'picture-selection',
                props: {
                    useImage: true
                }
            },
            {
                type: 'show',
                value: 'autocomplete'
            },
            {
                type: 'show',
                value: 'radio'
            },
            {
                type: 'show',
                value: {
                    and: [
                        {
                            // when no key is provided, it's referencing the parent
                            value: 'checkbox'
                        },
                        {
                            key: 'multiple',
                            value: 'true'
                        }
                    ]
                },
                props: {
                    disabled: false // leave this here to illustration an example of adding props
                }
            }
        ],
        choices: [],
        info: null,
        watch: null,
        required: true,
        autocomplete: false,
        multiple: false,
        ordinal: 5,
        isPrivate: false,
        isHidden: false,
        hasChildren: false,
        sectionId: '63536643f662ae82d97a1678',
        createdAt: '2022-10-22T03:40:52.898Z',
        updatedAt: '2022-10-22T03:40:52.898Z',
        parent: 'component'
    },
    {
        id: '63536644f662ae82d97a167e',
        key: 'multiple',
        component: 'toggle',
        label: 'Can user select more than 1 choice?',
        sub: null,
        tip: null,
        type: null,
        condition: [
            {
                type: 'show',
                value: 'select'
            },
            {
                type: 'show',
                value: 'autocomplete'
            },
            {
                type: 'show',
                value: 'checkbox'
            },
            {
                type: 'show',
                value: 'picture-selection'
            }
        ],
        defaultValue: false,
        info: null,
        watch: null,
        required: false,
        autocomplete: false,
        multiple: false,
        ordinal: 3,
        isPrivate: false,
        isHidden: false,
        hasChildren: true,
        sectionId: '63536643f662ae82d97a1678',
        createdAt: '2022-10-22T03:40:52.368Z',
        updatedAt: '2022-10-22T03:40:52.368Z',
        parent: 'component'
    },
    {
        id: '63536643f662ae82d97a167d',
        key: 'type',
        component: 'select',
        label: 'Format',
        sub: null,
        tip: null,
        type: null,
        condition: [
            {
                type: 'show',
                value: 'input'
            }
        ],
        defaultValue: 'text',
        choices: [
            { display: '', value: '' },
            { display: 'Tel', value: 'tel' },
            { display: 'Password', value: 'password' },
            { display: 'Number', value: 'numeric' },
            { display: 'Text', value: 'text' }
        ],
        info: null,
        watch: null,
        required: true,
        autocomplete: false,
        multiple: false,
        ordinal: 4,
        isPrivate: false,
        isHidden: false,
        hasChildren: false,
        sectionId: '63536643f662ae82d97a1678',
        createdAt: '2022-10-22T03:40:51.592Z',
        updatedAt: '2022-10-22T03:40:51.595Z',
        parent: 'component'
    },
    {
        id: '63536644f662ae82d97a168f',
        key: 'watch',
        component: 'input',
        label: 'Video Link',
        sub: `Be sure it's public so your users can watch it!`,
        tip: null,
        type: null,
        choices: null,
        info: null,
        watch: null,
        required: false,
        autocomplete: false,
        multiple: false,
        ordinal: 5,
        isPrivate: false,
        isHidden: false,
        hasChildren: false,
        condition: {
            type: 'show',
            value: 'video'
        },
        sectionId: '63536643f662ae82d97a1678',
        createdAt: '2022-10-22T03:40:52.898Z',
        updatedAt: '2022-10-22T03:40:52.898Z',
        parent: 'component'
    }
    // {
    //   id: '63536644f662ae82d97a160f',
    //   key: 'pValue',
    //   component: 'input',
    //   label: 'Internal Value',
    //   sub: 'Private value assign for you to use',
    //   tip: null,
    //   type: null,
    //   choices: null,
    //   info: null,
    //   watch: null,
    //   required: false,
    //   autocomplete: false,
    //   multiple: false,
    //   ordinal: 0,
    //   isPrivate: false,
    //   isHidden: false,
    //   hasChildren: false,
    //   sectionId: '63536643f662ae82d97a1678',
    //   createdAt: '2022-10-22T03:40:52.898Z',
    //   updatedAt: '2022-10-22T03:40:52.898Z',
    //   parent: null
    // },
];
