// import * as formulajs from '@formulajs/formulajs'
import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
// const processRowUpdate = useCallback(
//   async (newRow: GridRowModel) => {
//     console
//     // Make the HTTP request to save in the backend
//     // const response = await mutateRow(newRow)
//     // setSnackbar({ children: 'User successfully saved', severity: 'success' })
//     // return response
//   },
//   [mutateRow]
// )
// const handleProcessRowUpdateError = React.useCallback((error: Error) => {
//   setSnackbar({ children: error.message, severity: 'error' });
// }, []);
const handleProcessRowUpdateError = (error) => {
    console.log('error', error);
    // setSnackbar({ children: error.message, severity: 'error' })
};
const processRowUpdate = async (newRow) => {
    console.log('newRow', newRow);
    const tryFunc = () => {
        try {
            // const result = formulajs.SUM([1, 2, 3])
            // console.log('result', result)
        }
        catch (error) { }
    };
    tryFunc();
    // Make the HTTP request to save in the backend
    // const response = await mutateRow(newRow)
    // setSnackbar({ children: 'User successfully saved', severity: 'success' })
    // return response
};
export const FlowTableView = ({ submissions, groupColumns, columns }) => {
    return (<div style={{ height: 600, width: '100%' }}>
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid rows={submissions} experimentalFeatures={{
            columnGrouping: true,
            newEditingApi: true
        }} processRowUpdate={processRowUpdate} onProcessRowUpdateError={handleProcessRowUpdateError} columnGroupingModel={groupColumns} // grouping of columns
     columns={columns} // field-columns
     checkboxSelection disableSelectionOnClick/>
        </div>
      </div>
    </div>);
};
