import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Collapse, List, ListItem, ListSubheader, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { generateAltFieldEdit } from './AltFieldEdit';
export const EditSettings = ({ id, header, subHeader, settings, useExpand }) => {
    const [expanded, setExpanded] = useState(false);
    const { control, setValue, formState, getValues, watch, trigger } = useFormContext();
    const { errors } = formState;
    const data = watch();
    const handleExpandClick = () => {
        if (useExpand)
            setExpanded(!expanded);
    };
    useEffect(() => {
        settings
            ?.filter((setting) => setting.component === 'toggle')
            ?.forEach((s) => trigger(s.key));
    }, [data?.fields]);
    return (<>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }} key={`settings-${id}`} disablePadding={true} subheader={<ListSubheader onClick={handleExpandClick} sx={{
                cursor: useExpand ? 'pointer' : 'default'
            }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
              <span>{header}</span>
              {useExpand ? (<div>{expanded ? <ExpandLess /> : <ExpandMore />}</div>) : null}
            </Box>
            {subHeader && (<Box pt={'0.5'}>
                <Typography variant='body2' component='header'>
                  {subHeader}
                </Typography>
              </Box>)}
          </ListSubheader>}>
        <Collapse in={expanded} timeout='auto'>
          {settings?.map((setting) => {
            return (<ListItem key={`field-${setting.key}-setting`}>
                {generateAltFieldEdit({
                    ...setting,
                    defaultValue: getValues(setting.key) ?? setting.defaultValue
                }, getValues, setValue, control, errors)}
              </ListItem>);
        })}
        </Collapse>
        {!useExpand &&
            settings?.map((setting) => {
                return (<ListItem key={`field-${setting.key}-setting`}>
                {generateAltFieldEdit({
                        ...setting,
                        defaultValue: getValues(setting.key) ?? setting.defaultValue
                    }, getValues, setValue, control, errors)}
              </ListItem>);
            })}
      </List>
    </>);
};
