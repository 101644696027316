import { DeleteOutline } from '@mui/icons-material';
import { Box, Button, Card, Divider, IconButton, TextField, Typography } from '@mui/material';
import { DebounceField } from 'components/DebounceField';
import { formatTextarea } from 'forms/format';
import React, { memo, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { deleteWorkflowStage, updateTaskPosition, upsertWorkflowStage } from 'services/workflow-admin-service';
import { filterFieldProperties } from '../filter-field-properties';
import { TaskDetails } from '../TaskDetails';
/**
 * @param props
 * @returns
 */
export const FlowBoardView = memo(({ flowSlug, workflow, meta }) => {
    const [stages, setStages] = useState(workflow?.stages || []);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);
    console.log('meta', meta);
    const onDragEnd = async ({ source, destination, draggableId }) => {
        /**
         * note:
         * - droppableId = stageId
         * - draggableId = taskId
         */
        if (!destination)
            return;
        if (destination.droppableId === source.droppableId)
            return;
        // console.log('source', source)
        // console.log('destination', destination)
        const taskId = draggableId;
        const stageId = destination.droppableId;
        const sourceColIndex = stages.findIndex((e) => e.id === source.droppableId);
        const destinationColIndex = stages.findIndex((e) => e.id === destination.droppableId);
        const sourceCol = stages[sourceColIndex];
        const destinationCol = stages[destinationColIndex];
        const sourceSectionId = sourceCol.id;
        const destinationSectionId = destinationCol.id;
        const sourceTasks = [...sourceCol.tasks];
        const destinationTasks = [...(destinationCol?.tasks || [])];
        if (source.droppableId !== destination.droppableId) {
            const [removed] = sourceTasks.splice(source.index, 1);
            destinationTasks.splice(destination.index, 0, removed);
            stages[sourceColIndex].tasks = sourceTasks;
            stages[destinationColIndex].tasks = destinationTasks;
        }
        else {
            const [removed] = destinationTasks.splice(source.index, 1);
            destinationTasks.splice(destination.index, 0, removed);
            stages[destinationColIndex].tasks = destinationTasks;
        }
        await updateTaskPosition(flowSlug, workflow?.id, taskId, stageId);
    };
    const createStage = async () => {
        try {
            const stage = {
                name: 'Untitled',
                tasks: []
            };
            const newStageResponse = await upsertWorkflowStage(flowSlug, workflow?.id, 'new', stage);
            setStages([...stages, newStageResponse]);
        }
        catch (err) {
            alert(err);
        }
    };
    const deleteStage = async (stageId) => {
        try {
            await deleteWorkflowStage(flowSlug, workflow?.id, stageId);
            const allStages = [...stages].filter((e) => e.id !== stageId);
            setStages(allStages);
        }
        catch (err) {
            alert(err);
        }
    };
    const updateStageName = async (value, context) => {
        const newName = value;
        const { stageId } = context;
        const newStageResponse = await upsertWorkflowStage(flowSlug, workflow?.id, stageId, {
            name: newName
        });
        const newData = stages.map((stage) => {
            return {
                ...stage,
                name: stage.id === newStageResponse.id ? newStageResponse.name : stage.name
            };
        });
        setStages(newData);
    };
    // const createTask = async (sectionId) => {
    //   try {
    //     const task = { id: sectionId, title: 'Untiltled' }
    //     const newData = [...stages]
    //     const index = newData.findIndex((e) => e.id === sectionId)
    //     newData[index].tasks?.unshift(task)
    //     setStages(newData)
    //   } catch (err) {
    //     alert(err)
    //   }
    // }
    // const onUpdateTask = (task) => {
    //   const newData = [...stages]
    //   const sectionIndex = newData.findIndex((e) => e.id === task.section.id)
    //   const taskIndex = newData[sectionIndex].tasks.findIndex(
    //     (e) => e.id === task.id
    //   )
    //   newData[sectionIndex].tasks[taskIndex] = task
    //   setStages(newData)
    // }
    // const onDeleteTask = (task) => {
    //   const newData = [...stages]
    //   const sectionIndex = newData.findIndex((e) => e.id === task.section.id)
    //   const taskIndex = newData[sectionIndex].tasks.findIndex(
    //     (e) => e?.id === task?.id
    //   )
    //   newData[sectionIndex].tasks.splice(taskIndex, 1)
    //   setStages(newData)
    // }
    const renderCard = useMemo(() => (task) => {
        const entries = Object.entries(task);
        return entries
            ?.filter(([key, _value]) => filterFieldProperties(key))
            .map(([key, value]) => {
            const component = meta?.fields?.[key]?.component;
            return (<Box key={key} sx={{ pb: 1 }}>
              <Typography variant='h6' component='header'>
                {meta?.fields?.[key]?.label}
              </Typography>
              {component !== 'signature' ? (<Typography dangerouslySetInnerHTML={{
                        __html: formatTextarea(renderFieldDataByComponent(component, value || ' '))
                    }}/>) : value ? (<img src={formatTextarea(value)} style={{ maxWidth: '100%', maxHeight: '75px' }}/>) : ('')}
            </Box>);
        });
    }, [stages]);
    return (<>
      {/* <pre>{JSON.stringify(initialData, null, 1)}</pre> */}
      <Box id='flow-board' sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        }}>
        {/* <Button onClick={createStage}>Add stage</Button> */}
        <Typography variant='body2' fontWeight='700'>
          {stages.length} Stages
        </Typography>
      </Box>
      <Divider sx={{ margin: '10px 0' }}/>
      <DragDropContext onDragEnd={onDragEnd}>
        <Box id='flow-board-stages' sx={{
            display: 'flex',
            alignItems: 'flex-start',
            overflowX: 'auto'
        }}>
          {stages?.map((stage) => (<div id={`flow-board-stage-${stage.id}`} key={stage.id}>
              <Droppable key={stage.id} droppableId={stage.id}>
                {(provided) => (<Box ref={provided.innerRef} {...provided.droppableProps} sx={{
                    width: '300px',
                    padding: '10px',
                    paddingBottom: '30px',
                    marginRight: '10px',
                    minHeight: '800px',
                    maxHeight: '800px',
                    backgroundColor: 'lightgray',
                    overflowX: 'auto',
                    ':hover': {
                        border: '1px solid black'
                    }
                }}>
                    <Box className='stage-name' sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '15px',
                    overflow: 'auto'
                }}>
                      <DebounceField callBackApi={updateStageName} defaultValue={stage.name} context={{ stageId: stage.id }} Component={TextField} componentProps={{
                    placeholder: 'Untitled',
                    variant: 'outlined',
                    sx: {
                        flexGrow: 1,
                        '& .MuiOutlinedInput-input': { padding: 0 },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'unset '
                        },
                        '& .MuiOutlinedInput-root': {
                            fontSize: '1rem',
                            fontWeight: '700'
                        },
                        ':hover': {
                            borderBottom: '1px solid black'
                        }
                    }
                }}/>

                      {/* <IconButton
                  size='small'
                  sx={{
                    color: 'gray',
                    '&:hover': { color: 'green' }
                  }}
                  onClick={() => createTask(stage.id)}>
                  <AddOutlined />
                </IconButton> */}

                      {stage.isBacklog === false &&
                    stage.id &&
                    !stage.tasks?.length ? (<IconButton size='small' sx={{
                        color: 'gray',
                        '&:hover': { color: 'red' }
                    }} onClick={() => deleteStage(stage.id)}>
                          <DeleteOutline />
                        </IconButton>) : null}
                    </Box>
                    {/* tasks */}
                    {stage.tasks?.map((task, index) => (<Draggable key={task.id} draggableId={task.id} index={index}>
                        {(provided, snapshot) => (<Card variant='outlined' sx={{
                            ':hover': {
                                border: '1px solid black'
                            },
                            padding: '10px',
                            marginBottom: '10px',
                            cursor: snapshot.isDragging
                                ? 'grab'
                                : 'pointer !important',
                            minHeight: '200px',
                            maxHeight: '350px',
                            overflow: 'scroll'
                        }} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} onClick={() => {
                            setIsOpen(true);
                            setSelectedTask(task);
                        }}>
                            <Box sx={{ overflow: 'scroll', maxHeight: '320px' }}>
                              {renderCard(task)}
                            </Box>
                          </Card>)}
                      </Draggable>))}
                    {provided.placeholder}
                  </Box>)}
              </Droppable>
            </div>))}
          <div id={`flow-board-stage-add`} key={'stage-add'}>
            <Box sx={{
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center'
        }}>
              <Button size='large' variant='outlined' onClick={createStage} sx={{
            width: '300px',
            padding: '10px'
        }}>
                Add stage
              </Button>
            </Box>
          </div>
        </Box>
      </DragDropContext>

      {selectedTask ? (<TaskDetails isOpen={isOpen} setIsOpen={setIsOpen} task={selectedTask} meta={meta}/>) : null}
    </>);
});
export const renderFieldDataByComponent = (component, value) => {
    console.log('value', value);
    switch (component) {
        // case 'photo-upload':
        //   return <FileUploadPreview answer={value} />
        default:
            return Array.isArray(value) ? value?.join('\n') : value;
    }
};
