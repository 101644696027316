import { CREATE_NEW_FLOW_SLUG } from 'forms/alt-field';
import { paramCase } from 'shared';
/**
 * Create Admin Flow
 * - overriding the admin field (slug)
 */
export const adminFieldsOverride = {
    slug: CREATE_NEW_FLOW_SLUG,
    data: {
        sections: {
            0: {
                fields: {
                    name: {
                        props: {}
                    },
                    slug: {
                        props: {
                            disabled: true,
                            hidden: true
                        }
                    }
                }
            }
        }
    }
};
/**
 * This is for the Create Flow fields overrides.
 * The slug input field is hidden and is getting updated when a user types in the flow name
 */
export const adminFieldsOverrides = {
    field: 'name',
    action: (args) => {
        if (Object.hasOwn(args?.formValues, 'name')) {
            args?.setValue('slug', paramCase(args?.formValues?.name));
        }
    }
};
