import { CheckCircleOutlineTwoTone } from '@mui/icons-material';
import { Box, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { findConditionsV2 } from 'forms/conditions-v2';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useAltField } from './altfield/useAltField';
import { renderAltFieldIcon } from './altfield/renderAltFieldIcon';
import { renderAltFieldReadOnly } from './altfield/renderAltFieldReadOnly';
const renderNonFieldComponents = (field) => {
    switch (field.component) {
        case 'header':
            return (<>
          <h3>{field.label}</h3>
          <h4>{field.sub}</h4>
        </>);
    }
};
/**
 * This is the public flow's altfield and api reponse altfield
 * @param currentField
 * @param getValues
 * @param setValue
 * @param control
 * @param errors
 * @returns
 */
export const generateAltField = (currentField, getValues, setValue, control, errors) => {
    const currentParentValue = getValues(`${currentField?.parent}`) || undefined;
    const condition = currentField?.condition &&
        currentField.parent &&
        findConditionsV2(currentField, currentField?.condition || null, getValues);
    if (condition?.hide)
        return null;
    if (currentField?.parent && currentParentValue === undefined)
        return null;
    const getDefaultValueBy = (component, value, multiple = false, isJson = false) => {
        const toString = (text) => {
            try {
                if (typeof text === 'string')
                    return text;
                return JSON.stringify(text);
            }
            catch (error) {
                return text;
            }
        };
        switch (component) {
            case 'toggle':
                return value ?? false;
            case 'checkbox':
            case 'picture-selection':
            case 'autocomplete':
            case 'select': {
                if (multiple) {
                    if (value !== undefined) {
                        if (!value)
                            return [];
                        if (Array.isArray(value) && value.length < 1)
                            return [];
                        return Array.isArray(value) ? value : [value];
                    }
                    return [];
                }
                return value ?? '';
            }
            case 'radio':
            case 'video':
            case 'signature':
                return value ?? '';
            case 'textarea':
            default:
                return !isJson ? value || '' : toString(value);
        }
    };
    const defaultValue = getDefaultValueBy(currentField.component, currentField.defaultValue, currentField?.multiple ?? false, currentField?.format === 'json' || false);
    // console.log(
    //   currentField.component,
    //   currentField.key,
    //   'value',
    //   currentField.defaultValue,
    //   'defaultValue',
    //   defaultValue
    // )
    return (<>
      {currentField.component !== 'header' ? (<Controller key={currentField.key} name={currentField.key} control={control} rules={{
                required: currentField?.required ?? false
            }} defaultValue={defaultValue} render={({ field: controlledField }) => {
                return (<Box sx={{ pb: 1 }}>
                {useAltField({
                        altField: currentField,
                        controlledField,
                        getValues,
                        setValue,
                        condition,
                        errors
                    })}
              </Box>);
            }}/>) : (renderNonFieldComponents(currentField))}

      {/* {error ? <div>{error}</div> : null} */}
    </>);
};
export const renderAltFieldOverview = (altField, answers) => {
    const renderSubLabelAndAnswer = (subLabel, answer) => {
        return (<>
        {subLabel ? (<>
            {subLabel}
            <br />
          </>) : null}
        {answer ? (<Box sx={{
                    alignContent: 'flex-start',
                    alignItems: 'flex-start',
                    display: 'flex',
                    pt: 1
                }}>
            <CheckCircleOutlineTwoTone fontSize='small' color='success'/>
            {renderAltFieldReadOnly(altField, answer)}
          </Box>) : null}
      </>);
    };
    return (<ListItem id={altField.id} key={altField.id} sx={{
            px: 0,
            alignItems: 'self-start'
        }}>
      <ListItemIcon sx={{
            minWidth: '40px'
        }}>
        {renderAltFieldIcon(altField.component)}
      </ListItemIcon>
      <ListItemText primary={altField.label} secondary={renderSubLabelAndAnswer(altField?.sub, answers[altField.key])} secondaryTypographyProps={{ component: 'div' }} sx={{
            marginTop: 0
        }}/>
    </ListItem>);
};
