import altflowAwsApi from './altflow-aws-api';
export const getFlowAdmin = async (flowSlug, token) => {
    try {
        const { data } = await altflowAwsApi.get(`/flows/${flowSlug}?mode=edit`, {
            headers: {
                authorization: `Bearer ${token}`
            }
        });
        return data;
    }
    catch (error) {
        console.error('error', error);
        return null;
    }
};
export const createFlow = async (flow, template) => {
    const queryParam = template ? `?template=${template}` : '';
    const { data } = await altflowAwsApi.post(`/flows${queryParam}`, flow);
    return data;
};
export const updateFlowBySlug = async (flowSlug, flow) => {
    const { data } = await altflowAwsApi.put(`flows/${flowSlug}`, {
        ...flow
    });
    return data;
};
export const deleteFlow = async (slug) => {
    await altflowAwsApi.delete(`flows/${slug}`);
};
export const getFlowSubmissions = async (flowSlug) => {
    // return {
    //   items: [
    //     {
    //       id: '63e1814eadf0dce4f27a5d63',
    //       username: 'huantbui@gmail.com',
    //       _stageId: null,
    //       _firstname: 'HB',
    //       _lastname: 'Bui',
    //       'uaqwd.cpnou': 1,
    //       'uaqwd.qppaw': 2,
    //       'uaqwd.wkudr': 3
    //     },
    //     {
    //       id: 'fdafdsafd',
    //       username: 'dsfsd@gmail.com',
    //       _stageId: null,
    //       _firstname: 'HB',
    //       _lastname: 'Bui',
    //       'uaqwd.cpnou': 0,
    //       'uaqwd.qppaw': 5,
    //       'uaqwd.wkudr': 5
    //     }
    //   ],
    //   meta: {
    //     fields: {
    //       'uaqwd.cpnou': {
    //         key: 'cpnou',
    //         label: 'Segment A',
    //         component: 'linear'
    //       },
    //       'uaqwd.qppaw': {
    //         key: 'qppaw',
    //         label: 'Segment B',
    //         component: 'linear'
    //       },
    //       'uaqwd.wkudr': { key: 'wkudr', label: 'Segment C', component: 'linear' }
    //     },
    //     sections: { uaqwd: 'Section 1' }
    //   }
    // }
    try {
        const { data } = await altflowAwsApi.get(`/flows/${flowSlug}/submissions`);
        return data;
    }
    catch (error) {
        return [];
    }
};
