import { InfoOutlined } from '@mui/icons-material';
import { Autocomplete, Box, Checkbox, Chip, FormControl, FormControlLabel, FormHelperText, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Switch, TextField, Tooltip, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AltSlider } from 'components/AltSlider';
import { AltVideo } from 'components/AltVideo';
import { Checkboxes } from 'components/Checkboxes';
import { ChipList } from 'components/ChipList';
import { FileUpload } from 'components/FileUpload';
import { PictureSelection } from 'components/PictureSelection';
import { Signature } from 'components/Signature';
import get from 'lodash.get';
import React from 'react';
import { paramCase } from 'shared';
import { LAST_FIELD_KEY } from 'shared/alt-field';
const GLOBAL_PROPS = {
    fullWidth: true
};
export const useAltField = ({ altField, controlledField, getValues, setValue, condition, errors }) => {
    // const requiredText = altField.required ? '*' : ''
    const key = paramCase(altField.key);
    const labelWithRequired = (<>
      {altField.label}
      {altField?.required ? '*' : ''}
      {altField?.tip ? (<Tooltip title={altField?.tip} placement='right' sx={{
                ml: '4px'
            }}>
          <InfoOutlined fontSize='inherit'/>
        </Tooltip>) : null}
      {altField.multiple === true && (<Tooltip title={'You can choose multiple options'} placement='right' sx={{
                ml: '4px'
            }}>
          <InfoOutlined fontSize='inherit'/>
        </Tooltip>)}
    </>);
    const error = get(errors, altField.key, null) || null;
    if (altField?.props?.hidden) {
        return (<TextField error={Object.keys(error || {})?.length > 0} {...controlledField} {...condition?.props} type={altField?.type} required={altField.required} key={key} id={key} helperText={altField.sub} {...altField.props} {...GLOBAL_PROPS} style={{
                display: 'none'
            }}/>);
    }
    switch (altField.component) {
        case 'autocomplete': {
            // https://mui.com/material-ui/react-autocomplete/
            return (<>
          <InputLabel id={`${key}-label`} sx={{ display: 'flex', alignItems: 'center' }}>
            {labelWithRequired}
          </InputLabel>
          <Autocomplete disablePortal options={altField.choices} 
            // this is how it should be
            getOptionLabel={(label) => label?.display ?? label} isOptionEqualToValue={(choice, selection) => {
                    return choice.value === selection;
                }} key={key} multiple={altField?.multiple ?? false} renderTags={(value, getTagProps) => value?.map((option, index) => (<Chip variant='outlined' label={option} {...getTagProps({ index })}/>))} renderInput={(params) => <TextField {...params}/>} onChange={(e, selection) => {
                    if (!selection)
                        controlledField.onChange('');
                    else
                        controlledField.onChange(Array.isArray(selection)
                            ? selection?.map((s) => s?.value)
                            : selection?.value);
                }} value={altField.defaultValue} {...condition?.props} {...altField.props} {...GLOBAL_PROPS}/>
          <FormHelperText>{altField.sub || ''}</FormHelperText>
        </>);
        }
        case 'date':
            return (<LocalizationProvider dateAdapter={AdapterDateFns}>
          <InputLabel id={`${key}-label`} sx={{ pb: 1 }}>
            {labelWithRequired}
          </InputLabel>
          <DatePicker inputFormat='MM-dd-yyyy' {...controlledField} renderInput={(params) => (<TextField {...params} error={error} label='MM-dd-yyyy' {...controlledField} {...condition?.props} type={altField?.type} required={altField.required} key={key} id={key} helperText={altField.sub} {...altField.props} {...GLOBAL_PROPS}/>)}/>
        </LocalizationProvider>);
        case 'input':
            if (altField?.type === 'date') {
                return (<LocalizationProvider dateAdapter={AdapterDateFns}>
            <InputLabel id={`${key}-label`} sx={{ pb: 1 }}>
              {labelWithRequired}
            </InputLabel>
            <DatePicker inputFormat='MM-dd-yyyy' {...controlledField} renderInput={(params) => (<TextField {...params} error={Object.keys(error || {})?.length > 0} label='MM-dd-yyyy' {...controlledField} {...condition?.props} type={altField?.type} required={altField.required} key={key} id={key} helperText={altField.sub} {...altField.props} {...GLOBAL_PROPS}/>)}/>
          </LocalizationProvider>);
            }
            return (<>
          <InputLabel id={`${key}-label`}>{labelWithRequired}</InputLabel>
          <TextField error={Object.keys(error || {})?.length > 0} {...controlledField} {...condition?.props} type={altField?.type} required={altField.required} key={key} id={key} helperText={altField.sub} {...altField.props} {...GLOBAL_PROPS}/>
        </>);
        case 'textarea':
            return (<>
          <InputLabel id={`${key}-label`}>{labelWithRequired}</InputLabel>
          <TextField error={error} {...controlledField} {...condition?.props} multiline resize='true' minRows={3} type={altField?.type} required={altField.required} key={key} id={key} helperText={altField.sub} {...altField.props} {...GLOBAL_PROPS}/>
        </>);
        case 'radio':
            return (<>
          <FormControl component='fieldset' variant='outlined' {...controlledField} {...condition?.props} {...GLOBAL_PROPS}>
            <FormLabel id={`${key}-label`}>{labelWithRequired}</FormLabel>
            <RadioGroup value={controlledField.value} key={key} id={key}>
              {altField.choices?.map((choice) => {
                    return (<FormControlLabel key={choice?.value ?? choice?.display} value={choice?.value ?? choice?.display} control={<Radio />} label={choice?.display}/>);
                })}
            </RadioGroup>
            <FormHelperText>{altField.sub || ''}</FormHelperText>
          </FormControl>
        </>);
        case 'toggle':
            return (<>
          <FormLabel id={`${key}-label`}>{labelWithRequired}</FormLabel>
          <Switch id={key} required={altField.required} defaultChecked={altField.defaultValue} edge='end' inputProps={{
                    'aria-labelledby': altField.label
                }} {...controlledField} {...condition?.props} {...altField.props}/>
          <FormHelperText>{altField.sub || ''}</FormHelperText>
        </>);
        case 'picture-selection':
            return (<PictureSelection altField={altField} controlledField={controlledField} label={labelWithRequired} condition={condition}/>);
        case 'checkbox':
            return altField.key !== LAST_FIELD_KEY ? (<>
          {altField.multiple === true ? (
                // https://react-hook-form.com/api/usecontroller#example
                <Checkboxes altField={altField} controlledField={controlledField} label={labelWithRequired} condition={condition}/>) : (<FormControl component='fieldset' variant='outlined' {...controlledField} {...condition?.props}>
              <FormControlLabel key={key} value={labelWithRequired} control={<Checkbox key={key} id={key} name={altField.label} defaultChecked={altField.defaultValue}/>} label={labelWithRequired}/>
              <FormHelperText>{altField.sub}</FormHelperText>
            </FormControl>)}
        </>) : null;
        case 'select': {
            return (<>
          <InputLabel id={`${key}-label`} sx={{ display: 'flex', alignItems: 'center' }}>
            {labelWithRequired}
          </InputLabel>
          <Select id={key} key={key} {...controlledField} {...condition?.props} multiple={altField?.multiple ?? false} required={altField.required} labelId={`${key}-label`} {...altField.props} {...GLOBAL_PROPS}>
            {altField.choices?.map((choice) => {
                    return (<MenuItem key={choice?.value} value={choice?.value} divider={true}>
                  {choice?.display}
                </MenuItem>);
                })}
          </Select>
          <FormHelperText>{altField.sub}</FormHelperText>
        </>);
        }
        case 'video':
            return (<AltVideo label={labelWithRequired} altField={altField} controlledField={controlledField} getValues={getValues} setValue={setValue} error={error} condition={condition}/>);
        case 'signature': {
            // add controlledField and use `controlledField.onchange` instead
            return <Signature altField={altField} label={labelWithRequired}/>;
        }
        /**
         * @private
         * - this can only be used if flow.settings.toRequireAuth is true
         * - why? because 1. it's a premium and 2. have traceability to auth user who uploaded it
         */
        // add controlledField and use `controlledField.onchange` instead
        case 'upload':
            return (<Box key={key} sx={{ width: '100%' }}>
          <Typography variant='body1' component='span'>
            {labelWithRequired}
          </Typography>
          <Typography variant='body2' component='p'>
            {altField.sub}
          </Typography>
          <FileUpload altField={{ ...altField, setValue }} fileType='all' type='user'/>
          <FormHelperText>{altField.sub || ''}</FormHelperText>
        </Box>);
        case 'photo-upload':
            return (<Box key={key} sx={{ width: '100%' }}>
          <Typography variant='body1' component='span'>
            {labelWithRequired}
          </Typography>
          <Typography variant='body2' component='p'>
            {altField.sub}
          </Typography>
          <FileUpload altField={{ ...altField, setValue }} fileType='image' type='user'/>
          <FormHelperText>{altField.sub || ''}</FormHelperText>
        </Box>);
        case 'chip':
            return (<ChipList label={labelWithRequired} altField={altField} controlledField={controlledField} getValues={getValues} setValue={setValue} error={error} condition={condition}/>);
        case 'linear':
        case 'range':
            return (<AltSlider label={labelWithRequired} altField={altField} controlledField={controlledField} getValues={getValues} setValue={setValue} error={error} condition={condition}/>);
        default:
            break;
    }
};
