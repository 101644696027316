import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import { renderAltFieldOverview } from 'components/AltField';
import { formatTextarea } from 'forms/format';
import React from 'react';
import { useNavigate } from 'react-router-dom';
export const SectionOverview = ({ section, answers, status, isActionButtonDisabled }) => {
    const navigate = useNavigate();
    const getSectionStatus = (status) => {
        switch (status) {
            case 'CREATED':
                return 'START';
            case 'IN_PROGRESS':
                return 'CONTINUE';
            case 'COMPLETED':
                return 'REVIEW';
            default:
                return 'START';
        }
    };
    return (<Card variant='outlined' id={section.id}>
      <CardContent>
        <Typography variant='h3' component='header' sx={{ pb: 1 }}>
          {section.header.label}
        </Typography>
        <Typography sx={{ mb: 2 }} variant='h5' color='text.secondary' component='p' dangerouslySetInnerHTML={{
            __html: formatTextarea(section.header?.sub)
        }}/>
        {section.settings?.displayOverview ? (<>
            <Typography sx={{ mb: 1 }} variant='h5' color='text.secondary' component='p'>
              {status === 'CREATED' ? 'What to expect:' : 'Your responses:'}
            </Typography>
            {section.fields?.map((field) => {
                return renderAltFieldOverview(field, answers);
            })}
          </>) : null}
      </CardContent>
      <CardActions>
        <Button size='medium' variant='contained' disabled={isActionButtonDisabled} onClick={() => navigate(`${section.slug}`, {
            state: {
                sectionId: section.id
            }
        })}>
          {getSectionStatus(status || 'CREATED')}
        </Button>
      </CardActions>
    </Card>);
};
