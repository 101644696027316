import altflowAwsApi from './altflow-aws-api';
/**
 * @deprecated - we will create the workflow at the creation of a flow
 * @param flowSlug
 * @param workflowId
 * @returns
 */
export const createWorkflow = async (flowSlug, workflowId) => {
    const { data } = await altflowAwsApi.put(`/flows/${flowSlug}/workflow/${workflowId}`, {
        name: `Workflow ${flowSlug}`
    });
    return data;
};
/**
 *
 * @param flowSlug
 * @returns
 */
export const getWorkflow = async (flowSlug) => {
    try {
        const { data } = await altflowAwsApi.get(`/flows/${flowSlug}/workflow`);
        return data;
    }
    catch (error) {
        console.error('getWorkflow', error);
        return null;
    }
};
export const upsertWorkflowStage = async (flowSlug, workflowId, stageId, body) => {
    const { data } = await altflowAwsApi.put(`/flows/${flowSlug}/workflow/${workflowId}/stage/${stageId}`, { ...body });
    return data;
};
export const deleteWorkflowStage = async (flowSlug, workflowId, stageId) => {
    await altflowAwsApi.delete(`/flows/${flowSlug}/workflow/${workflowId}/stage/${stageId}`);
};
export const updateTaskPosition = async (flowSlug, workflowId, taskId, toStage) => {
    await altflowAwsApi.put(`/flows/${flowSlug}/workflow/${workflowId}/task`, {
        id: taskId,
        stageId: toStage
    });
};
