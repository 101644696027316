import axios from 'axios';
import altflowAwsApi from './altflow-aws-api';
/**
 *
 * @param slug
 * @param type 'logo' | 'user'
 * @param fileType
 * @param file
 * @returns
 */
export const uploadFile = async (slug, type, fileType, file) => {
    async function getPresignedPostUrl() {
        const { data: presignedPostUrl } = await altflowAwsApi.get(`flows/${slug}/upload?fileType=${fileType}&type=${type}`);
        return presignedPostUrl;
    }
    const presignedPostUrl = await getPresignedPostUrl();
    const headers = Object.keys(presignedPostUrl.fields || {})
        ?.filter((key) => key.startsWith('x-amz-meta-'))
        ?.reduce((acc, curr) => {
        acc[curr] = presignedPostUrl.fields[curr];
        return acc;
    }, {});
    const formData = new FormData();
    formData.append('Content-Type', fileType);
    Object.entries(presignedPostUrl.fields).forEach(([k, v]) => {
        formData.append(k, v);
    });
    formData.append('file', file); // The file has be the last element
    await axios.post(presignedPostUrl.url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            ...headers
        }
    });
    return `${presignedPostUrl.url}/${presignedPostUrl.filePath}`;
};
