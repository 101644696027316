import { Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import React from 'react';
import { TaskDetailsCard } from './views/TaskDetailsCard';
export const TaskDetails = ({ isOpen, setIsOpen, task, meta }) => {
    const handleClose = () => {
        setIsOpen(false);
    };
    return (<Dialog open={isOpen} fullWidth={true} maxWidth={'xl'} keepMounted={true} onClose={handleClose}>
      <DialogTitle></DialogTitle>
      <DialogContent>
        <TaskDetailsCard task={task} meta={meta}/>
        <Divider />
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={handleClose}>Disagree</Button> */}
        {/* <Button onClick={handleClose} autoFocus>
          Agree
        </Button> */}
      </DialogActions>
    </Dialog>);
};
