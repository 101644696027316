import altflowAwsApi from './altflow-aws-api';
export const getUser = async (id) => {
    try {
        const { data } = await altflowAwsApi.get(`/users/${id}`);
        return data;
    }
    catch (error) {
        throw error;
    }
};
export const getMySubmissions = async () => {
    try {
        const { data } = await altflowAwsApi.get(`/my/submissions`);
        return data;
    }
    catch (error) {
        throw error;
    }
};
export const updateUser = async (id, { lastName, firstName, email }) => {
    try {
        const { data } = await altflowAwsApi.put(`/users/${id}`, {
            firstName,
            lastName,
            email
        });
        return data;
    }
    catch (error) {
        console.error('error update-user', error);
        // throw error
    }
};
export const postUserAnswers = async (flowSlug, sectionId, userId, data) => {
    await altflowAwsApi.post(`/flows/${flowSlug}/sections/${sectionId}/answers?userId=${userId}`, data);
};
export const postUserFlowStatus = async (flowSlug, sectionId, userId, newStatus) => {
    await altflowAwsApi.post(`/flows/${flowSlug}/sections/${sectionId}/status?userId=${userId}`, { status: newStatus });
};
