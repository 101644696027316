import { InfoOutlined, PriorityHighOutlined } from '@mui/icons-material';
import { Box, InputAdornment, InputBase, ListItemIcon, ListItemText, MenuItem, Select, Switch, Tooltip, Typography } from '@mui/material';
import { ChipList } from 'components/ChipList';
import { FileUpload } from 'components/FileUpload';
import { MatrixGroup } from 'components/MatrixGroup';
import { findConditionsV2 } from 'forms/conditions-v2';
import get from 'lodash/get';
import React from 'react';
import { Controller } from 'react-hook-form';
import { paramCase } from 'shared/utils';
import { fieldHoverStyle } from 'style';
const GLOBAL_PROPS = {
// fullWidth: true
};
export const generateAltFieldEdit = (currentField, getValues, setValue, control, errors, props) => {
    const currentParentValue = getValues(`${currentField?.parent}`) || undefined;
    const condition = currentField?.condition &&
        currentField.parent &&
        findConditionsV2(currentField, currentField?.condition || null, getValues);
    if (condition?.hide)
        return null;
    if (currentField?.parent && currentParentValue === undefined)
        return null;
    const getDefaultValueBy = (component, value, isJson) => {
        const toString = (value) => {
            try {
                if (typeof value === 'string')
                    return value;
                return JSON.stringify(value);
            }
            catch (error) {
                return value;
            }
        };
        switch (component) {
            case 'radio':
            case 'checkbox':
            case 'toggle':
                return value ?? false;
            case 'matrix':
                return value?.map((val) => {
                    return {
                        display: val?.display ?? val,
                        value: val?.value ?? val,
                        ...(val?.image ? { image: val?.image } : undefined)
                    };
                });
            case 'select':
            case 'chip':
            case 'video':
            case 'textarea':
            default:
                return !isJson ? value ?? '' : toString(value);
        }
    };
    const defaultValue = getDefaultValueBy(currentField.component, currentField.defaultValue, currentField?.format === 'json');
    // if (currentField.key.includes('choices')) {
    //   console.log('defaultValue', defaultValue)
    // }
    return (<>
      {!props?.isSimple &&
            (currentField?.icon ? (<ListItemIcon sx={{ ...currentField?.props?.sx }}>
            {currentField?.icon}
          </ListItemIcon>) : null)}
      {currentField.component !== 'header' ? (<Controller key={currentField.key} name={currentField.key} control={control} rules={{
                required: currentField?.required ?? false
            }} defaultValue={defaultValue} render={({ field: controlledField }) => {
                return (<>
                {/* since input/chip/upload require a wider space,
                     * instead of putting it inside mui list,
                     * allow these components to have its own style
                     */}
                {!['input', 'chip', 'upload', 'matrix'].some((componentName) => componentName === currentField.component) && (<ListItemText id='switch-list-label-wifi' key={currentField.key} primary={<>
                        {currentField.label}
                        {currentField?.required ? '*' : ''}
                        {currentField?.tip ? (<Tooltip title={currentField?.tip} placement='bottom' sx={{
                                    ml: '4px'
                                }}>
                            <InfoOutlined fontSize='inherit'/>
                          </Tooltip>) : ('')}
                      </>} secondary={`${currentField?.sub ?? ''}`} 
                    // sx={}
                    // secondary={`${
                    //   currentField?.sub
                    //   // JSON.stringify(currentField.defaultValue, null, 1)
                    // }`}
                    style={{
                            textTransform: 'capitalize'
                        }}/>)}
                {useAltFieldEdit({
                        altField: currentField,
                        controlledField,
                        getValues,
                        setValue,
                        condition,
                        errors,
                        props
                    })}
              </>);
            }}/>) : null}

      {/* {errors ? <div>{errors}</div> : null} */}
    </>);
};
/**
 *
 * Render the admin / edit section ONLY!
 * Private use!
 *
 */
const useAltFieldEdit = ({ altField, controlledField, getValues, setValue, condition, errors, props }) => {
    const key = paramCase(altField.key); // just key for react.key -- nothing else
    const labelWithRequired = (<>
      {altField.label}
      {altField?.required ? '*' : ''}
      {altField?.tip ? (<Tooltip title={altField?.tip} placement='right' sx={{
                ml: '4px'
            }}>
          <InfoOutlined fontSize='inherit'/>
        </Tooltip>) : null}
    </>);
    const error = get(errors, altField.key, null) || null;
    switch (altField.component) {
        case 'toggle':
            return (<Switch key={key} id={key} edge='end' inputProps={{
                    'aria-labelledby': altField.label
                }} {...controlledField} defaultChecked={altField?.defaultValue || false} {...altField.props}/>);
        // https://www.freecodecamp.org/news/how-to-create-email-chips-in-pure-react-ad1cc3ecea16/
        case 'chip': {
            return (<ChipList label={labelWithRequired} altField={altField} controlledField={controlledField} getValues={getValues} setValue={setValue} error={error} condition={condition}/>);
        }
        case 'matrix':
            return (<MatrixGroup key={altField.key} label={labelWithRequired} altField={altField} controlledField={controlledField} getValues={getValues} setValue={setValue} error={error} condition={condition}/>);
        case 'input':
            return (<>
          <Box key={key} sx={{ width: '100%', ...altField?.props?.sx }}>
            {!props?.isSimple && (<>
                <Typography variant='body1' component='span'>
                  {labelWithRequired}
                </Typography>
                <Typography variant='body2' component='p'>
                  {altField.sub}
                </Typography>
              </>)}
            {altField?.example ? (<Typography variant='body2' component='i'>
                {altField.example(controlledField?.value || altField.defaultValue)}
              </Typography>) : null}
            <InputBase key={key} id={key} required={altField?.required ?? true} type={altField?.type ?? 'text'} {...GLOBAL_PROPS} placeholder={altField.label} inputProps={{ 'aria-label': 'text field' }} {...altField.props} sx={{
                    flex: 1,
                    height: '2em',
                    fontSize: '16px',
                    width: '100%',
                    ...fieldHoverStyle,
                    ...altField.props?.sx
                }} {...condition?.props} {...controlledField} onChange={(e) => controlledField.onChange(altField?.transform ? altField?.transform(e.target.value) : e)} endAdornment={error ? (<InputAdornment position='end'>
                    <PriorityHighOutlined color='error'/>
                  </InputAdornment>) : null} color={error ? 'error' : 'primary'}/>
          </Box>
        </>);
        case 'upload':
            return (<Box key={key} sx={{ width: '100%' }}>
          <Typography variant='body1' component='span'>
            {labelWithRequired}
          </Typography>
          <Typography variant='body2' component='p'>
            {altField.sub}
          </Typography>
          <FileUpload altField={{ ...altField, setValue }} fileType='image' type='logo'/>
        </Box>);
        case 'select':
            return (<Select id={key} key={key} variant='standard' labelId={`${key}-label`} {...controlledField} {...condition?.props} multiple={altField.multiple} required={altField.required} {...altField.props} {...GLOBAL_PROPS}>
          {altField.choices?.map((choice, index) => {
                    return (<MenuItem sx={{
                        // todo: add style to truncate eg:
                        // https://tailwindcss.com/docs/text-overflow#truncate
                        // https://codesandbox.io/s/modest-matsumoto-eorbsn?file=/demo.js:431-565
                        }} key={`${index}-${choice?.value ?? choice?.display}`} value={choice?.value ?? choice?.display} divider={true}>
                {choice?.display}
              </MenuItem>);
                })}
        </Select>);
        default:
            console.error(`AltFieldEdit: ${altField.component} not implemented yet`);
    }
};
